@import '../../../styles/colors';

.helper {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 18px;
    color: var(--main-color) !important;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: -5px;
    min-height: 100px;
    overflow: scroll;
    width: 300px;
    z-index: 101;
    color: var(--text-color);
    background: var(--content-bg);
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px 1px #ccc;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 15px;

    h4 {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
      color: var(--text-color);
    }

    p {
      font-size: 15px;
      margin-bottom: 10px;
      color: var(--text-color);
    }

    .ant-image {
      width: 100% !important;
      height: 200px !important;
      @media screen and( max-width: 1300px) {
        height: 400px !important;
      }
    }

    img {
      width: 100% !important;
      height: 200px !important;
      @media screen and( max-width: 1300px) {
        height: 400px !important;
      }
    }

    &-button {
      position: absolute;
      top: 5px;
      right: 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      color: var(--text-color);
    }
  }
}

.ssl-helper__container {
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
}
