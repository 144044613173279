.custom-select {
  width: 100%;
  max-width: 440px;
  position: relative;
  outline: none;
  border-radius: 16px;
  margin: 10px 0;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: black;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 18px;
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 35px;
  margin-bottom: 16px;

  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1450px) {
    height: 35px;
    font-size: 15px;
  }
  @media screen and (max-height: 700px) {
    height: 35px;
  }
  .selected-option {
    display: flex;
    position: relative;
    gap: 15px;
    border-radius: 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    align-items: center;
    .displayed__icons {
      margin-left: 12px;
      width: 20px !important;
      height: 20px !important;
      @media screen and (max-height: 700px) {
        width: 17px !important;
        height: 17px !important;
      }
    }
  }

  .options {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: none;
    color: black;
    max-height: 150px;
    overflow-y: auto;
    z-index: 9999;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: black;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 18px;
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    border-radius: 9px;
    li {
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 15px;

      &:hover {
        background-color: #fff;
      }

      &.selected {
        background-color: #f0f0f0;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--main-color);
      border-radius: 10px;
    }
  }
}
.custom-select--error {
  border: 1px solid red !important;
}
.options__icon {
  width: 15px;
  height: 15px;
}
