@import '../../../styles/colors';

.drag-and-drop {
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: var(--content-bg);
  -webkit-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
  z-index: 0;

  @media screen and(max-width: 1650px) {
    min-height: 260px;
  }

  @media screen and(max-width: 1450px) and (max-height: 700px) {
    min-height: 200px;
    height: 215px;
  }

  @media screen and(max-width: 1400px) {
    min-height: 200px;
    padding: 10px;
  }

  h3 {
    font-family: Bebas Neue, sans-serif;
    font-size: 32px;
    margin-bottom: 25px;
    font-weight: bold;
    color: var(--text-color);

    @media screen and(max-width: 1600px) {
      font-size: 25px;
    }

    @media screen and(max-width: 1400px) {
      font-size: 20px;
    }

    span {
      color: var(--main-color);
    }
  }

  &-input {
    width: 300px;
    margin: 20px 0;
    text-transform: uppercase;
  }

  &__circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 13px auto 12px;
    position: relative;

    @media screen and (min-width: 1600px) {
      margin: auto;
    }
  }

  &__image {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 10px 15px;
    border: 1px solid $grey;
    border-radius: 8px;
  }

  &__placeholder {
    margin: 0 auto;
    color: $grey;
    cursor: pointer;
    font-family: Glacial Indifference, sans-serif;

    span {
      text-decoration: underline;
    }
  }

  &__text {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    color: var(--text-color);

    &--highlight {
      color: var(--main-color);
    }

    &-item {
      width: 99px;
      margin: 7px;
      cursor: pointer;
    }
  }

  .icon {
    font-size: 40px;
    color: var(--main-color);
    margin: 5px;
    @media screen and(max-width: 1300px) {
      font-size: 30px;
    }
  }

  .primary-button {
    border-radius: 30px;
    margin-top: 30px;
    height: 50px;
    text-transform: uppercase;
    font-size: 20px;
    border: var(--main-color);
    background: $main-gradient;
    font-family: 'Glacial Indifference', sans-serif !important;

    @media screen and (min-width: 1600px) {
      margin-top: auto;
    }

    @media screen and(max-width: 1450px) {
      font-size: 14px;
      width: 250px !important;
      height: 40px;
    }
  }

  .primary-button:hover {
    background: $main-gradient;
    opacity: 0.8;
  }

  img {
    width: 50px;
    height: 50px;
  }
}

.dashboard .link {
  width: 40%;
  border-radius: 30px;
  margin-top: 30px;
  height: 50px;
  text-transform: uppercase;
  font-size: 20px;
  border: var(--main-color);
  background: $main-gradient;
  font-family: 'Glacial Indifference', sans-serif !important;
  @media screen and(max-width: 1300px) {
    font-size: 16px;
  }
}
