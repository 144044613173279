$main-blue: #55a0e6;
$white: #f2f2f2;
$black: #000;
$grey: #b1b1b1;
$blue: #0c70e9;
$correctColor: #5de100;
$incorrectColor: #f5222d;

///WL
$main-gradient: linear-gradient(to right, var(--gradient-1) 0%, var(--gradient-2) 52%, var(--gradient-3) 100%);
$header-avatar-border: var(--gradient-3);
$main-gradient-hover: linear-gradient(-45deg, #e6e6e6, #e6e6e6, var(--gradient-1), var(--gradient-3));
$main-gradient-left: linear-gradient(to left, var(--gradient-1) 0%, var(--gradient-2) 52%, var(--gradient-3) 100%);
$sub-text-color: var(--gradient-2);
$link-color: var(--gradient-3);
$header-inner: linear-gradient(to left, var(--header-inner-gradient-1) 0%, var(--header-inner-gradient-2) 52%, var(--header-inner-gradient-3) 100%);
$header-skips: linear-gradient(to left, var(--header-skips-gradient-1) 0%, var(--header-skips-gradient-2) 52%, var(--header-skips-gradient-3) 100%);
$action-gradient: linear-gradient(to left, var(--action-gradient-1) 0%, var(--action-gradient-2) 52%, var(--action-gradient-3) 100%);
$total-gradient: linear-gradient(to left, var(--background-totals-1) 0%, var(--background-totals-2) 52%, var(--background-totals-3) 100%);
