.edit-colors-position {
  position: relative;
  .title-text {
    position: absolute;
    top: 17%;
    font-size: 25px;
    left: 25px;
    color: var(--main-color);
  }
}
.edit-wl-container {
  height: 600px;
  overflow-y: scroll;
  padding: 1rem;
  border-radius: 0 0 10px 20px !important;
  margin-top: 50px;

  @media screen and (max-width: 1600px) {
    margin-top: 0;
    height: 500px;
    transition: 0.3s ease-in all;
  }
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 10px;
  }
}
