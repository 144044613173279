.edit__field {
  display: block;
  height: 50px;
  outline: none;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  background: #f2f2f2;
  border: 1px solid white;
  color: black!important;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 16px;
}

.edit__field::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #a3a3a3;
  padding-left: 5px;
  font-family: 'Glacial Indifference', sans-serif;
}

.edit__field:hover {
  border: 1px solid var(--main-color);
}

.edit__field:focus {
  box-shadow: 0 0 0 2px var(--main-color-hover);
  border: 1px solid var(--main-color);
}
