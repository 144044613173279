@import '../../../styles/colors';

.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  position: relative;
  color: var(--text-color);
  font-family: 'Glacial Indifference', sans-serif;
  display: flex;
  flex-direction: column;
}

.field {
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 16px;
  padding: 6px 50px;
  margin: 10px 0;
  background: $white;
  border: 1px solid $white;
  color: black;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 18px;
  -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  position: relative;

  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1450px) {
    height: 35px;
    font-size: 15px;
  }

  @media screen and (max-height: 700px) {
    height: 35px;
  }
}

.field__password {
  -webkit-text-security: disc;
}

.field::placeholder {
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #a3a3a3;
  font-family: 'Glacial Indifference', sans-serif;

  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1450px) {
    font-size: 15px;
  }

  @media screen and (max-width: 500px) {
    font-size: 15px;
    line-height: 15px;
  }
  @media screen and (max-height: 700px) {
    font-size: 13px;
  }
}

.field__icon {
  position: absolute;
  top: 50%;
  left: 13px;
  z-index: 1;
  width: 20px;
  height: 21px;
  @media screen and (max-height: 700px) {
    width: 13px;
    height: 16px;
  }
  transform: translate(0, -50%);
}

.field:focus {
  border: 1px solid var(--main-color);
  -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
}

.field:disabled {
  cursor: not-allowed;
}

.ant-tooltip-open {
  font-size: 13px;
  font-family: 'Glacial Indifference', sans-serif;
}

.password__eye {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 23px;
  color: var(--main-color);
  cursor: pointer;

  @media screen and (max-width: 1500px) {
    top: 51%;
    transform: translate(0, -50%);
  }

  @media screen and (max-height: 700px) {
    top: 49%;
    right: 20px;
  }
  svg {
    @media screen and (max-height: 700px) {
      width: 20px;
    }
  }
}
