@import '../../styles/colors';
.contact {
  background-image: url('../../assets/white-label/main-background.png');
  background-repeat: no-repeat;
  background-size: 100%;

  @media screen and (max-width: 1280px) {
    min-width: 1300px;
  }
}

.contact-section {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 100px;
  padding: 2rem 2rem 0;

  font-family: 'Glacial Indifference', sans-serif;

  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.contact__form {
  margin-bottom: 70px;

  & > h3 {
    font-family: Bebas Neue, sans-serif;
    font-size: 50px;
    text-align: center;

    & > b {
      color: var(--main-color);
    }
  }

  & > p {
    max-width: 50%;
    margin: 0 auto 2rem;

    font-size: 20px;
    text-align: center;
  }
}

.contact__form-fieldset {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;

  border: none;

  & > textarea,
  & > input {
    height: 60px;
    padding: 1rem;

    font-family: 'Glacial Indifference', sans-serif;

    border: 2px solid #f1f1f1;
    background-color: #f1f1f1;

    transition: 0.15s all ease-in-out;

    &:hover,
    &:focus-visible {
      border-color: var(--main-color);
      outline: none;
    }

    &::placeholder {
      color: #ccc;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
      transition: 0.15s all ease-in-out;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & > input[type='text'] {
    flex-grow: 1;
    border-radius: 10px 0 0 0;
  }

  & > input[type='email'] {
    flex-grow: 2;
    border-radius: 0 10px 0 0;
  }

  & > textarea {
    width: 100%;
    height: 180px;

    border-radius: 10px;

    resize: none;
  }
}

.contact__form-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 280px;
  height: 50px;
  margin: 0 auto;
  padding: 10px;

  font-size: 20px;
  font-family: 'Glacial Indifference', sans-serif;
  color: #fff;

  border: 1px solid transparent;
  border-radius: 25px;
  background: $main-gradient;
  background-size: 150%;
  background-position: 0;
  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus-visible {
    background-position: 100%;
    border-color: var(--main-color);
  }
}

.contact__account-container {
  position: relative;

  width: 60%;
  margin: 0 auto;
  padding: 50px 50px 15px;

  border-radius: 15px 15px 0 0;
  background-color: #444444;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
  }
}

.contact__account-svg-container {
  position: absolute;
  top: -40px;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  border-radius: 50%;
  background-color: #fff;

  transform: translateX(-50%);
  box-shadow: 0 3px 4px 2px rgba(34, 60, 80, 0.5);

  & > svg {
    width: 50%;
  }
}

.contact__account-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin: 0 0 1rem;
  padding: 0;

  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.contact__login-button {
  margin-bottom: 1rem;
}

.contact__login-link {
  border-bottom: 1px solid #fff;
}

.input-error {
  border: 1px solid #ff4c4c !important;
}
