@import '../../../styles/colors';

.callendly__modal {
  .ant-modal,
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
  }

  .ant-modal-close-x {
    display: initial !important;
    color: white;
    font-size: 30px;
  }
}

.modal-callendly-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  h1 {
    color: var(--text-color);
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 550px;

    @media screen and (max-width: 1300px) {
      min-height: 450px;
    }
  }

  .ant-modal-close-x {
    display: initial !important;
    font-size: 30px;
    margin-right: 25px;
    color: var(--text-color) !important;
  }

  .ant-modal-body {
    padding: 24px !important;
    background: var(--content-bg) !important;
  }
}

.callendly-card {
  margin-left: 20px;
  width: 250px;
  min-height: 340px;
  border-radius: 16px;
  padding: 3px;
  background-image: linear-gradient(var(--content-bg), var(--content-bg)), $main-gradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    h4 {
      font-size: 22px;
      margin: 10px 0;
      color: var(--text-color);
    }

    img {
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid $link-color;
    }
  }

  &__body {
    padding: 5px 10px;
    text-align: center;

    p {
      color: var(--text-color);
    }
  }

  &__footer {
    display: flex;
    margin: auto 10px 10px 10px;

    button {
      flex: 1;
      background: var(--main-color);
      border: 1px solid var(--main-color);
      border-radius: 8px;
      text-transform: capitalize;
      cursor: pointer;
      display: block;
      font-family: 'Glacial Indifference', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      padding: 8px;
      color: white;
      text-align: center;

      &:hover {
        background: var(--main-color-hover);
        border: 1px solid var(--main-color-hover);
      }
    }
  }
}
