@import '../../../../styles/colors';

.description-btn {
  background: $main-gradient;
  padding: 4px;
  color: white;
  border-radius: 8px;
  border: 1px solid var(--main-color);
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: $main-gradient-left;
    transition: 0.4s ease-in all;
    color: white;
  }
}

.description__modal {
  width: 70% !important;

  .ant-modal-close-x {
    display: block !important;
  }

  @media screen and (max-width: 1500px) {
    width: 95% !important;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      color: var(--main-color);

      span {
        color: var(--text-color);
      }
    }

    &-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 500px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 16px;
      color: var(--main-color);

      span {
        color: var(--text-color);
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    height: 420px;
    padding: 0 10px;

    p {
      padding: 10px;
      font-size: 16px;
      height: 100%;
      color: var(--text-color);
      line-height: 20px;

      &:nth-child(2n) {
        background: var(--tr-bg);
      }
    }
  }
}
