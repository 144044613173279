@import '../../styles/colors';

.data-matching {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;

  &__article-container {
    margin: 0 50px;

    @media screen and(max-width: 1520px) {
      margin: 0;
    }
  }

  &__article {
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 45px;
    margin-left: 20px;
    line-height: 0.75;
    color: var(--text-color);

    @media screen and (max-width: 1400px) {
      font-size: 35px;
      width: 400px;
    }

    span {
      color: var(--main-color);
    }
  }

  &__sub {
    font-size: 18px;
    margin-left: 20px;
    margin-bottom: 25px;
    color: var(--text-color);
    line-height: 1.2;
    width: 800px;

    @media screen and(max-width: 1600px) {
      font-size: 16px;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      font-size: 14px;
      width: 650px;
    }

    @media screen and(max-width: 1350px) {
      font-size: 14px;
      width: 500px;
    }
  }

  h4 {
    font-size: 27px;
    letter-spacing: 1.5px;
    padding: 0 25px;
    color: var(--text-color);
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;

    @media screen and (max-width: 1300px) {
      font-size: 24px;
    }
  }

  &__item {
    padding: 20px 0;
    max-height: 485px;
    z-index: 1000;
    -webkit-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);

    @media screen and(max-width: 1400px) {
      max-height: 430px;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      max-height: 415px;
    }

    &-inner {
      overflow-y: auto;
    }

    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      width: 290px;
      margin-right: 30px;
      border-radius: 24px;
      background: var(--content-bg);
      text-align: center;
      padding-top: 15px;
      @media screen and(max-width: 1520px) {
        width: 27%;
      }

      h4 {
        font-size: 25px;
        padding-bottom: 10px;
      }

      .total-rows {
        padding-top: 10px;
        margin-top: auto;
        color: var(--text-color);
      }

      .data-matching__field {
        margin: 0 auto;
        font-size: 13px;
        padding: 0 8px;
        border-radius: 12px;
        height: 55px;
        color: black;
        background: $white;

        @media screen and(max-width: 1520px) {
          height: 45px;
        }
      }
    }

    &-file {
      .data-matching__field {
        color: $white !important;
        background: $main-gradient !important;
      }
    }

    &:nth-child(2) {
      width: 40%;
      background: var(--content-bg);
      border-radius: 24px 0 0 24px;
      text-align: center;
    }

    &:nth-child(3) {
      width: 300px;
      background: $main-gradient;
      border-radius: 0 24px 24px 0;
      z-index: 999;
      -webkit-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
      @media screen and(max-width: 1520px) {
        width: 27%;
      }

      h4 {
        padding-bottom: 39px;
      }
    }
  }

  &__form {
    padding: 5px 25px;

    .data-matching__label {
      margin: 0 !important;
    }

    .data-matching__label::after {
      content: ' ';
      position: absolute;
      top: 27px;
      right: -30px;
      width: 8px;
      height: 8px;
      background-color: $white;
      border-radius: 4px;
      z-index: 1001;

      @media screen and(max-width: 1520px) {
        top: 22px;
        right: -29px;
      }
    }

    .data-matching__label::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: -27px;
      width: 27px;
      height: 2px;
      background-color: $white;
    }
  }

  &__form-user {
    padding: 5px 25px;

    .data-matching__label::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: -25px;
      width: 25px;
      height: 2px;
      background-color: $white;
    }

    .data-matching__field {
      background: $white;
      color: black;
      cursor: pointer !important;
      font-size: 15px;

      @media screen and (max-width: 1300px) {
        font-size: 12px;
      }
    }

    .data-matching__field:hover {
      border: 2px solid #222736;
    }

    .data-matching__label {
      cursor: pointer;
      margin: 0 !important;
    }
  }

  &__button {
    background: transparent;
    border: 1px solid $white;
    border-radius: 16px;
    text-transform: uppercase;
    width: 60%;
    cursor: pointer;
    display: block;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    padding: 5px 8px;
    color: $white;
    margin: 10px auto 0 auto !important;
  }

  &__continue {
    background: $white;
    color: var(--main-color);
    margin: 10px auto 0 auto !important;
  }

  &__checkbox input:checked + label:after {
    top: 0;

    @media screen and (max-width: 1550px) {
      top: 6px;
    }

    @media screen and (max-width: 1330px) {
      left: 10px;
    }
    @media screen and (max-width: 1280px) {
      left: 6px;
    }
  }

  &__checkbox label {
    font-size: 13px;
    color: var(--text-color);

    @media screen and (max-width: 1300px) {
      font-size: 12px;
    }
  }

  .ant-select {
    height: 50px;
    background: $white;
    display: flex !important;
    align-items: center !important;
    position: relative;
    font-family: 'Glacial Indifference', sans-serif;
    width: 100%;
    margin: 5px 0 10px 0 !important;
    border-radius: 8px;

    @media screen and(max-width: 1520px) {
      height: 40px !important;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: -25px;
      width: 25px;
      height: 2px;
      background-color: $white;
    }

    &-focused {
      border: none !important;
      background: $white !important;
      box-shadow: none !important;
    }
  }

  .ant-select-selector {
    border: none !important;
    background: $white !important;
    border-radius: 8px;
  }

  .ant-select-arrow {
    background: url('../../assets/UI/icon_arrow.svg') 0 0 no-repeat;
    width: 25px;
    height: 25px !important;
    transform: translate(0, -20%);
    color: transparent !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
}

.steps {
  margin: 0 0 0 45px;
  z-index: 3;

  h3 {
    margin: 0 0 10px 0;
  }
}

.ant-steps {
  margin-bottom: 30px !important;

  @media screen and(max-width: 1520px) {
    margin-bottom: 20px !important;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $main-gradient !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #8cc63f !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #8cc63f !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.ant-steps-item-title {
  font-size: 14px !important;
  color: var(--text-color) !important;
}

.container {
  padding: 0 30px;

  @media screen and(max-width: 1520px) {
    margin-top: 30px;
  }
}

.data-matching__error .ant-steps-item-icon {
  border: 1px solid $incorrectColor !important;
  color: $incorrectColor !important;

  & > span {
    color: $incorrectColor !important;
  }
}

.data-matching__error .ant-steps-item-wait .ant-steps-item-icon {
  border: 1px solid $incorrectColor !important;
  color: $incorrectColor !important;
}

.data-matching__error .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: $incorrectColor !important;
}

.select-variant {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 10px;

  span {
    font-size: 12px;
    color: #787878;
  }
}
