.ssl-field {
  label {
    display: flex;
    flex-direction: column;
  }

  textarea {
    font-size: 14px !important;
    width: 400px;
  }
}
