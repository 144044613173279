@import '../../../../styles/colors';

.employees {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__invite {
    position: absolute;
    right: 20px;
    top: -50px;
    border: 1px solid var(--main-color);
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    background: $main-gradient;
    color: white;
    z-index: 100;

    @media screen and (max-width: 1400px) {
    }

    @media screen and (max-width: 1280px) {
      top: -40px;
    }
  }

  &__container {
    height: 500px;
    margin-bottom: 1rem;
    overflow-y: auto;

    @media screen and(max-width:1366px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1280px) {
      height: 455px;
    }

    @media screen and (max-width: 1400px) and (max-height: 750px) {
      height: 410px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      @media screen and (max-width: 1400px) {
        width: 5px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--main-color);
      border-radius: 100px;
    }
  }

  &-list {
    padding: 0;
    margin: 0;
  }

  .employees-list__item {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 10px;

    & > span {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: var(--text-color);

      &:first-child {
        text-transform: capitalize;
      }

      &:last-child {
        justify-content: center;

        & > span {
          padding: 5px;
        }
      }
    }

    & > h3 {
      margin: 0;
      padding: 0;

      font-weight: bold;
      color: #999999;

      &:last-child {
        text-align: center;
      }
    }

    &:first-child {
      position: sticky;
    }

    &:nth-child(2n) {
      background-color: var(--tr-bg) !important;
    }
  }
}

.employees-pagination {
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 370px;
  }
}
