@import '../../../../styles/colors';
.edit-billing {
  min-height: 500px;
  display: flex;
  flex-direction: column;

  .edit-header {
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    margin: 40px -70px;

    @media screen and (max-width: 1550px) {
      margin: 50px -30px;
    }

    @media screen and (max-width: 1450px) {
      margin: 90px -10px 50px;
    }
  }

  .edit-header-nav {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $main-gradient !important;
    padding: 3px;
    border-radius: 20px;
    color: white;

    p {
      margin-left: 20px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    button {
      background: var(--action-color);
      border: 1px solid var(--action-color);
      outline: none;
      padding: 6px 18px;
      font-size: 16px;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 45%;
    min-width: 500px;
    margin: 0 auto 30px auto;

    @media screen and (max-width: 1450px) {
      min-width: 450px;
    }
  }

  &-body-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto 30px 0;

    @media screen and (max-width: 1600px) {
      justify-content: space-between;
    }

    a,
    button {
      color: white;
      background: $main-gradient;
      padding: 15px;
      border-radius: 30px;
      width: 160px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
    }

    img {
      margin-left: 10px;
      width: 20px;
      height: 10px;
    }
  }

  .border {
    margin: 0 auto;
    height: 2px;
    width: 60%;
    min-width: 600px;
    background: $main-gradient;
    @media screen and (max-width: 1450px) {
      min-width: 100%;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: block;
    height: 50px;
    outline: none;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    background: #f2f2f2;
    border: 1px solid white;
  }
}
