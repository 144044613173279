.users-list__search {
  display: flex;
  align-items: center;
  gap: 15px;

  .ant-select-selector {
    background: white !important;
    height: 40px !important;
    width: 120px !important;
  }

  .ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
  }
}
