@import '../../../styles/colors';

.result {
  &-modal {
    display: flex;
    flex-direction: column;
    min-height: 63vh;
    position: relative;
    border-radius: 32px;

    &::before {
      content: '';
      background: $main-gradient;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.85;
      border-radius: 32px;
    }

    &__header {
      padding: 20px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $action-gradient;
      border-radius: 50px;
      opacity: 1;
      width: 45%;
      min-width: 691px;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &__body {
      margin-top: 150px;
      text-align: center;
      opacity: 1;
      z-index: 100;
      color: white;

      img {
        width: 256px;
        height: 144px;
      }

      h2 {
        color: white;
        text-transform: uppercase;
        font-family: Bebas Neue, sans-serif;
        font-size: 50px;
        font-weight: normal !important;
        margin-bottom: 4px;
        margin-top: 40px;
      }

      p {
        line-height: 16px;
        font-size: 16px;
      }
    }

    &__body-wl{
      padding: 60px 0;

      p{
        font-size: 25px;
        margin: 10px 0;
      }
    }

    &__footer {
      margin-top: auto;
      height: 144px;
      width: 100%;
      background: url('../../../assets/pop-up/bg_result_modal.svg') 0 0 no-repeat;
      background-size: 100% 184px;
      z-index: 10;
      position: relative;

      &-inner {
        width: 60%;
        position: absolute;
        min-width: 991px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 38px 0;
        text-align: center;
        border-radius: 70px 70px 0 0;
        opacity: 1;

        &::before {
          content: '';
          background: $action-gradient;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0.75;
          border-radius: 70px 70px 0 0;
          z-index: 1;
        }

        p {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          opacity: 1;
          z-index: 20;
        }

        b {
          font-weight: 600;
        }

        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  .ant-modal-close {
    top: 20px;
    right: 20px;
  }

  .ant-steps-item-icon {
    width: 32px !important;
    height: 32px !important;
  }

  .ant-modal-close-x {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    color: white;
    line-height: 20px;
    font-size: 14px;

    border: 1px solid white;
    border-radius: 50%;

    transition: 0.25s ease all;

    &:hover,
    &:focus-visible {
      border-color: #144c76;
      color: #144c76;
    }
  }

  .ant-steps {
    margin: 0 !important;
  }

  .ant-steps-item-title {
    color: white !important;
  }

  .ant-modal-content {
    background: transparent !important;
  }
}

.result-modal__progressbar-container {
  position: relative;

  width: 100%;
  margin-top: 10px;

  border-radius: 10px;
  background-color: rgba(119, 177, 248, 0.6);

  & > span {
    position: absolute;
    left: 50%;
    top: 50%;

    color: #fff;
    font-weight: bold;

    transform: translate(-50%, -50%);
  }
}

.result-modal__progressbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1%;
  min-width: 20px;
  height: 18px;

  border-radius: 10px;
  background: $main-gradient;

  transition: 0.35s ease all;
}
