@import '../../../../styles/colors';

.white-label__nav {
  position: absolute;
  right: -16px;
  top: -91px;

  @media screen and (max-width: 1550px) {
    top: -87px;
  }

  &-basic {
    top: -44px !important;
    @media screen and (max-width: 1550px) {
      top: -40px !important;
    }
  }
}

.white-label__nav-link {
  position: relative;
  padding: 10px 2rem;
  font-size: 16px;
  color: black;
  text-align: center;
  border: none;
  border-radius: 0 20px 0 0;
  background: #f2f2f2;
  outline: none;
  transition: 0.45s ease all;
  cursor: pointer;
  box-shadow: -2px 0 10px 0 rgb(64 64 64 / 20%);

  @media screen and (max-width: 1550px) {
    font-size: 13px;
  }

  &--active {
    background: $main-gradient;
    color: #fff;
    pointer-events: none;
  }

  &:hover {
    color: #fff;
    background: $main-gradient-hover;
  }

  &:not(:first-child) {
    margin-left: -2.5rem;
    padding-left: 3.5rem;
  }

  &:first-child {
    border-top-left-radius: 60px;
    z-index: 1;
  }
}
