@import '../../../styles/colors';

.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 10px 0 10px;
  border-radius: 10px;
  padding: 10px 5px;
  background: rgba($color: #000000, $alpha: 0.9);
  height: calc(100vh - 19px);
  min-height: 600px;

  @media screen and (max-width: 1280px) and (max-height: 750px) {
    min-height: calc(689px - 19px);
  }

  @media screen and (max-width: 1280px) and (max-height: 650px) {
    min-height: calc(650px - 19px);
  }

  &__collapsed {
    width: 106px;
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }

  &__uncollapsed {
    width: 305px;

    @media screen and (max-width: 1500px) {
      width: 250px;
    }
  }

  .collapsed_button {
    background: $main-gradient;
    position: absolute;
    top: 25px;
    left: 100%;
    color: white;
    height: 170px;
    width: 25px;
    font-size: 10px;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 99;
    line-height: 12.38px;

    p {
      transform: rotate(90deg);
      white-space: pre;
      margin-top: 20px;
      text-transform: uppercase;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__border {
    border: 2px solid var(--main-color);
    border-radius: 15px 15px 0 0;
    position: absolute;
    min-height: calc(100% - 30px);
  }

  &__border__collapsed {
    width: 95px;
    left: 5px;
    border: 2px solid var(--main-color);
  }

  &__border__uncollapsed {
    width: 295px;
    top: 6px;
    @media screen and (max-width: 1500px) {
      width: 240px;
    }
  }

  .logo {
    width: 170px;
    height: 75px;
    margin: 0 0 20px 0;
    @media screen and(max-width: 1300px) {
      width: 150px;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin: 20px 0;

    @media screen and (max-width: 1440px) {
      margin: 0 0;
    }

    .sidebar__logo1 {
      max-width: 95%;
      height: 75px;
      margin-bottom: 15px;
      margin-top: 15px;

      @media screen and (max-width: 1280px) {
        margin-bottom: 10px;
        height: 65px;
        margin-top: 10px;
      }

      @media screen and (max-width: 1280px) {
        margin-bottom: 10px;
        height: 65px;
        margin-top: 10px;
      }

      @media screen and (max-height: 750px) {
        height: 50px;
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }

    h3 {
      font-weight: 300;
      text-transform: uppercase;
      color: $white;
    }

    span {
      margin-left: 5px;
      color: $sub-text-color;
      font-weight: bold;
    }
  }

  .blue-line {
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, transparent 0%, transparent 15%, $link-color, transparent 85%, transparent 100%);
  }

  &__user-collapsed {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__user-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid $link-color;
    margin-bottom: 30px;

    @media screen and(max-width: 1520px) {
      width: 105px;
      height: 105px;
      margin-bottom: 17px;
      margin-top: 20px;
    }

    @media screen and (max-width: 1440px) {
      width: 90px;
      height: 90px;
      margin-bottom: 17px;
      margin-top: 20px;
    }
    @media screen and (max-width: 1280px) {
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      text-transform: uppercase;
      border: var(--main-color);
      background: $main-gradient;
      cursor: pointer;
      font-family: 'Glacial Indifference', sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      padding: 8px;
      color: var(--navigation-text-active);
      text-align: center;

      @media screen and (max-width: 1500px) {
        font-size: 14px;
      }

      @media screen and(max-width: 1400px) {
        margin-top: 20px;
      }

      @media screen and(max-width: 1300px) {
        font-size: 12px;
        width: 60%;
        height: 40px;
        margin-top: 15px;
      }

      @media screen and (max-height: 650px) {
        margin-top: 10px;
      }
      @media screen and (max-height: 750px) {
        span {
          font-size: 18px !important;
        }
      }
    }

    .collapsed {
      width: 70px;
      height: 70px;
      border-radius: 5px;

      @media screen and (max-width: 1520px) {
        width: 55px;
        height: 55px;
      }

      @media screen and (max-width: 1440px) {
        width: 50px;
        height: 50px;
      }

      @media screen and (max-height: 750px) {
        width: 50px;
        height: 40px;
      }
    }

    .uncollapsed {
      width: 70%;
      height: 50px;
      border-radius: 30px;
      @media screen and (max-width: 1520px) {
        width: 65%;
        height: 45px;
        margin-top: 15px;
        font-size: 14px;
        &:last-child {
          margin-bottom: -30px;
        }
      }
      @media screen and (max-width: 1440px) {
        height: 45px;
        margin-top: 15px;
        &:last-child {
          margin-bottom: 17px;
        }
      }
      @media screen and (max-width: 1280px) {
        height: 43px;
        margin-top: 12px;
        &:last-child {
          margin-bottom: 8px;
        }
      }

      @media screen and (max-height: 750px) {
        font-size: 13px;
        height: 37px;
        margin-top: 10px;
        &:last-child {
          margin-bottom: -18px;
        }
      }
    }

    &-item:hover {
      background: $main-gradient;
      opacity: 1;
      color: white;
    }
  }

  &__menu-credit {
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    margin-left: -10%;
    width: 120%;
    min-height: 50px;
    color: var(--main-color);
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    box-shadow: 0 0 5px 1px #ccc;
    font-size: 17px;
    margin-bottom: -73px;
    margin-top: 40px;
    z-index: 999;

    @media screen and(min-width: 1550px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 1440px) {
      margin-bottom: -72px;
      margin-top: 0;
    }

    @media screen and (max-height: 750px) {
      margin-top: 25px;
    }

    span {
      margin-left: 3px;
      font-weight: normal;
      color: var(--main-color);
      line-height: 0;
    }
  }

  &__collapsed__menu-credit {
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    margin-left: -18%;
    width: 135px;
    min-height: 50px;
    color: var(--main-color);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    box-shadow: 0 0 5px 1px #ccc;
    padding: 10px 0;
    margin-top: 70px;
    z-index: 9;

    @media screen and (max-width: 1520px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 1440px) {
      margin-top: 15px;
    }

    @media screen and (max-height: 750px) {
      margin-top: 10px;
    }

    img {
      width: 40px;
      height: 30px;
      display: block;
      margin-left: -10px;
      margin-bottom: 5px;
    }

    .collapsed__text {
      text-transform: uppercase;
      margin-left: 3px;
      color: #000;
      font-size: 11px;
      font-weight: bold;
      display: flex;
    }
  }

  &__footer {
    width: 456px;
    height: 159px;
    margin: auto 0 -6px -86px;
    line-height: 0;
    z-index: 100;
    position: relative;

    @media screen and (max-width: 1520px) {
      margin: auto 0 -6px -85px;
      width: 425px;
      height: 147px;
    }

    @media screen and (max-width: 1440px) {
      margin: auto 0 -6px -86px;
      width: 365px;
      height: 125px;
    }
    @media screen and (max-width: 1280px) {
      margin: auto 0 -6px -86px;
      width: 365px;
      height: 125px;
    }

    img {
      position: absolute;
      top: 63%;
      left: 50%;
      z-index: 1000;
      transform: translate(-50%, 0);
      color: white;
      font-size: 24px;
      text-transform: uppercase;
      font-family: 'Myriad Pro', sans-serif;
      font-weight: 100;
      letter-spacing: 1px;
      width: 250px;
      height: 55px;
      object-fit: contain;

      @media screen and (max-width: 1450px) {
        width: 200px;
        height: 40px;
        top: 65%;
        left: 55%;
      }

      @media screen and (max-width: 1280px) {
        top: 66%;
        left: 53%;
        width: 205px;
        height: 40px;
      }

      @media screen and (max-height: 750px) {
        left: 55%;
      }
    }
  }
}

.sidebar__bg {
  background: #000000 url('../../../assets/sidebar/sidebar-bg.svg') no-repeat;
  background-size: cover;
}

.sidebar__line {
  margin: -5px 0 -20px -12px;
  height: 2px;
  width: 120px;
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    rgba(242, 242, 242, 0) 0%,
    rgba(199, 199, 199, 0.2) 22%,
    #b3b3b3 50%,
    rgba(242, 242, 242, 0.2) 78%,
    rgba(242, 242, 242, 0) 100%
  );
  transform: rotate(90deg);
  margin-bottom: 50px;

  @media screen and (max-width: 1440px) {
    width: 65px;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  @media screen and (max-height: 750px) {
    width: 45px;
    margin-left: 25px;
  }
}

.sidebar__footer__block {
  position: absolute;
  width: 180px;
  height: 95px;
  background: $main-gradient;
  border-top-right-radius: 100%;
  bottom: -9px;
  left: -15px;

  @media screen and (max-width: 1440px) {
    width: 165px;
    height: 85px;
    bottom: -9px;
  }
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    width: 147px;
    height: 75px;
    bottom: -9px;
  }

  h3 {
    position: absolute;
    color: white;
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'Myriad Pro', sans-serif;
    font-weight: 100;
    letter-spacing: 1px;
    bottom: 7px;
    left: 26px;
  }

  img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 35px;
    left: 25%;
    z-index: 1000;
    @media screen and (max-width: 1440px) {
      top: 23px;
      left: 27%;
    }
  }

  @media screen and (max-width: 1279px) {
    top: 92%;
  }
}
