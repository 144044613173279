@import '../../../styles/colors.scss';
.ssl-input__field {
  outline: none;
  width: 45%;
}

.change-setting_button {
  outline: none;
  border: none;
  padding: 8px 12px;
  color: var(--text-color);
  background-color: rgb(199, 186, 186);
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 7px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
.active-setting {
  background: $main-gradient;
}
