@import '../../../../styles/colors';

.edit-personal {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;

  h3 {
    color: $grey;
    font-size: 22px;
    padding: 11px 20px;

    @media screen and (max-width: 1450px) {
      margin-top: 45px;
      font-size: 18px;
    }
  }

  &__body {
    width: 85%;
    margin: 0 auto;
  }

  &__body-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid;
    border-image-slice: 1;
    border-image-source: $main-gradient;
    padding-bottom: 30px;

    @media screen and (max-width: 1450px) {
      padding-bottom: 20px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px 0 30px;

    a,
    button {
      color: white;
      background: $main-gradient;
      padding: 12px 15px;
      border-radius: 20px;
      width: 150px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        background: $main-gradient-left;
        color: black;
      }
    }
  }

  .edit__field {
    display: block;
    width: 49%;
    height: 50px;
    outline: none;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    background: #f2f2f2;
    border: 1px solid white;
    color: black;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 16px;

    @media screen and(max-width: 1500px) {
      font-size: 14px;
    }
  }

  .edit__field::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #a3a3a3;
    padding-left: 5px;
    font-family: 'Glacial Indifference', sans-serif;
  }

  .phone-edit-field {
    width: 100%;
  }

  .edit__field__btn {
    outline: none;
    border: none;
    height: 50px !important;
    border-radius: 8px 0 0 8px !important;
  }

  .edit-checkbox {
    width: 50%;
    line-height: 1.5;

    @media screen and (min-width: 1920px) {
      width: 360px;
    }

    @media screen and (max-width: 1800px) {
      width: 55%;
    }

    label {
      color: var(--text-color) !important;
      font-size: 15px;
      text-align: center !important;

      @media screen and (max-width: 1450px) {
        font-size: 13px;
      }
    }

    input:checked + label:after {
      @media screen and (max-width: 1930px) {
        top: 5px !important;
        left: 25px !important;
      }

      @media screen and (max-width: 1820px) {
        top: 4px !important;
        left: 11px !important;
      }
      @media screen and (max-width: 1650px) {
        top: 5px;
        left: 23px !important;
      }

      @media screen and (max-width: 1450px) {
        top: 6px !important;
        left: 19px !important;
      }

      @media screen and (max-width: 1300px) {
        top: 7px !important;
        left: 18px !important;
      }
    }
  }

  .phone__field {
    width: 49%;

    @media screen and (max-width: 1800px) {
      width: 42%;
    }

    .edit__field {
      padding-left: 48px;
    }
  }

  .edit-info {
    padding: 10px 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      padding: 8px 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      width: 49%;
    }

    label {
      width: 49%;
      font-size: 12px;
      color: #a3a3a3;
      text-align: left;
      position: relative;

      span {
        padding-left: 20px;
      }

      .edit__field {
        width: 100%;
      }
    }
  }

  h4 {
    color: $grey;
    width: 100%;
    font-size: 25px;
    margin: 20px 0;

    @media screen and (max-width: 1450px) {
      margin: 10px 0;
    }
  }

  .icon-check {
    font-size: 20px;
    color: green;
    position: absolute;
    right: 20px;
    top: 31%;
  }

  .icon-eye {
    font-size: 20px;
    color: var(--main-color) !important;
    position: absolute;
    right: 20px;
    top: 32%;
  }

  .label-password {
    width: 100% !important;
    position: relative !important;
  }

  .datepicker-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    height: 50px;
    outline: none;
    border-radius: 8px;
    padding: 10px 0 10px 10px;
    margin: 10px 0;
    background: #f2f2f2;
    border: 1px solid white;
    color: $grey;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 16px;

    p {
      padding-right: 10px;
      width: 25%;
    }
  }

  .datepicker {
    height: 50px;
    border: none;
    background: $main-gradient;
    border-radius: 0 8px 8px 0;
    flex: 1;

    .ant-picker-input > input {
      color: white;
    }

    .ant-picker-input > input::placeholder {
      color: white;
    }

    .close-circle {
      background: transparent;
    }
  }
}

.edit-email__container {
  button {
    color: white;
    background: $main-gradient;
    padding: 12px 15px;
    border-radius: 20px;
    width: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      background: $main-gradient-left;
      color: black;
    }
  }
}

.edit__email {
  width: 100%;
  outline: none;
  border-radius: 16px;
  padding: 10px 10px 10px 50px;
  margin: 10px 0;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: black;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 18px;
  box-shadow: 0 8px 7px 0 rgb(0 0 0 / 20%);
  position: relative;
}
