.social-block__auth {
  position: absolute;
  bottom: 0;
  left: 0;

  @media screen and (max-width: 1920px) {
    bottom: -130px;
  }
  @media screen and (max-width: 1600px) {
    bottom: -5px;
  }

  @media screen and (max-width: 1550px) {
    bottom: 15px;
  }

  @media screen and (max-width: 1550px) {
    bottom: 0 !important;
  }

  @media screen and (max-width: 1300px) and (max-height: 689px) {
    bottom: 0;
  }

  .social-link__items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__icon {
    width: 60px;
    height: 30px;
    cursor: pointer;
  }
}

.extended-class {
  position: absolute;
  bottom: 0;
  left: 0;

  @media screen and (max-width: 1920px) {
    bottom: 0 !important;
  }

  @media screen and (max-width: 1550px) {
    bottom: 10px !important;
  }

  @media screen and (max-width: 1250px) {
    bottom: 180px !important;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }

  .social-link__items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__icon {
    width: 60px;
    height: 30px;
    cursor: pointer;
  }
}

.to-main__page {
  width: 410px;
  height: 194px;
  position: absolute;
  top: 19%;
  left: 137px;

  &:hover {
    cursor: pointer;
  }
}

.auth__layout-bg {
  flex: 1;
  position: relative;
  @media screen and (max-width: 1300px) {
    margin-top: 0;
  }

  .logo-img {
    margin: 15% 0 0 10%;
    object-fit: contain;
    width: 465px;

    @media screen and (max-width: 1600px) {
      width: 400px;
      margin: 16% 0 0 15%;
    }

    @media screen and (max-width: 1400px) {
      width: 400px;
      margin: 20% 0 0 8%;
    }
  }
}
