@import '../../../../styles/colors';

.edit-payment {
  display: flex;
  flex-direction: column;
  min-height: 500px;

  .stripe-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-label {
    width: 100%;

    span {
      margin: 0 0 0 20px;
      color: #8c8c8c;
    }
  }

  &-field {
    border: 1px solid #8c8c8c;
    width: 100%;
    border-radius: 12px;
    height: 50px;
    margin: 7px 0 20px 0;
    padding-left: 20px;
    font-size: 16px;
    outline: none;
    padding-bottom: 20px;
    background: var(--content-bg);

    @media screen and (max-width: 1550px) {
      font-size: 14px;
    }

    &:nth-child(1) {
      border: 1px solid red;
    }

    &:nth-child(2) {
    }

    &:nth-child(3) {
    }
  }

  &-field::placeholder {
    color: #8c8c8c;
  }

  .edit-header {
    border-bottom: 1px solid #e6e6e6;
    margin: 40px -70px;
    position: relative;

    @media screen and (max-width: 1550px) {
      margin: 50px -30px;
    }

    @media screen and (max-width: 1450px) {
      margin: 90px -10px 50px;
    }
  }

  .edit-header-nav {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $main-gradient !important;
    padding: 3px 0 3px 3px;
    border-radius: 20px;
    color: white;

    p {
      margin-right: 22px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    button {
      background: #1a4268;
      border: 1px solid #1a4268;
      outline: none;
      padding: 6px 18px;
      font-size: 16px;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 67%;
    min-width: 720px;
    margin: 0 auto;
    transition: 0.6s ease all;

    @media screen and (max-width: 1550px) {
      min-width: 100%;
      margin-bottom: 0 !important;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto 30px 0;

    @media screen and (max-width: 1600px) {
      justify-content: space-between;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      margin-bottom: 15px;
    }

    a,
    button {
      color: white;
      background: $main-gradient;
      padding: 15px;
      border-radius: 30px;
      width: 160px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        color: #d3d3d3;
      }
    }

    img {
      margin-left: 10px;
      width: 20px;
      height: 10px;
    }
  }

  .border {
    margin: 0 auto;
    height: 1px;
    width: 67%;
    min-width: 600px;
    background: $main-gradient;
    @media screen and (max-width: 1550px) {
      min-width: 100%;
    }
  }

  .save-payment {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1450px) {
      flex-direction: column;
    }
  }
}
