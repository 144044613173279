@import '../../../../styles/colors';

.landing__video-section {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem 0;

  background-image: url('../../../../assets/landing/example_background.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% -10%;

  @media screen and(max-width: 1200px) {
    background-position: 50% 2%;
  }

  @media screen and(max-width: 850px) {
    padding: 3rem 2rem 0;
    background-position: 50% 2%;
  }

  @media screen and(max-width: 600px) {
    background-position: 50% 2%;
    overflow: hidden;
    padding: 3rem 2rem;
  }

  @media screen and(max-width: 500px) {
    padding: 2.5rem 1rem;
    background-size: 110vw;
    margin-bottom: 15px;
  }

  @media screen and(max-width: 320px) {
    background-size: 180vw;
  }

  & > h2 {
    margin: 0 0 1rem;
    padding: 0;
    font-size: 65px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Bebas Neue', sans-serif;

    @media screen and(max-width: 850px) {
      font-size: 40px;
      line-height: 0.8;
      margin-bottom: 5px;
    }

    @media screen and(max-width: 560px) {
      font-size: 27px;
    }

    @media screen and(max-width: 450px) {
      font-size: 26px;
    }

    @media screen and(max-width: 320px) {
      font-size: 22px;
    }
  }

  & > h3 {
    margin: 0 0 1rem;
    padding: 0;

    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;

    @media screen and(max-width: 1280px) {
      font-size: 27px;
    }

    @media screen and(max-width: 600px) {
      font-size: 17px;
    }

    @media screen and(max-width: 500px) {
      font-size: 13px;
      line-height: 1.5;
    }

    & > span {
      color: var(--main-color);
    }
  }

  & > p {
    width: 550px;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    text-shadow: 0 0 1px #fff;

    @media screen and(max-width: 850px) {
      font-size: 16px;
      max-width: 390px;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    @media screen and(max-width: 560px) {
      font-size: 14px;
      max-width: 310px;
    }

    @media screen and(max-width: 450px) {
      font-size: 11px;
    }
  }

  & > iframe {
    width: 80%;
    max-width: 1140px;
    aspect-ratio: 1034 / 582;

    border: none;
    border-radius: 8px;
    box-shadow: 0 0 15px rgb(0, 0, 0, 0.7);

    @media screen and(max-width: 850px) {
      width: 100%;
    }
  }
}

.landing__video-iframe {
  margin: 2rem 0;

  @media screen and(max-width: 850px) {
    margin: 1rem 0;
  }
}

.landing__video-back-decoration {
  position: absolute;
  top: 25%;
  width: 100%;
  aspect-ratio: 3077 / 1725;

  z-index: -1;

  @media screen and(max-width: 1800px) {
    top: 20%;
  }

  @media screen and(max-width: 1400px) {
    top: 10%;
  }

  @media screen and(max-width: 1280px) {
    transform: scale(100%, 125%);
  }

  @media screen and(max-width: 1100px) {
    top: 0;
  }

  @media screen and(max-width: 850px) {
    transform: scale(100%, 200%);
  }

  @media screen and(max-width: 750px) {
    transform: scale(100%, 250%);
  }

  @media screen and(max-width: 650px) {
    transform: scale(100%, 300%);
    top: -20%;
    width: 100vw;
  }
  @media screen and(max-width: 600px) {
    transform: scale(1);
    width: 180vw;
    top: 27%;
  }

  @media screen and(max-width: 500px) {
    width: 200vw;
  }

  @media screen and(max-width: 420px) {
    width: 210vw;
  }
}

.landing__example-form {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 2px solid var(--main-color);
  border-radius: 20px;
  border-left: none;
  border-top: none;

  @media screen and(max-width: 1280px) {
    max-width: initial;
  }

  &::after {
    content: '';

    position: absolute;
    right: -1px;
    top: 0;

    display: block;
    width: 97%;
    height: 100%;

    background-color: transparent;
    border: 2px none;
    border-top-style: solid;
    border-radius: 0 20px 20px 20px;
    border-color: var(--main-color);

    z-index: -1;
  }

  &::before {
    content: '';

    position: absolute;
    left: -1px;
    bottom: 0;

    display: block;
    width: 100%;
    height: 88%;

    background-color: transparent;
    border: 2px none;
    border-left-style: solid;
    border-radius: 0 20px 20px 20px;
    border-color: var(--main-color);

    z-index: -1;
  }

  & > img {
    width: 140px;
    height: auto;
    @media screen and(max-width: 800px) {
      width: 70px;
    }
  }

  & > svg {
    position: absolute;
    top: -22px;
    left: -22px;

    width: 40px;
  }
}

.landing__example-fieldset {
  position: relative;

  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 1rem;
  padding-left: 1rem;
  z-index: 1;

  @media screen and(max-width: 500px) {
    gap: 5px;
  }

  &::after {
    content: '';

    position: absolute;
    left: 0;
    top: 50%;

    display: block;
    width: 100%;
    height: 2px;

    background-color: #fff;

    transform: translateY(-50%);
    z-index: -1;
  }

  & > .landing__example-select {
    flex-grow: 1;
    max-width: 155px;
    height: 35px;

    font-size: 14px;

    @media screen and(max-width: 500px) {
      font-size: 10px;
    }

    & > .ant-select-selector {
      height: 100% !important;
    }

    & > .ant-select-selector {
      border-radius: 15px !important;
    }
  }
}

.landing__example-search-container {
  position: relative;

  flex-grow: 1;
  max-width: 335px;

  & > svg {
    position: absolute;
    top: 50%;
    right: 10px;

    display: block;
    width: 15px;

    transform: translateY(-50%);
  }
}

.landing__example-search {
  width: 100%;
  height: 35px;
  padding: 0 2rem;

  font-size: 14px;

  border: 1px solid #d9d9d9;
  border-radius: 30px;

  transition: 0.25s ease all;

  &::placeholder {
    color: #979797;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.25s all ease-in-out;
  }

  &:hover,
  &:focus-visible {
    outline: none;
    border-color: var(--main-color);
  }
}

.landing__example-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
}

.landing__example-item {
  display: grid;
  grid-template-columns: 80px 1fr;
  width: 100%;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 60px 1fr;
  }

  &:first-child {
    margin-bottom: 10px;
  }

  &:nth-child(2) {
    margin-bottom: 25px;
  }

  &:first-child {
    & > div {
      &:first-child {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.landing__example-content-container {
  display: grid;
  grid-template-columns: 1fr 90px 135px 90px 90px;
  height: 62px;
  padding: 0 1rem;

  border-radius: 10px;
  background: $main-gradient;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 60px 90px 80px 80px;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 60px 90px 60px 60px;
  }

  .landing__title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    @media screen and (max-width: 700px) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      flex: 1;
    }

    h4 {
      @media screen and (max-width: 700px) {
        margin-bottom: 10px;
        margin-left: 5px;
      }
    }
  }

  & > div,
  & > h4 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #fff !important;

    @media screen and (max-width: 1280px) {
      font-size: 10px;
    }

    @media screen and (max-width: 800px) {
      font-size: 9px;
    }
  }

  h4 {
    color: #fff !important;
  }

  & > div {
    &:not(:first-child) {
      justify-content: center;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 30px;

        border-radius: 5px;
        background: rgb(0, 0, 0, 0.5);
      }
    }
  }

  & > h4 {
    justify-content: center;
    text-align: center;
    font-weight: bold;
  }

  &--heading {
    height: auto;
    border-radius: 0;
    background: none;
  }
}

.landing__example-multiply {
  position: relative;

  display: flex;
  align-items: center;
  padding: 3px 10px;
  gap: 5px;

  font-weight: normal;

  border: 3px solid rgb(0, 0, 0, 0.8);
  border-radius: 10px;
  background: rgb(0, 0, 0, 0.7);
  background-clip: padding-box;

  @media screen and (max-width: 800px) {
    padding: 3px 5px;
  }

  & > svg {
    width: 12px;
    height: auto;
    stroke: #fff;

    @media screen and (max-width: 1200px) {
      width: 10px;
    }
  }
}

.landing__example-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing__example-checkbox-label {
  cursor: pointer;

  & > svg {
    width: 30px;
    height: 30px;

    stroke: #fff;
    stroke-width: 3px;

    & > #check {
      stroke-miterlimit: 10;
      stroke-dasharray: 50;
      stroke-dashoffset: 50;

      transition-property: stroke-dashoffset;
      transition-duration: 0.3s;
    }
  }
}

.landing__example-checkbox {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;

  &:hover + label,
  &:focus-visible + label {
    & > svg {
      stroke: var(--main-color);
      transition: 0.35s ease all;
    }
  }

  &:checked + label {
    & > svg {
      & > #check {
        stroke-dashoffset: 0;
        transition-property: stroke-dashoffset;
        transition-duration: 0.3s;
      }
    }
  }
}

.landing__example-link-button {
  max-width: 330px;
  font-size: 22px;
  background: $main-gradient;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    width: 205px;
    padding: 5px 15px;
  }
}

.plus {
  background: transparent;
  border: 2px solid white;
  font-size: 26px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  vertical-align: center;
  margin: 0 20px 0 0;
  cursor: pointer;

  span {
    margin-top: -5px;
  }
}

.button-action {
  width: 70px;
  background: $action-gradient;
  border-radius: 8px;
  text-align: center;
  height: 35px;
  margin: auto;
  outline: none;
  border: 1px solid var(--action-color);

  @media screen and (max-width: 800px) {
    width: 55px;
  }
}

.landing__results {
  border: 1px solid white;
  border-radius: 10px;
  padding: 3px 10px;
}

.mobile-table {
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
}
