@import '../../../../styles/colors';

.billing-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;
    padding: 20px;
    position: relative;
    border-bottom: 1px solid var(--main-color-hover);
    flex-wrap: wrap;
    transition: 0.6s ease all;

    @media screen and(max-width: 1450px) {
      padding: 10px 20px;
    }
  }

  h3 {
    margin-top: 30px;
    border: 1px solid $grey;
    color: $grey;
    font-size: 20px;
    padding: 10px 50px;
    border-radius: 30px;
    text-align: center;

    @media screen and (max-width: 1500px) {
      font-size: 18px;
      padding: 10px 30px;
    }

    @media screen and (max-width: 1500px) {
      font-size: 18px;
      margin-top: 60px;
    }
  }

  &__address {
    margin-top: 20px !important;
  }

  &__body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    position: relative;
    border-bottom: 1px solid var(--main-color-hover);
    min-height: 119px;
    transition: 0.6s ease all;
    @media screen and (max-width: 1450px) {
      padding: 10px 20px;
    }
  }

  &-item {
    margin-bottom: 20px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
    color: var(--text-color);

    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }
  }

  .value {
    font-size: 16px;
    color: var(--text-color);
    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }

  &__footer {
    padding: 10px 0 0 0;
    position: relative;
    margin-top: 10px;

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      padding-top: 5px;
    }
  }

  &__footer-inner {
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      color: $grey;
      font-size: 20px;
      margin-right: 15px;
      margin-bottom: 20px;
      @media screen and (max-width: 1500px) {
        font-size: 18px;
      }
    }

    a {
      margin-bottom: 20px;
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      text-transform: capitalize;
      color: var(--main-color);
      text-decoration: underline;
      font-size: 14px;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }
  }

  .payment-edit {
    border-bottom: 1px solid $grey;
    position: relative;
    width: 100%;
    margin: auto 0 50px 0;

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      margin-bottom: 30px;
    }
  }

  .payment {
    position: absolute;
    bottom: -21px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--content-bg);
    outline: none;
    cursor: pointer;
    padding: 15px 16px;
    border-radius: 30px;
    border: 1px solid $grey;
    transition: 0.4s ease-in all;

    span {
      color: var(--main-color);
      text-decoration: underline;
      margin-right: 10px;
      font-size: 14px;
      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }

    img {
      width: 15px;
      height: 15px;
    }
  }

  .edit {
    position: absolute;
    bottom: -21px;
    right: 0;
    color: white;
    background: $main-gradient;
    padding: 15px;
    border-radius: 30px;
    width: 160px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    transition: 0.4s ease-in all;

    &:hover {
      background: $main-gradient-left;
      transition: 0.4s ease-in all;
    }
  }

  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .card__label {
    display: flex;
    align-items: center;
    text-align: center;
    min-width: 160px;
    cursor: pointer;

    span {
      color: var(--text-color);
      margin-left: 5px;
    }
  }

  .cards-empty {
    margin: auto;

    .payment {
      position: initial;
      padding: 20px;
    }

    span {
      font-size: 16px;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }
}
