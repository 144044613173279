@import '../../styles/colors';

.pricing {
  background-image: url('../../assets/white-label/main-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;

  @media screen and (max-width: 1280px) {
    min-width: 1300px;
  }
}

.pricing-section {
  padding-bottom: 200px;
}

.pricing-section__list-article {
  margin-top: -130px;
  margin-bottom: 60px;
}

.pricing-section__list-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 15px;
  width: fit-content;
  margin: 0 auto 45px;
  padding: 0 2rem;
  @media screen and (max-width: 1460px) {
    justify-content: space-around;
    padding: 0 5px;
  }

  & > .additional-price-card {
    flex-grow: 1;

    @media screen and (max-width: 1460px) {
      flex-grow: initial;
    }
  }
}

.pricing-section__price-container {
  flex-grow: 1;

  @media screen and (max-width: 1460px) {
    width: calc(100% - 275px);
    flex-grow: initial;
  }
}

.pricing-section__list-description {
  width: 100%;
  padding: 25px 45px;

  font-size: 18px;
  text-align: center;
  color: #fff;

  background-color: rgb(0%, 0%, 0%, 0.5);
  border-radius: 10px;

  @media screen and (max-width: 1460px) {
    padding: 25px 10px;
    font-size: 16px;
  }

  & > b {
    text-decoration: underline;
  }
}

.pricing-section__price-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;

  margin-bottom: 25px;

  & > .default-price-card {
    width: auto;
    max-width: 240px;
    flex-grow: 1;

    @media screen and (max-width: 1460px) {
      max-width: 190px;
    }
  }

  & > .price-card__price-value {
    @media screen and (max-width: 1460px) {
      font-size: 50px;
    }
  }

  .price-card__count-container > button {
    @media screen and (max-width: 1460px) {
      height: 32px;
      padding: 8px;
      font-size: 12px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 10px;
    }
  }
}

.pricing-section__price-sum {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;

  border-radius: 26px;
  border: 5px solid rgba(241, 241, 241, 0.62);
  background: $main-gradient;

  p {
    font-size: 18px;
    padding: 0 30px;

    &:first-child {
      padding-right: 15px;
      font-weight: bold;
      border-right: 1px solid #000;
    }
  }
}

.pricing-section__price-sum-total {
  width: 65%;
  display: flex;
  align-items: center;
  background: #f1f1f1;
  color: #000;
  padding: 20px 0;
  border-radius: 19px 0 0 19px;
}

.pricing-section__price-sum-checkout {
  position: relative;

  width: 35%;

  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 0 19px 19px 0;
  cursor: pointer;

  &:before {
    content: ' ';

    position: absolute;
    top: 50%;
    left: -15px;
    z-index: 1;

    width: 30px;
    height: 30px;

    background: white url('../../assets/pop-up/cartIcon.svg') 7px 8px no-repeat;
    background-size: 15px 15px;
    border-radius: 50%;

    transform: translateY(-50%);
  }
}

.pricing-section__text-article {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4%;
  width: 80%;
  margin: 0 auto;
  padding: 25px;

  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);

  & > .svg {
    position: absolute;
    left: 50%;
    top: 50%;

    z-index: -1;

    transform: translate(-50%, -50%);
  }

  & > img {
    width: 50%;
    height: auto;
  }

  & > p {
    width: 45%;
    padding-bottom: 120px;

    font-size: calc(16px + 12 * ((100vw - 500px) / (1920 - 500)));

    & > b {
      font-size: 38px;

      @media screen and (max-width: 1500px) {
        font-size: 30px;
      }
    }
  }
}

.pricing-section__account-container {
  position: absolute;
  bottom: -100px;
  left: 90%;

  width: 35%;
  min-width: 550px;
  margin: 0 auto;
  padding: 50px;
  padding-bottom: 15px;

  border-radius: 15px;
  background-color: #444444;

  transform: translateX(-90%);

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
  }
}

.pricing-section__account-svg-container {
  position: absolute;
  top: -40px;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  border-radius: 50%;
  background-color: #fff;

  transform: translateX(-50%);
  box-shadow: 0 3px 4px 2px rgba(34, 60, 80, 0.5);

  & > svg {
    width: 50%;
  }
}

.pricing-section__account-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;

  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.pricing-section__login-button {
  margin-bottom: 1rem;
}

.pricing-section__login-link {
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 1455px) {
  .pricing-section__list-article {
    margin-top: -30px;
  }
}
