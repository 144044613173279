@import '../../../styles/colors';

.status {
  text-align: center;
  padding: 6px 0;
  border-radius: 16px;
  text-transform: uppercase;

  p {
    font-size: 10px;
  }

  &-completed {
    border: 1px solid var(--text-color);
    color: var(--text-color);
    padding-left: 5px;
    position: relative;
    width: 95px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translate(-15%, -50%);
      background: #7eca27;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &-processing {
    background: $main-gradient;
    color: white;
    position: relative;
    width: 100px;
    height: 24px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      left: 7px;
      transform: translate(-10%, -50%);
      background: url('../../../assets/dashboard/loading-icon.svg') 0 0 no-repeat;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &-conversion {
    background: #892bc5;
    color: white;
    position: relative;
    width: 100px;
    height: 24px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      left: 7px;
      background: url('../../../assets/dashboard/loading-icon.svg') 0 0 no-repeat;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &-error {
    border: 1px solid #ed1c24;
    color: white;
    background: #ed1c24;
    padding-left: 5px;
    position: relative;
    width: 80px;

    p {
      text-decoration: underline;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translate(-15%, -50%);
      background: white;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &-draft {
    background: #fbb03b;
    border: 1px solid #fbb03b;
    color: white;
    padding-right: 10px;
    position: relative;
    width: 75px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      right: 15%;
      background: white;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: url('../../../assets/dashboard/draft-icon.svg') 0 0 no-repeat;
      //-webkit-animation: spinX 5s infinite;
      //animation: spinX 5s infinite;
    }
  }

  &-expired {
    border: 1px solid $grey;
    color: $grey;
    padding-left: 5px;
    position: relative;
    width: 95px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translate(-15%, -50%);
      background: $grey;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

//
//@-webkit-keyframes spinX
//{
//  0%   {-webkit-transform: rotateX(0deg); -webkit-transform-origin: 0% 50% 0;}
//  100% {-webkit-transform: rotateX(360deg); -webkit-transform-origin: 0% 50% 0;}
//}
//@keyframes spinX
//{
//  0%   {transform: rotateX(0deg); transform-origin: 0% 50% 0;}
//  100% {transform: rotateX(360deg); transform-origin: 0% 50% 0;}
//}