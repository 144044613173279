@import '../../styles/colors';

.results {
  margin: 60px 60px 30px 60px;

  @media screen and(max-width: 1550px) {
    margin: 80px 30px 30px 30px;
  }

  @media screen and(max-width: 1520px) {
    margin: 50px 30px 00px 30px;
  }

  &__header {
    width: 50%;
    min-width: 640px;
    margin-bottom: 48px;

    @media screen and(max-width: 1400px) and(max-height: 700px) {
      margin-bottom: 10px;
    }

    @media screen and(max-width: 1300px) {
      margin-bottom: 20px;
    }

    h1 {
      font-family: Bebas Neue, sans-serif;
      font-size: 40px;
      line-height: 0.95;
      margin-bottom: 9px;
      font-weight: bold;
      color: var(--text-color);

      @media screen and(max-width: 1520px) {
        font-size: 35px;
      }

      span {
        color: var(--main-color);
      }
    }

    b {
      display: block;
      font-size: 18px;
      margin-bottom: 8px;
      color: var(--text-color);

      @media screen and(max-width: 1520px) {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    p {
      font-size: 20px;
      width: 62%;
      min-width: 540px;
      color: var(--text-color);

      @media screen and(max-width: 1520px) {
        font-size: 16px;
      }
    }
  }

  &__body {
    border-radius: 26px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &-item {
      -webkit-box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.5);
      box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.5);

      &:first-child {
        -webkit-box-shadow: 4px 0 10px 0 rgba(64, 64, 64, 0.5);
        box-shadow: 4px 0 10px 0 rgba(64, 64, 64, 0.5);
        background: $main-gradient;
        border-radius: 32px 0 0 32px;
        width: 30%;
        color: white;
        text-align: center;
        padding: 35px 0 0 0;
        z-index: 2;
        display: flex;
        flex-direction: column;

        @media screen and(max-width: 1450px) {
          padding: 20px 0 0 0;
        }

        h3 {
          font-size: 35px;
          font-family: Bebas Neue, sans-serif;
          font-weight: bold;
          color: white;
          padding-bottom: 30px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          padding-right: 30px;

          @media screen and(max-width: 1550px) {
            font-size: 25px !important;
          }
        }

        p {
          text-align: left;
          font-size: 17px;
          width: 140px;

          @media screen and(max-width: 1450px) {
            font-size: 14px;
          }
        }
      }

      &-footer {
        background: $total-gradient;
        height: 140px;
        border-radius: 0 55px 0 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: auto;

        @media screen and(max-width: 1520px) {
          height: 90px;
        }

        h3 {
          margin: 0 0 5px 0 !important;
          font-size: 40px !important;
          border: none !important;
          padding: 0 !important;
          font-family: Glacial Indifference, sans-serif !important;
        }

        p {
          font-size: 20px !important;
          text-transform: uppercase;

          @media screen and(max-width: 1550px) {
            font-size: 15px !important;
            text-align: center !important;
          }
        }
      }

      &:last-child {
        width: 70%;
        background: var(--content-bg);
        border-radius: 0 26px 26px 0;
        position: relative;

        h3 {
          position: absolute;
          top: 32px;
          left: 60px;
          font-size: 28px;
          font-family: Bebas Neue, sans-serif;
          font-weight: bold;
          color: var(--text-color);
          margin-bottom: 0 !important;

          @media screen and(max-width: 1550px) {
            font-size: 25px !important;
          }
        }
      }
    }
  }

  &-border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 28px 0 30px 0;

    @media screen and(max-width: 1520px) {
      padding: 15px 0;
    }
  }

  &-border-double {
    padding: 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    @media screen and(max-width: 1600px) {
      padding: 20px 0;
    }

    @media screen and(max-width: 1520px) {
      padding: 15px 0;
    }

    div {
      &:first-child {
        padding-bottom: 45px;

        @media screen and(max-width: 1600px) {
          padding-bottom: 40px;
        }

        @media screen and(max-width: 1450px) {
          padding-bottom: 25px;
        }
      }
    }
  }

  .duplicates-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 8px;
  }
  .total {
    flex: 1;
    margin-left: 20px;
    text-align: right !important;
    padding-right: 50px;
    font-weight: bold;
    font-size: 20px !important;
    @media screen and (max-width: 1700px) {
      padding-right: 30px;
    }

    @media screen and (max-width: 1520px) {
      padding-right: 10px;
      font-size: 18px !important;
    }

    @media screen and(max-width: 1450px) {
      padding-right: 10px;
      font-size: 15px !important;
      margin-left: 0;
    }
  }

  .savedSkips {
    width: 100% !important;
    font-size: 17px;
    padding-bottom: 15px;
    @media screen and(max-width: 1450px) {
      font-size: 14px;
    }
    @media screen and(max-width: 1280px) {
      font-size: 13px !important;
      padding-bottom: 5px;
    }
  }

  .table {
    width: 100%;
    padding: 37px 48px;
    position: relative;

    @media screen and(max-width: 1520px) {
      padding: 35px 30px 0 30px;
    }
  }

  .thead {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0;
  }

  .tbody {
    margin-top: 15px;
    margin-bottom: 30px;
    height: 450px;
    overflow: auto;

    @media screen and (max-width: 1700px) {
      height: 400px;
      margin-bottom: 0;
    }

    @media screen and(max-width: 1450px) {
      height: 320px;
      margin-bottom: 0;
    }
  }

  .tfoot {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin-container:after {
      border-radius: 32px !important;
    }

    button {
      outline: none;
      font-size: 18px;
      border-radius: 26px;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      background: var(--main-color-hover);

      width: 300px;
      padding: 15px 20px;
      cursor: pointer;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      border: none;
      position: relative;

      @media screen and(max-width: 1520px) {
        width: 250px;
        font-size: 14px;
        margin-bottom: 20px;
      }

      &:before {
        background: $main-gradient;
        position: absolute;
        top: 3px;
        left: 4px;
        right: 4px;
        bottom: 3px;
        content: '';
        border-radius: 25px;
        opacity: 1;
        z-index: 100;
      }
    }

    p {
      font-weight: bold;
      opacity: 1;
      z-index: 100;
    }

    img {
      margin-left: -30px;
      opacity: 1;
      z-index: 100;
      width: 20px;
      height: 20px;

      @media screen and(max-width: 1520px) {
        width: 15px;
        height: 15px;
      }
    }
  }

  .th {
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    margin: 0 10px;
    padding: 0 10px;
    color: #999999;
    font-weight: bold;

    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      width: 22%;
    }

    &:nth-child(3) {
      text-align: center;
      width: 85px;
      margin: 0 -5px 0 0px;
      @media screen and(max-width: 1520px) {
        width: 95px;
      }
    }

    &:nth-child(4) {
      width: 120px;
      margin: 0 5px 0 0;
      @media screen and(max-width: 1520px) {
        width: 85px;
      }
    }
  }

  .download-checkbox {
    width: 130px;
  }

  .download-checkbox .checkbox-label {
    font-size: 16px;
    color: var(--text-color);
  }

  .download-checkbox input:checked + label:before {
    background: transparent;
    border: 1px solid var(--text-color);
  }

  .download-checkbox input:checked + label:after {
    border: solid var(--text-color);
    border-width: 0 2px 2px 0;
    left: 5px;
    width: 6px;
    height: 11px;
    top: 3px;

    @media screen and (max-width: 1450px) {
      top: 10px;
    }
  }

  .download-checkbox label:before {
    padding: 7px;
    border-radius: 5px;
  }
}
