@import '../../styles/colors';

.login {
  @media screen and (max-width: 1200px) {
    background: none !important;
    overflow: scroll !important;
  }

  &__logo {
    width: 90px;
    height: 50px;
    object-fit: contain;

    @media screen and (max-width: 1400px) {
      width: 70px;
      height: 35px;
    }
  }

  &__border {
    margin: 25px 0 0 0;
    height: 2px;
    width: 100%;
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      rgba(242, 242, 242, 0) 0%,
      rgba(199, 199, 199, 0.5) 22%,
      #b3b3b3 50%,
      rgba(242, 242, 242, 0.5) 78%,
      rgba(242, 242, 242, 0) 100%
    );

    @media screen and (max-width: 1400px) {
      margin: 15px 0 0 0;
    }

    @media screen and (max-width: 800px) {
      margin: 15px 0;
    }
  }

  &__button-forgot {
    background: transparent;
    border: 1px solid #ff383f;
    color: #ff383f;
    outline: none;
    cursor: pointer;
    padding: 6px 13px;
    border-radius: 16px;
    text-decoration: none;

    &:hover {
      border: 1px solid var(--main-color);
      color: var(--main-color);
    }
  }

  &__body {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 30px;
    max-height: 805px;
    z-index: 10;
    // position: relative;
    height: 800px;

    @media screen and (max-width: 1600px) {
      height: 650px;
    }

    @media screen and (max-width: 1450px) {
      padding: 15px 30px 0 30px;
    }

    @media screen and (max-width: 1300px) {
      height: 599px;
      padding: 10px 30px;
    }
  }

  &__body-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    @media screen and (max-width: 1200px) {
      width: 410px;
    }

    .auth__title {
      @media screen and (max-width: 1300px) {
        color: black;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 110%;

    @media screen and (max-width: 1400px) {
      justify-content: space-around;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 1.1;
  }

  ul {
    color: $white;
    margin-bottom: 50px;
  }

  li {
    margin: 10px 0;
  }

  .google-button {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: transparent;
    border: #b3b3b3 2px solid;
    border-radius: 16px;
    padding-left: 20px;
    color: #b3b3b3;
    cursor: pointer;


    @media screen and (max-width: 1400px) {
      height: 45px;
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 10px;
      width: 80%;
    }

    span {
      color: #999898;
      font-weight: 600;
      margin-left: 1px;
    }

    &.sign__up-highlevel {
      padding-right: 6px;
      margin-top: 8px;

      .icon {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 1400px) {
          width: 30px;
          height: 30px;
        }
      }
    }

  }

  .icon {
    width: 45px;
    height: 45px;

    @media screen and (max-width: 1400px) {
      width: 30px;
      height: 30px;
    }
  }

  h3 {
    color: black;
    font-size: 25px;
    margin: 20px 0;

    @media screen and (max-width: 1450px) {
      font-size: 19px;
      margin: 15px 0;
    }

    @media screen and (max-width: 800px) {
      margin: 20px 0;
    }
  }

  &__register {
    color: #b3b3b3;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__link {
    color: var(--main-color);
    text-decoration: underline;
    margin-left: 1px;

    &:hover {
      color: var(--main-color);
    }
  }

  .auth__footer__outer p {
    top: 35% !important;
  }
}

.login-check input:checked + label:after {
  @media screen and (max-width: 1450px) {
    top: 7px;
    left: 6px;
  }
}

.ant-alert {
  border-radius: 8px !important;
}

.ant-alert-error {
  border: 1px solid rgb(255, 76, 76);
}

.ant-spin-container:after {
  background: transparent;
}
