@import '../../../styles/colors';

.data-matching {
  .ant-tooltip-open {
    font-size: 13px !important;
  }

  &__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    margin: 15px 0 5px 0;
    position: relative;
    color: $white;
    font-family: 'Glacial Indifference', sans-serif;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__field {
    width: 100%;
    outline: none;
    border-radius: 8px;
    padding: 10px 50px 10px 10px;
    margin: 5px 0;
    background: $white;
    border: 1px solid $white;
    color: black;
    font-family: 'Glacial Indifference', sans-serif;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 17px;

    @media screen and (max-width: 1500px) {
      font-size: 14px;
      height: 40px;
    }

    p {
      margin: auto 0;
    }
  }

  &__field:disabled {
    cursor: pointer;
  }

  &__field__password {
    -webkit-text-security: disc;
  }

  &__field::placeholder {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: var(--text-color);
    font-family: 'Glacial Indifference', sans-serif;
  }

  &__field:focus {
    border: 1px solid var(--main-color);
    box-shadow: 0 0 16px 0 rgba(160, 230, 255, 0.2);
  }
}
