.high-level__form {
  padding: 15px 30px 0 30px;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  width: 500px;

  iframe {
    min-height: 80vh;
  }
}