.discount-input__container {
  display: flex;
  margin-bottom: 15px;
}

.discount__btn {
  padding: 15px 25px;
  border: none;
  margin-left: 15px;
  width: 15%;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
}

.save {
  background-color: var(--main-color);
  &:hover {
    cursor: pointer;
    background-color: var(--main-color-hover);
  }
}

.cancel {
  background-color: #cccc;
  &:hover {
    cursor: pointer;
    background-color: rgba(86, 81, 81, 0.8);
  }
}

.promo__codes__table {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.promo__codes__item {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
  padding: 10px 0;

  & > span {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 10px;
    color: var(--text-color);

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      justify-content: center;

      & > span {
        padding: 5px;
      }
    }
  }

  & > h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #999999;
    font-size: 12px;

    &:last-child {
      text-align: center;
    }
  }

  &:first-child {
    position: sticky;
  }

  &:nth-child(2n) {
    background-color: var(--tr-bg) !important;
  }
}

.action__icon {
  &:hover {
    cursor: pointer;
  }
}
.modal__discount {
  h2 {
    color: var(--main-color);
  }
}

.empty__promo {
  text-align: center;
  font-size: 22px;
  color: var(--main-color);
}
