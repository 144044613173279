.social-block__adaptive {
  margin-top: 30px;
  height: 100px;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  @media screen and (max-width: 1299px) {
    padding: 15px 25px;
    background-color: var(--main-color);
    text-align: center;
    border-radius: 16px;
  }
  @media screen and (max-width: 500px) {
    top: 88%;
  }

  @media screen and (max-width: 460px) {
    left: 50px;
  }

  h4 {
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__icon {
    width: 60px;
    height: 30px;
    cursor: pointer;
  }
}
