.tooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -90%;
  right: 0;
  opacity: 1;
  z-index: 1000;

  p {
    font-size: 16px;
    color: white;
  }

  &__first {
    top: 3%;
    right: 4%;
    transform: translate(-1%, -6%);
  }

  &__body {
    position: relative;
    padding: 20px;
    opacity: 1;
    z-index: 100;

    &::before {
      content: '';
      background: black;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.65;
      border-radius: 12px;
    }
  }

  &__item {
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      opacity: 1;
      z-index: 100;
    }

    &-total {
      font-weight: bold;
    }
  }
}
