@import '../../../styles/colors';

.white-label-file {
  width: 22%;
  display: block;
  background: $main-gradient;
  padding: 15px;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
}

.certificate__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  width: 22%;

  p {
    font-size: 12px;
    color: var(--text-color);
  }

  &-icon {
    color: var(--main-color) !important;
    height: 50px;
    font-size: 50px;
    margin-bottom: 20px;
  }
}
