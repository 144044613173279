@import '../../../../../../styles/colors.scss';
.file-statistic {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.file-statistic__container {
  height: 485px;
  overflow-y: auto;

  @media screen and (max-width: 1450px) {
    height: 390px;
  }

  @media screen and (max-width: 1400px) {
    height: 375px;
  }

  @media screen and (max-width: 1280px) {
    height: 345px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 100px;
  }
}

.file-statistic__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
  width: 100%;
  padding: 10px;
  &:nth-child(2n) {
    background-color: var(--tr-bg) !important;
  }

  & > span {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-color);

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      justify-content: center;

      & > span {
        padding: 5px;
      }
    }
  }

  & > h3 {
    margin: 0;
    padding: 0;

    font-weight: bold;
    color: #999999;

    &:last-child {
      text-align: center;
    }
  }

  &:first-child {
    position: sticky;
  }

  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
}

.file-type__container {
  display: flex;
  margin-bottom: 15px;
  .btn {
    font-size: 22px;
    margin-right: 15px;
    background: #e6e6e6;
    border-radius: 8px;
    color: #fff;
    padding: 9px 25px;

    @media screen and (max-width: 1450px) {
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.completed {
  color: $correctColor;
}

.expired {
  color: $incorrectColor;
}

.file-name {
  &:hover {
    cursor: pointer;
  }
}
