@import '../../../styles/colors';

.sendgrid {
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    color: var(--main-color);
    font-size: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  &-template {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--text-color);

    p {
      font-size: 16px;
      color: var(--text-color);

      &:nth-child(1) {
        width: 250px;
      }

      &:nth-child(2) {
        width: 320px;
      }
    }

    input {
      border-radius: 8px;
      padding: 8px;
      border: 1px solid var(--text-color);
      font-size: 16px;
      width: 320px;
    }

    input:focus {
      border: 1px solid var(--main-color);
      outline: none;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 10px;
      gap: 10px;
    }

    button {
      background: $main-gradient;
      color: white;
      border: 1px solid transparent;
      padding: 5px 10px;
      font-size: 16px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
