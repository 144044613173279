@import '../../../styles/colors';

.insufficient {
  text-align: center;
  padding-bottom: 30px;

  &__modal {
    .ant-modal-body {
      padding: 24px !important;
      background: white;
    }

    .ant-modal {
      background: white;
    }
  }

  a {
    text-decoration: underline !important;
    color: var(--main-color);

    &:hover {
      color: var(--main-color-hover);
    }
  }

  &__header {
    margin-bottom: 30px;

    .warning {
      width: 30px;
      height: 27px;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff0100;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 30px;

    img {
      margin-right: 5px;
    }

    span {
      font-weight: 400;
      margin-left: 10px;
    }
  }

  h5 {
    font-size: 17px;
  }

  &__body {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 7px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 22px;
    width: 40%;
    min-width: 521px;
    margin: 0 auto;
    border: 3px solid var(--main-color-hover);

    &-total {
      width: 65%;
      display: flex;
      align-items: center;
      background: #333333;
      color: white;
      padding: 20px 0;
      border-radius: 19px 0 0 19px;

      p {
        font-size: 18px;
        padding: 0 30px;

        &:first-child {
          padding-right: 15px;
          font-weight: bold;
          border-right: 1px solid white;
        }

        &:last-child {
          padding-left: 15px;
        }
      }
    }

    &-checkout {
      padding: 21px 0;
      width: 35%;
      background: $main-gradient;
      border-radius: 0 19px 19px 0;
      color: white;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      position: relative;

      &:before {
        position: absolute;
        top: 15px;
        left: -15px;
        content: ' ';
        background: white url('../../../assets/pop-up/cartIcon.svg') 7px 8px no-repeat;
        background-size: 15px 15px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        z-index: 1000;
      }
    }
  }
}
