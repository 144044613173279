@import '../../../styles/colors';

.wl-modal {
  display: flex;
  flex-direction: column;
  height: 150px;

  h3 {
    margin: auto;
    font-size: 20px;
    color: var(--text-color);
  }

  &__buttons {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100px;
      background: $main-gradient;
      padding: 10px;
      color: white;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background: $main-gradient-left;
      }
    }
  }
}
