@import './indents';
@import './flexbox';
@import './colors';

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../fonts/glacial-indifference/GlacialIndifference-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../fonts/glacial-indifference/GlacialIndifference-Italic.otf') format('otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../fonts/glacial-indifference/GlacialIndifference-Bold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../fonts/glacial-indifference/GlacialIndifference-Bold.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../fonts/glacial-indifference/GlacialIndifference-Bold.otf') format('otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Thin'), local('BebasNeue-Thin'), url('../fonts/bebasneue/bebasneuethin.woff2') format('woff2'),
    url('../fonts/bebasneue/bebasneuethin.woff') format('woff'), url('../fonts/bebasneue/bebasneuethin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Light'), local('BebasNeue-Light'), url('../fonts/bebasneue/bebasneuelight.woff2') format('woff2'),
    url('../fonts/bebasneue/bebasneuelight.woff') format('woff'), url('../fonts/bebasneue/bebasneuelight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Book'), local('BebasNeueBook'), url('../fonts/bebasneue/bebasneuebook.woff2') format('woff2'),
    url('../fonts/bebasneue/bebasneuebook.woff') format('woff'), url('../fonts/bebasneue/bebasneuebook.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Regular'), local('BebasNeueRegular'), url('../fonts/bebasneue/bebasneueregular.woff2') format('woff2'),
    url('../fonts/bebasneue/bebasneueregular.woff') format('woff'), url('../fonts/bebasneue/bebasneueregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Bold'), local('BebasNeueBold'), url('../fonts/bebasneue/bebasneuebold.woff2') format('woff2'),
    url('../fonts/bebasneue/bebasneuebold.woff') format('woff'), url('../fonts/bebasneue/bebasneuebold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

.primary-button {
  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 8px;
  text-transform: capitalize;
  cursor: pointer;
  display: block;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 8px;
  color: $white;
  text-align: center;
}

.primary-button:hover {
  background: var(--main-color-hover);
}

.success-button {
  background: #04d240;
  border: 1px solid #04d240;
  border-radius: 8px;
  text-transform: capitalize;
  cursor: pointer;
  display: block;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 8px;
  color: $white;
}

.danger-button {
  background: #e21717;
  border: 1px solid #e21717;
  border-radius: 8px;
  text-transform: capitalize;
  cursor: pointer;
  display: block;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 8px;
  color: $white;
}

.success-button:hover {
  background: rgba(4, 210, 64, 0.5);
}

.notification-border {
  border-radius: 16px !important;
}

.touch-callout {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.auth__footer {
  position: relative;
  width: 100%;
  height: 90px;
  margin-top: auto;
  overflow: hidden;

  @media screen and(max-width: 1200px) {
    display: none !important;
  }

  @media screen and(max-width: 1380px), screen and (max-height: 700px) {
    display: none !important;
  }

  &__outer {
    position: relative;
    width: 415px;
    height: 190px;
    background-color: var(--main-color);
    margin-left: calc(100% - 355px);
    border-top-left-radius: 100%;

    @media screen and (max-width: 1450px) {
      height: 170px;
    }

    p {
      font-size: 12px;
      position: absolute;
      color: white;
      z-index: 1000;
      top: 40%;
      left: 30%;
      transform: translate(0, -50%);

      @media screen and (min-width: 1500px) {
        top: 35%;
      }
    }
  }

  @media screen and (max-width: 455px) {
    display: none;
  }
}

.auth__button {
  border-radius: 30px;
  height: 55px !important;
  font-size: 25px;
  border: var(--main-color);
  background: $main-gradient;
  cursor: pointer;
  display: block;
  font-family: 'Glacial Indifference', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 20px;
  color: $white;
  text-align: center;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 30px;
  &:disabled {
    background: #ccc !important;
    cursor: not-allowed;
  }

  @media screen and (max-width: 1450px) {
    height: 50px !important;
    line-height: 8px;
    margin-top: -5px;
    font-size: 20px;
  }

  @media screen and (max-width: 1450px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 370px) {
    text-align: left;
  }

  @media screen and (max-height: 700px) {
    height: 35px !important;
    margin-top: 10px;
    font-size: 17px;
    line-height: 4px;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 29%;
    right: 33px;
    width: 33px;
    height: 30px;
    background: url('../assets/auth/signIn.png') 0 0 no-repeat;

    @media screen and (max-width: 1400px) {
      top: 26%;
    }
  }
}

.auth__title {
  font-size: 30px !important;
  text-transform: uppercase;
  color: black !important;
  line-height: 0;
  margin: 30px 0 20px 0 !important;
  border: none !important;
  text-align: center;

  @media screen and (max-width: 1400px) {
    font-size: 25px !important;
    margin: 20px 0 10px !important;
  }

  @media screen and (max-width: 1200px) {
    font-size: 22px !important;
  }

  @media screen and (max-width: 800px) {
    font-size: 19px !important;
  }

  span {
    color: var(--main-color);
  }
}

.authentication__logo {
  width: 405px;
  height: 185px;
}

.spinner-container {
  width: 50px;
  margin: auto;
}

.ant-spin-dot-item {
  background: var(--main-color) !important;
}

a:hover {
  color: var(--main-color);
  opacity: 0.8;
}

.swiper-pagination-bullet-active {
  background: var(--main-color) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--main-color) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid var(--main-color) !important;
}

.ant-result-title {
  color: var(--text-color) !important;
}

.ant-result-subtitle {
  color: var(--text-color) !important;
}

.ant-btn {
  background: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}

.ant-btn-primary {
  background: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}

.ant-modal {
  top: 50% !important;
  transform: translate(0, -50%) !important;

  @media screen and (max-width: 1590px) {
    top: 50px !important;
    transform: translate(0, -0) !important;
  }
}
