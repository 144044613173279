.notification-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  height: calc(100% - 60px);
  padding: 0 20px;
  margin-top: 40px;
  position: relative;
  h1 {
    text-decoration: underline;
    font-weight: bold;
    margin-top: 30px;
    color: var(--main-color);
  }
  .toggle-send__notification {
    display: flex;
    align-items: center;
    gap: 15px;
    span {
      font-size: 19px;
      font-weight: bold;
    }
  }
  .marketing-text {
    font-size: 22px;
    line-height: 1.2;
    @media screen and (max-width: 1500px) {
      font-size: 20px;
    }
    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }
  button {
    position: absolute;
    bottom: 0;
    right: 35px;
    color: white;
    background: linear-gradient(to right, var(--gradient-1) 0%, var(--gradient-2) 52%, var(--gradient-3) 100%);
    padding: 15px;
    border-radius: 15px;
    width: 160px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    &:disabled {
      background: #c3c3c3;
      color: rgb(141, 134, 126);
      cursor: progress;
    }
  }
  .notifications-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
