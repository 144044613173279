@import '../../../styles/colors';

.stripe-modal {
  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }

  .payment-modal {
    max-height: 555px;
    min-height: 555px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--main-color);
      border-radius: 100px;
    }
  }

  &__body-item {
    &:first-child {
      position: relative;
      padding: 44px 165px 150px 77px;
      width: 76%;
      border-radius: 32px 0 0 32px;
      display: flex;
      flex-direction: column;

      @media screen and(max-width: 1600px) {
        padding-right: 100px;
      }
    }

    &:last-child {
      width: 24%;
      border-radius: 0 32px 32px 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    bottom: -2px;
    left: 0;
    background: url('../../../assets/pop-up/modal__footer-stripe.svg') 0 0 no-repeat;
    background-size: 255px 80px;
    width: 255px;
    height: 80px;

    p {
      color: white;
      margin-right: 5px;
      font-size: 16px;
      @media screen and (max-width: 1300px) {
        font-size: 14px;
      }
    }

    img {
      margin: 0 5px;
    }
  }

  &__body-item-stripe {
    h2 {
      color: var(--text-color);
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 15px;
      text-align: center;

      span {
        color: var(--main-color);
      }
    }

    h3 {
      text-align: center;
      font-weight: normal;
      margin: 0 25% 40px 25%;
      line-height: 22px;
      color: var(--text-color);

      @media screen and (max-width: 1600px) {
        margin-bottom: 15px;
      }

      @media screen and (max-width: 1300px) {
        margin: 0 15% 40px 15%;
      }

      span {
        font-weight: bold;
      }
    }

    label {
      span {
        color: var(--text-color);
        font-size: 24px;
        margin-left: 20px;
        @media screen and (max-width: 1300px) {
          font-size: 20px;
        }
      }
    }
  }

  &__order {
    background: $main-gradient;
    height: 100%;
    border-radius: 0 46px 46px 0;
    display: flex;
    flex-direction: column;
  }

  &__order-header {
    border-radius: 0 46px 0 46px;
    background: $total-gradient;
    padding: 10px 10px 28px 10px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    @media screen and (max-width: 1600px) {
      padding: 10px 10px 18px 10px;
    }

    h3 {
      background: url('../../../assets/pop-up/white_check.svg') 50% 21% no-repeat;
      background-size: 30px 30px;
      font-size: 30px;
      padding: 58px 0 14px 0;
      margin-bottom: 14px;
      color: white;
      font-weight: bold;
      letter-spacing: -1px;
      border-bottom: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(90deg, transparent 0%, transparent 14%, white 51%, transparent 85%, transparent 100%);
      @media screen and (max-width: 1600px) {
        font-size: 26px;
      }
    }

    p {
      font-size: 18px;
      @media screen and (max-width: 1300px) {
        font-size: 16px;
      }
    }
  }

  &__order-body {
    text-align: center;
    padding: 0 20px 25px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 6500px) {
      padding: 0 20px 15px;
    }

    &-total {
      color: white;
      font-size: 24px;
      font-weight: bold;
      margin: 20px 0;

      @media screen and (max-width: 1600px) {
        font-size: 20px;
      }
    }
  }

  &__order-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    position: relative;

    &:after {
      position: absolute;
      top: 50%;
      left: 52%;
      transform: translate(-50%, -50%);
      content: ' ';
      height: 2px;
      width: 35%;
      border-bottom: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.4) 14%,
        white 51%,
        rgba(255, 255, 255, 0.4) 85%,
        rgba(255, 255, 255, 0.2) 100%
      );
    }

    p {
      text-align: left;
      width: 90px;
      color: white;
      font-size: 16px;
      margin-bottom: 5px;

      @media screen and (max-width: 1600px) {
        font-size: 14px;
      }

      &:last-child {
        width: 60px;
      }
    }
  }

  &-img {
    width: 70px;
    height: 50px;

    @media screen and (max-width: 1650px) {
      width: 50px;
    }
  }

  .stripe-checkbox input:checked + label:after {
    top: 8px;
    left: 6px;
    width: 7px;
    height: 14px;
  }

  &__back {
    position: absolute;
    left: 25px;
    top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    color: white;
    border-radius: 6px;
    outline: none;
    border: none;
    background: $main-gradient;
    font-size: 13px;
  }
}

.stripe-checkbox {
  label {
    color: var(--text-color);
  }

  .form-group label:before {
    padding: 10px;
  }
}

.stripe-modal__field {
  color: var(--text-color);
  background: var(--content-bg);
  border: 1px solid #bababa;
  width: 100%;
  border-radius: 12px;
  height: 58px;
  margin: 0 0 8px 0;
  padding-left: 20px;
  font-size: 20px;
  outline: none;

  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
}

.stripe-modal__field::placeholder {
  font-size: 20px;
  color: var(--text-color);
}

.stripe-label {
  text-align: left;

  span {
    font-size: 24px;
    margin-left: 15px;
  }

  &:nth-child(1) {
    width: 40%;
    margin-right: 10px;
  }

  &:nth-child(2) {
    width: 30%;
    margin-right: 10px;
  }

  &:nth-child(3) {
    width: 30%;

    margin-bottom: 0;
  }
}

.checkout__button {
  color: white;
  background: $total-gradient;
  border-radius: 40px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 10px 0;
  width: 80%;
  font-size: 20px;
  margin: auto auto 5px auto;
}

.back {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  cursor: pointer;

  .arrow {
    width: 16px;
    height: 8px;
    margin-right: 5px;
  }
}

.ant-modal-content {
  background-color: var(--content-bg) !important;
  border-radius: 50px !important;
}

.ant-modal-close-x {
  display: none !important;
}

.chose-cards {
  position: relative;
  min-height: 100%;

  &-button {
    position: absolute;
    bottom: -120px;
    left: 55%;
    transform: translate(-50%, 0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    color: white;
    border-radius: 6px;
    outline: none;
    border: none;
    background: $main-gradient;
    font-size: 13px;
  }

  h2 {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    color: var(--text-color);

    span {
      color: var(--main-color);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 30px 0 0 0;
    margin: 0;
    gap: 10px;
    overflow-y: auto;
    height: 429px;

    @media screen and (max-width: 1600px) {
      height: 300px;
      margin-bottom: 40px;
    }
  }

  &__list-item {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    padding: 10px;
    text-align: center;
    transition: 0.5s all ease-in;
    gap: 30px;
    border-bottom: 1px solid var(--main-color-hover);

    &:first-child {
      position: -webkit-sticky;
      position: sticky;
    }

    h3 {
      color: var(--text-color);
      @media screen and (max-width: 1600px) {
        font-size: 14px;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }

    img {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      color: var(--text-color);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      @media screen and (max-width: 1600px) {
        font-size: 12px;
      }

      &:nth-child(2) {
        justify-content: flex-start;
      }
    }

    input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      cursor: pointer;
      color: var(--text-color);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;

      font-weight: bold;

      border: none;
      border-radius: 5px;
      color: var(--main-color);
      background: transparent;

      cursor: pointer;
      transition: 0.25s ease all;

      &:hover,
      &:focus-visible {
        color: rgba(24, 144, 255, 0.5);
      }
    }
  }
}

.discount-code {
  position: relative;
  width: 50%;
  height: 40px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  transition: all 0.4s cubic-bezier(0.21, 1.04, 1, 0.06);

  @media screen and (max-width: 1400px) {
    width: 350px;
  }

  @media screen and (max-width: 1300px) {
    width: 300px;
  }

  &__field {
    background-clip: content-box, border-box;
    background-image: linear-gradient(var(--content-bg), var(--content-bg)), $main-gradient;
    background-origin: border-box;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 1px;
    width: calc(100% - 40px);
    height: 40px;
    display: flex;
    flex-direction: column;
  }

  input {
    margin: auto;
    height: 40px;
    outline: none;
    z-index: 0;
    width: 100%;
    padding-left: 15px;
    font-size: 14px;
    border: 1px solid transparent;
    background: transparent;
    color: var(--text-color);

    &::placeholder {
      font-style: italic;
      color: var(--text-color);
    }
  }

  button {
    background: $main-gradient;
    color: white;
    outline: none;
    border: none;
    border-radius: 6px;
    padding: 3px 10px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.21, 1.04, 1, 0.06);

    &:hover {
      background: $main-gradient-left;
      transition: all 0.4s ease-in;
    }
  }

  p {
    color: #ff1e1e;
    font-size: 13px;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
