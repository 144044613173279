.certificate-modal {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  background: var(--content-bg);
  padding: 0 15px;
  gap: 20px;

  h1 {
    color: var(--text-color);
    font-size: 30px;
  }

  h3 {
    color: var(--text-color);
  }
}

.ant-certificate {
  .ant-modal-footer {
    border-radius: 0 0 50px 50px !important;
    height: 65px !important;
    background: transparent !important;
  }
}
