@import '../../../styles/colors';
.edit-password {
  display: flex;
  flex-direction: column;
  min-height: 500px;

  h3 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 30px;
  }

  .edit__field {
    width: 100%;
  }

  .label-password {
    position: relative;
  }

  .icon-eye {
    position: absolute;
    top: 31%;
    right: 4%;
    color: var(--main-color) !important;
  }

  .button-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid;
    border-image-slice: 1;
    border-image-source: $main-gradient;
    padding: 20px 44px 0 44px;
    margin: auto -44px 0 -44px;
  }

  button {
    color: white;
    background: $main-gradient;
    padding: 10px;
    border-radius: 20px;
    width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
