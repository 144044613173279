.gradient-picker {
  position: relative;
  height: 40px;
  border-radius: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
  border: 1px solid var(--text-color);
  flex: 1;

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 30%;
    position: relative;

    @media screen and(max-width: 1600px) {
      width: 45%;
    }

    @media screen and(max-width: 1300px) {
      width: 100%;
    }
  }

  h2 {
    position: absolute;
    top: -30px;
    color: var(--text-color);
  }

  &-item {
    position: absolute;
    bottom: 0;
    z-index: 1000;

    &:nth-child(2) {
      left: 5%;
    }

    &:nth-child(3) {
      left: 50%;
      transform: translate(-50%, 0);
    }

    &:nth-child(4) {
      left: 90%;

      .sketch-picker {
        right: 0;
      }
    }

    .sketch-picker {
      position: absolute;
      top: 0;
      z-index: 1000;
    }
  }
}

.gradient-picker-item-btn {
  color: var(--main-color);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  background: transparent;
  z-index: 1 !important;
  height: 40px;
  width: 40px;
}
