@import '../../../../styles/colors';

.users-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;

  &__form-skips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.users-list__container {
  height: 420px;
  margin-bottom: 1rem;
  overflow-y: scroll;

  @media screen and (max-width: 1450px) {
    height: 390px;
  }

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 320px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 100px;
  }
}

.with-pagination {
  height: 295px !important;

  @media screen and (max-width: 1920px) {
    height: 440px !important;
  }

  @media screen and (max-width: 1450px) {
    height: 330px !important;
  }

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 275px !important;
  }
}

.users-list__item {
  position: relative;
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1.5fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  width: 100%;
  padding: 10px;

  & > span {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-color);

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      justify-content: center;

      & > span {
        padding: 5px;
      }
    }
  }

  & > h3 {
    margin: 0;
    padding: 0;
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }

    font-weight: bold;
    color: #999999;

    &:nth-child(5) {
      margin-left: -10px;
    }
    &:nth-child(6) {
      margin-left: -10px;
    }
    &:nth-child(7) {
      margin-left: -10px;
    }
    &:nth-child(8) {
      margin-left: -10px;
    }
    &:nth-child(9) {
      margin-left: -10px;
    }

    &:last-child {
      text-align: center;
    }

    .sort-icon {
      @media screen and (max-width: 1500px) {
        margin-left: 5px;
      }
    }
  }

  &:first-child {
    position: sticky;
  }

  &:nth-child(2n) {
    background-color: var(--tr-bg) !important;
  }
}

.users-list__item-status {
  display: flex;
  flex-wrap: wrap;

  & > span {
    width: 100%;
  }
}

.users-list__popover {
  display: flex;
  width: 450px;
  gap: 10px;
  flex-wrap: wrap;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 45px;
    color: #fff;
    border: none;
    border-radius: 5px;
    background: $main-gradient;
    cursor: pointer;
    transition: 0.25s ease all;
    text-transform: capitalize;
    &:hover,
    &:focus-visible {
      background: $main-gradient-left;
    }
  }
}

.historyPush__link {
  transition: color 0.2s ease-in-out;
  color: var(--text-color);

  &:hover {
    cursor: pointer;
    color: var(--main-color);
  }
}

.add-skips__btn {
  padding: 5px;
}

.skips-btn {
  height: 35px !important;
}

.close__pop {
  position: absolute;
  top: -15px;
  right: 15px;
  font-size: 35px;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: red;
  }
}
