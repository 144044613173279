.web-hook {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: 460px;
  overflow: scroll;

  @media screen and (max-width: 1700px) {
    height: 400px;
  }

  @media screen and (max-width: 1500px) {
    height: 320px;
  }

  &__spinner {
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__field {
    display: flex;
    align-items: center;
    gap: 20px;

    &:first-child {
      width: 100%;
    }

    &:last-child {
      width: 100%;
    }
  }

  &__field-action {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .label {
    flex: 1;
  }

  .ant-btn {
    color: white !important;
    border-radius: 8px !important;
    height: 45px !important;
  }
}

.web-hook__btn {
  margin: auto auto 20px auto;
  width: 300px;
  color: white !important;
  border-radius: 8px !important;
  height: 45px !important;
}
