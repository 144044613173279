.footer-section {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 400px;
  background-color: #191919;

  svg{
    width: 300px;
    height: auto;
  }
}