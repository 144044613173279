@import '../../../../styles/colors';

.landing__advantages-section {
  padding: 100px 1rem 2rem 1rem;
  background: url('../../../../assets/landing/landing-form.svg') 0 0 no-repeat;
  box-shadow: 10px 10px 10px gray;

  @media screen and (max-width: 1400px) {
    background: url('../../../../assets/landing/landing-form.svg') top center no-repeat;
    background-size: 150%;
  }

  @media screen and (max-width: 1200px) {
    background: url('../../../../assets/landing/landing-form.svg') top center no-repeat;
    background-size: 200%;
  }

  @media screen and (max-width: 750px) {
    background-size: 280%;
  }

  @media screen and (max-width: 600px) {
    background-size: 350%;
    margin: -84px 0 0 0;
  }

  @media screen and (max-width: 500px) {
    background-size: 450%;
  }

  & > h2 {
    width: 100%;
    padding: 60px 0;
    background: url('../../../../assets/landing/icons/icon-search.svg') 50% 0 no-repeat;
    background-size: 45px 45px;
    font-size: 40px;
    text-align: center;
    color: #fff;
    font-family: 'Bebas Neue', sans-serif;

    @media screen and (max-width: 1200px) {
      font-size: 32px;
      padding: 50px 0 10px 0;
    }
    @media screen and (max-width: 500px) {
      background: url('../../../../assets/landing/icons/search-in-circle.svg') 50% 8% no-repeat;
      background-size: 36px 36px;
      padding: 50px 0 20px 0;
    }
  }
}

.landing__advantages-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  gap: 10px;

  list-style: none;

  @media screen and(max-width: 1450px) {
    margin-bottom: 90px;
  }

  @media screen and(max-width: 1450px) {
    margin-bottom: 120px;
  }

  @media screen and(max-width: 800px) {
    margin-bottom: 40px;
  }
}

.landing__advantages-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  border: 2px solid #e4e4e4;
  border-radius: 20px;
  background-color: #fff;
  padding: 1rem 1rem 60px;
  box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  max-height: 351px;

  @media screen and (max-width: 1200px) {
    max-width: 40%;
  }

  @media screen and (max-width: 800px) {
    max-width: 45%;
  }
  @media screen and (max-width: 500px) {
    max-height: 220px;
    border: none;
  }

  &:nth-child(2n) {
    border-bottom-right-radius: 70px;
    @media screen and (max-width: 500px) {
      border-bottom-right-radius: 85px;
    }
  }

  &:nth-child(2n + 1) {
    border-bottom-left-radius: 70px;
    @media screen and (max-width: 500px) {
      border-bottom-left-radius: 85px;
    }
  }

  &:nth-child(1) {
    @media screen and (max-width: 1200px) {
      border-radius: 25px;
    }
  }

  &:nth-child(2) {
    margin: -25px 0 20px 0;

    @media screen and (max-width: 1200px) {
      margin: 0;
      border-radius: 20px;
    }
  }

  &:nth-child(4) {
    img {
      @media screen and (max-width: 800px) {
        margin-bottom: 15px;
      }
    }
  }

  img {
    width: 80%;
    margin-bottom: 1rem;
    height: auto;
    max-width: 70px;

    @media screen and (max-width: 1200px) {
      margin-bottom: 10px;
      min-height: 20px;
    }
  }

  .icon-map {
    margin-bottom: 8px;
    width: 45px;
  }

  .icon-download {
    @media screen and (max-width: 800px) {
      width: 58px;
    }
  }

  & > h3 {
    padding-top: 1rem;
    max-width: 200px;
    margin-bottom: 1rem;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
    position: relative;

    @media screen and (max-width: 1200px) {
      font-size: 25px;
    }

    @media screen and (max-width: 700px) {
      font-size: 19px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      content: '';
      width: 90%;
      background: linear-gradient(
                      to right,
                      transparent 0%,
                      var(--gradient-3) 10%,
                      var(--gradient-3) 22%,
                      var(--gradient-2) 50%,
                      var(--gradient-3) 78%,
                      var(--gradient-3) 90%,
                      transparent 100%
      );
      height: 2px;
      min-width: 150px;
      max-width: 150px;

      @media screen and (max-width: 600px) {
        max-width: 75px;
        min-width: 75px;
      }
    }
  }

  & > p {
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }

    @media screen and (max-width: 700px) {
      font-size: 11px;
    }
    @media screen and (max-width: 450px) {
      font-size: 10px;
      line-height: 14px;
    }
    @media screen and (max-width: 350px) {
      font-size: 9px;
      line-height: 11px;
    }
  }

  &:first-child {
    & > h3 {
      max-width: 130px;
    }
  }
}

.landing__advantages-simple-article {
  display: flex;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 1200px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    gap: 0;
    margin-top: 20px;
  }

  & > img {
    width: 50%;
    height: auto;

    @media screen and(max-width: 800px) {
      width: 100%;
    }
    @media screen and(max-width: 500px) {
      width: 100%;
    }
  }
}

.landing__advantages-simple-container {
  flex-grow: 1;

  @media screen and(max-width: 800px) {
    width: 100%;
    margin-bottom: 20px;
  }

  & > h3 {
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 0;
    font-family: 'Bebas Neue', sans-serif;
    text-transform: uppercase;
    font-size: 55px;
    text-align: center;

    @media screen and(max-width: 1200px) {
      font-size: 40px;
    }

    @media screen and(max-width: 800px) {
      font-size: 51px;
    }

    @media screen and(max-width: 500px) {
      font-size: 48px;
    }

    @media screen and(max-width: 500px) {
      font-size: 38px;
    }

    .simple__line {
      position: absolute;
      left: 30%;
    }

    & > b {
      color: var(--main-color);
      @media screen and(max-width: 500px) {
        display: block;
        font-size: 47px;
        line-height: 44px;
        margin-top: 15px;
      }
    }
  }
}

.landing__advantages-simple-list {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin: 0 0 35px;
  padding: 0;

  list-style: none;

  @media screen and(max-width: 800px) {
    width: 80%;
    margin: 0 auto 40px;
  }
}

.landing__advantages-simple-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  & > span {
    font-size: 24px;
    font-style: italic;

    @media screen and(max-width: 1200px) {
      font-size: 20px;
    }

    @media screen and(max-width: 500px) {
      line-height: 16.2px;
      font-size: 13px;
    }
  }

  & > svg {
    width: 35px;
    height: 35px;

    @media screen and(max-width: 500px) {
      width: 25px;
      height: 25px;
    }
  }
}

.landing__advantages-link-button {
  max-width: 330px;
  margin: 0 auto;
  font-size: 20px;
  background: $main-gradient;

  @media screen and (max-width: 500px) {
    font-size: 23px;
    max-width: 250px;
  }
}
