@import '../../../../styles/colors';

.lading__welcome-section {
  position: relative;
  padding: 2rem;
  background: url('../../../../assets/landing/welcome-background.png') no-repeat center;
  background-size: cover;

  @media screen and (max-width: 1450px) {
    padding-top: 1rem;
  }

  &::before {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    top: -15px;

    display: block;
    width: 100%;
    aspect-ratio: 1920/ 735;
    background: url('../../../../assets/landing/icons/header-mask.svg') no-repeat;
    background-size: cover;
    z-index: 0;

    @media screen and (max-width: 1280px) {
      aspect-ratio: 960/520;
    }
  }

  @media screen and (max-width: 800px) {
    background: #ffffff;
  }
}

.landing__welcome-decoraion {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  display: block;
  aspect-ratio: 1920/ 735;

  background-size: cover;
  background: url('../../../../assets/landing/header-background2.png') no-repeat;
  mask: url('../../../../assets/landing/icons/header-mask.svg');
  mask-size: cover;
  mask-repeat: no-repeat;

  @media screen and (max-width: 1280px) {
    mask-size: auto;
    aspect-ratio: 960/520;
  }
}

.landing__signup-link {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 15px 25px;
  width: 100%;

  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;

  border-radius: 35px;
  background: $main-gradient;

  transition: 0.25s ease all;

  @media screen and (max-width: 1280px) {
    font-size: 14px;
    padding: 10px 15px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 11px;
    padding: 5px 15px;
  }

  &:hover,
  &:focus-visible {
    opacity: 0.9;
    color: var(--main-color);
  }

  span {
    &:first-child {
      flex-grow: 1;
      text-align: center;


      @media screen and (max-width: 800px) {
        font-size: 14px;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin-left: 15px;

      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  & > svg {
    width: 16px;
    height: auto;
  }
}

.landing__header {
  position: relative;

  display: grid;
  grid-template-columns: 2fr 650px 1fr 285px;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 2fr 650px 1fr 230px;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 2fr 610px 1fr 200px;
  }
}

.landing__header-decoration {
  display: block;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 100%;
}

.landing__nav {
  position: relative;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
}

.landing__nav-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0;
  padding: 0;
  background: inherit;

  list-style: none;
}

.landing__nav-link {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #fff;

  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }

  &:hover,
  &:focus-visible {
    color: white;
    text-shadow: 2px 2px 2px var(--main-color);
  }
}

.landing__login-link-button {
  background: rgba(0, 0, 0, 0.5);

  & > span {
    &:last-child {
      background: $main-gradient;
    }
  }
}

.landing__feature-container {
  position: relative;
  z-index: 2;

  & > img {
    display: block;
    width: 410px;
    height: auto;
    margin: 0 auto 4rem;

    @media screen and (max-width: 1600px) {
      width: 350px;
      margin: 0 auto 2rem;
    }

    @media screen and (max-width: 1400px) and (max-height: 700px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 800px) {
      width: 360px;
    }

    @media screen and (max-width: 560px) {
      width: 210px;
      margin-top: -20px;
      margin-bottom: 3rem;
    }
  }
}

.landing__login-container {
  position: relative;

  & > span {
    position: absolute;
    bottom: -20px;

    display: flex;
    width: 100%;
    justify-content: center;

    color: #fff;
    z-index: 3;
  }

  .landing__login-link {
    display: inline-block;
    margin: 0 3px;

    color: #fff;
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      color: #000;
    }
  }
}

.landing__monitor-container {
  position: relative;
  max-width: 1000px;
  margin: -15px auto 1rem;
  background-image: url('../../../../assets/landing/skip-gif.gif');
  background-size: 77% 78%;
  background-position: 50% 24%;
  background-repeat: no-repeat;

  @media screen and(max-width: 1600px) {
    max-width: 800px;
  }

  @media screen and(max-width: 1400px) {
    max-width: 650px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  @media screen and (max-width: 1400px) and (max-height: 700px) {
    margin-bottom: 2rem;
    margin-top: 15px;
  }


  & > span {
    position: absolute;
    top: 50%;
    right: -75px;
    width: 200px;
    padding: 1rem 2rem;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border-radius: 0 40px 40px 0;
    background: $main-gradient;
    transform: translateY(-50%);
    z-index: -1;

    @media screen and (max-width: 1620px) {
      right: -100px;
    }

    @media screen and (max-width: 1400px) and (max-height: 700px) {
      right: -120px;
    }

    @media screen and (max-width: 1300px) {
      right: -120px;
    }

    @media screen and(max-width: 1000px) {
      display: none;
    }
  }
}

.landing__monitor-picture {
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
}

.landing__welcome-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and(max-width: 900px) {
    text-align: center;
  }

  & > h1 {
    margin: 0 0 1rem;
    padding: 0;
    font-weight: bold;

    font-size: 50px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;

    @media screen and (max-width: 1450px) {
      font-size: 36px;
    }

    @media screen and (max-width: 800px) {
      font-size: 27px;
    }
    @media screen and (max-width: 450px) {
      font-size: 29px;
    }

    & > b {
      color: var(--main-color);
    }
  }

  & > p {
    font-size: 22px;
    @media screen and (max-width: 1450px) {
      font-size: 19px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 16px;
    }

    @media screen and (max-width: 800px) {
      line-height: 1.2;
    }
    @media screen and (max-width: 450px) {
      font-size: 12.5px;
    }
  }

  & > span {
    font-size: 21px;
    @media screen and (max-width: 1450px) {
      font-size: 19px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 16px;
    }

    @media screen and(max-width: 800px) {
      display: none;
    }
  }
}

.landing__welcome-form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;

  @media screen and(max-width: 800px) {
    display: block;
    min-width: 330px;
    background: #fff;
    border-radius: 16px;
    -webkit-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    padding: 15px;
  }

  @media screen and(max-width: 300px) {
    min-width: 280px;
  }

  input {
    flex-grow: 1;
    height: 60px;
    padding: 0 1rem;

    font-size: 18px;

    transition: 0.25s ease all;

    &::placeholder {
      color: #979797;
      @media screen and(max-width: 800px) {
        text-align: center;
      }
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
      transition: 0.25s all ease-in-out;
    }

    &:hover,
    &:focus-visible {
      outline: none;
      border-color: var(--main-color);
    }

    @media screen and(max-width: 800px) {
      margin: 0 auto 10px;
      width: 90%;
      max-width: 90%;
      border-radius: 30px;
    }
  }

  p {
    color: #cccccc;
  }

  h6 {
    font-size: 20px;

    b {
      color: var(--main-color);
    }
  }

  p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 0.7;
  }
}

.landing__input-container {
  position: relative;

  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 530px;

  & > input {
    padding-right: 250px;
    background-color: #e4e4e4;

    @media screen and (max-width: 1000px) {
      padding-right: 175px;
    }

    @media screen and(max-width: 800px) {
      padding: 0 1rem;
    }
  }

  @media screen and(max-width: 800px) {
    max-width: 100%;
    width: 100%;
    margin: 0 auto 10px;
  }
}

.landing__welcome-name {
  max-width: 255px;
  margin-right: 10px;
  border: 2px solid #e4e4e4;
  border-radius: 30px 0 0 30px;
  background-color: #e4e4e4;

  &::placeholder {
    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }
  }
}

.landing__welcome-last-name {
  max-width: 255px;
  margin-right: 10px;
  border: 2px solid #e4e4e4;
  background-color: #e4e4e4;

  &::placeholder {
    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }
  }
}

.landing__welcome-email {
  width: 100%;
  border: 2px solid #e4e4e4;
  border-radius: 0 30px 30px 0;

  &::placeholder {
    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }
  }
}

.landing__welcome-form-link {
  position: absolute;
  top: 50%;
  right: 7px;

  display: flex;
  align-items: center;
  width: 225px;
  padding: 7px 25px;

  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;

  border-radius: 35px;
  background: $main-gradient;

  transition: 0.25s ease all;
  transform: translateY(-50%);

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    padding: 7px 10px;
    width: 160px;
  }

  @media screen and(max-width: 800px) {
    bottom: -25%;
    left: 50%;
    transform: translate(-50%, 25%);
    max-height: 50px;
    margin-top: 115px;
    width: 225px;
  }

  @media screen and(max-width: 560px) {
    margin-top: 125px;
  }
}
