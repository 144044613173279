@import '../../../styles/colors';

.card {
  width: 100%;
  border-radius: 16px;
  -webkit-box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);
  height: 260px;
  margin: 0 2px 40px 2px;

  &__header {
    padding: 35px 0;
    background: #333333;
    border-radius: 16px 16px 0 0;
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      left: 50%;
      bottom: -39px;
      border: 20px solid transparent;
      border-top: 20px solid #333333;
      transform: translate(-50%, 0);
    }

    &-profit {
      background: var(--main-color);

      &:after {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: -39px;
        border: 20px solid transparent;
        border-top: 20px solid var(--main-color);
        transform: translate(-50%, 0);
      }
    }

    p {
      font-size: 26px;
      font-weight: normal;
      color: white;
    }

    span {
      font-weight: bold;
    }
  }

  &__body {
    padding: 50px 0 10px;
    background: #f1f1f1;

    &-border {
      border-bottom: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(90deg, #f1f1f1 0%, #f1f1f1 15%, #d5d5d5 51%, #f1f1f1 85%, #f1f1f1 100%);
    }

    p {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-family: Bebas Neue, sans-serif;
      font-size: 70px;
      background: $main-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;

      span {
        font-size: 40px;
      }
    }

    .profit-price {
      background: -webkit-linear-gradient(360deg, #333333 0, #333333 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .discount-price {
      background: -webkit-linear-gradient(360deg, red 0, purple 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .profit__per-hit {
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      font-family: sans-serif;
      background: -webkit-linear-gradient(360deg, #60b58e 0, #34b3e9 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 5px 30px 5px 0;
      font-weight: 200;
    }
  }

  p {
    text-transform: uppercase;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__footer {
    background: #f1f1f1;
    border-radius: 0 0 16px 16px;
    padding: 10px 0 30px;
    position: relative;
  }

  &__footer-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    background: url('../../../assets/pop-up/card_bg_star.svg') 0 0 no-repeat;
    width: 190px;
    height: 45px;
    background-size: 190px 45px;
    display: flex;
    align-items: flex-end;
    padding: 5px 10px 15px;

    img {
      margin-left: 20px;
    }

    p {
      color: white;
      margin-left: 8px;
    }
  }

  &__footer-bg-deal {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
    height: 45px;
    background-size: 120px 45px;
    display: flex;
    align-items: center;
    padding: 5px 0 5px;
    background: url('../../../assets/pop-up/best_deal.svg') 0 0 no-repeat;

    img {
      margin-left: 10px;
    }

    p {
      color: white;
      margin-left: 8px;
    }
  }

  &__count {
    border-radius: 16px;
    padding: 2px;
    background-image: linear-gradient(white, white), $main-gradient;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  &__count-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-btn {
      font-weight: bold;
      width: 60%;
      padding: 11px 0;
      border: none;
      outline: none;
      cursor: pointer;
      background: transparent;
      height: 100%;
      font-size: 18px;
    }

    .active {
      border-radius: 13px 0 0 13px;
      background: $main-gradient;
      color: white;
    }

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f1f1;
      width: 40%;
      padding: 9px 0;
      border-radius: 0 14px 14px 0;

      button {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border: none;
        outline: none;
        width: 12px;
        height: 12px;
        background: $main-gradient;
        border-radius: 50%;
        cursor: pointer;
        font-size: 8px;
        color: white;
        font-weight: bold;
        position: relative;

        &:first-child {
          &:before {
            content: '-';
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
          }
        }

        &:last-child {
          &:before {
            content: '+';
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
          }
        }
      }

      p {
        font-weight: bold;
        width: 30%;
        padding: 8px 0;
        font-size: 16px;
      }
    }
  }
}
