@import '../../../../styles/colors';

.other-cards {
  margin: 70px -60px 0 -50px;
  padding: 0 10px;

  h1 {
    color: var(--text-color);

    @media screen and (max-width: 1450px) {
      font-size: 20px;
    }
  }

  a {
    color: var(--main-color);

    &:hover {
      color: var(--main-color-hover);
    }
  }

  @media screen and (max-width: 1550px) {
    margin: 70px -20px 0 -20px;
  }

  @media screen and(max-width: 1520px) {
    margin: 50px -20px 0 -10px;
  }

  @media screen and (max-width: 1366px) {
    margin: 50px 0 0;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 100px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;
    overflow-y: auto;
    height: 480px;

    @media screen and (max-width: 1450px) {
      height: 420px;
    }
  }

  &__list-item {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 100px 2fr 2fr 1fr 100px;
    padding: 10px;
    text-align: center;
    transition: 0.5s all ease-in;
    gap: 30px;
    border-bottom: 1px solid var(--main-color-hover);

    @media screen and(max-width: 1366px) {
      grid-template-columns: 80px 2fr 2fr 1fr 80px;
    }

    &:first-child {
      position: -webkit-sticky;
      position: sticky;
    }

    h3 {
      color: var(--text-color);
      @media screen and (max-width: 1500px) {
        font-size: 14px;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }

    img {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      color: var(--text-color);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }

      &:nth-child(2) {
        justify-content: flex-start;
      }
    }

    input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      cursor: pointer;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;

      font-weight: bold;

      border: none;
      border-radius: 5px;
      color: var(--main-color);
      background: transparent;

      cursor: pointer;
      transition: 0.25s ease all;

      &:hover,
      &:focus-visible {
        color: var(--main-color-hover);
      }
    }
  }
}
