@import '../../../styles/colors';

.invite-modal {
  .ant-select-selector {
    border: none !important;
    background: $white !important;
    border-radius: 8px !important;
    height: 50px !important;
  }

  .ant-select-arrow {
    background: url('../../../assets/UI/icon_arrow.svg') 0 0 no-repeat;
    width: 25px;
    height: 25px !important;
    transform: translate(0, -20%);
    color: transparent !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
  }

  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }

  .ant-modal-content {
    border-radius: 16px !important;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    button {
      background: $main-gradient;
      cursor: pointer;
      color: white;
      outline: none;
      border: 1px solid transparent;
      padding: 5px 10px;
      border-radius: 8px;

      &:hover {
        transition: 0.5s ease-in all;
        background: $main-gradient-left;
      }
    }
  }
}
