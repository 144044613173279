@import '../../../../styles/colors';

.landing-mobile__nav {
  position: absolute;
  top: -15px;
  left: -32px;
  background: white;
  padding: 10px 20px;
  animation: 0.5s show ease;
  z-index: 100;
  width: 200px;
  background: $main-gradient;
  border-radius: 0 16px 16px 0;
}

@keyframes show {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

.landing-mobile__nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.landing-mobile__nav-item {
  padding: 15px 0;
}

.landing-mobile__nav-link {
  cursor: pointer;
  color: white;
}

.mobile-menu-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 1%;
  left: 1%;
  z-index: 1000;
}

.mobile-menu-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white !important;
  font-size: 22px;
}
