@import '../../../styles/colors';

// --------------------------------- "Price" card styles  --------------------------------- \\

.addmore-skips-card {
  position: relative;
  width: 18.5%;
  margin: 0;
  border-radius: 20px;
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  @media screen and (max-width: 1300px) {
    border-radius: 10px;
  }

  & > .addmore-skips-card__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
    padding: 51px 0;

    @media screen and (max-width: 1440px) {
      padding: 38px 0;
    }

    @media screen and (max-width: 1300px) {
      padding: 35px 0;
    }

    border-radius: 10px;
    background: #333;
    mask: url('../../../assets/white-label/card-header-mask.svg');
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;

    &::after {
      display: none;
    }
  }
}

.special__header {
  background: $main-gradient !important;
}

.addmore-skips-card-option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;

  background-color: #000;
  border-radius: 16px 16px 0 0;
  @media screen and (max-width: 1300px) {
    border-radius: 10px 10px 0 0;
  }

  & > svg {
    height: 18px;
    width: auto;
  }

  &--popular {
    background: rgb(51, 51, 51);

    & > svg {
      fill: var(--main-color);
    }
  }

  &--best {
    background: rgb(140, 198, 63);
  }
}

.addmore-skips-card-option ~ .addmore-skips-card__header {
  border-radius: 0;
  mask-position: 100% 100%;
  mask: url('../../../assets/white-label/additional-card-header-mask.svg');
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
}

.addmore-skips-card__content-container {
  padding: 0 15px 15px;

  @media screen and (max-width: 1300px) {
    padding: 0 5px 5px;
  }
}

.addmore-skips-card__header-heading {
  font-size: 26px;
  color: #fff;
  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
}

.addmore-skips-card__price-value {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: -5px;
  padding-bottom: 25px;
  font-size: 68px !important;
  text-align: center;
  background: black;
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  user-select: none;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 600;

  @media screen and (max-width: 1600px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 1440px) {
    font-size: 35px !important;
  }

  @media screen and (max-width: 1300px) {
    font-size: 33px !important;
  }

  & > sup {
    font-size: 34px !important;
    text-transform: uppercase;

    background: black;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    @media screen and (max-width: 1440px) {
      font-size: 25px !important;
    }
    @media screen and (max-width: 1300px) {
      font-size: 22px !important;
    }
  }

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    width: 55px;
    height: 2px;

    background: #d5d5d5;
    transform: translate(-50%, 0);
  }
}

.addmore-skips-card__price-skip {
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
  padding-top: 10px;
  color: $black !important;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 1440px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1300px) {
    margin-bottom: 15px;
    padding-top: 10px;
  }
}

.addmore-skips-card__button-container {
  position: relative;

  margin: 0 auto;
  padding: 2px;

  border-radius: 12px;
  background-image: linear-gradient(white, white), $main-gradient;
  background-origin: border-box;
  background-clip: content-box, border-box;

  overflow: hidden;

  @media screen and (max-width: 1300px) {
    border-radius: 8px;
  }
}

.addmore-skips-card__count-container {
  display: flex;
  align-items: stretch;

  & > button {
    flex-grow: 1;
    height: 35px;
    padding: 8px;

    font-size: 16px;
    font-weight: bold;

    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }

  & > .addmore-skips-card__count-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60px;
    padding: 0 10px;

    border-radius: 0 10px 10px 0;
    background-color: #f1f1f1;
    @media screen and (max-width: 1300px) {
      width: 50px;
      padding: 0 5px;
      height: 25px;
    }

    & > p {
      padding: 8px 0;
      font-weight: bold;
      font-size: 16px;
      color: $black !important;
    }

    & > button {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 12px;
      height: 12px;
      border: none;
      outline: none;
      background: $main-gradient;
      border-radius: 50%;
      cursor: pointer;
      font-size: 12px;
      color: white;
      font-weight: bold;
      position: relative;

      &:first-child {
        &:before {
          content: '-';
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
        }
      }

      &:last-child {
        &:before {
          content: '+';
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
        }
      }
    }
  }
}

.addmore-skips-card__price-default {
  background: $main-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  & > sup {
    background: $main-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.active {
  border-radius: 9px 0 0 9px;
  background: $main-gradient !important;
  color: white;

  @media screen and (max-width: 1300px) {
    border-radius: 6px 0 0 6px;
  }
}

// --------------------------------- "Default" card styles  --------------------------------- \\

.default-price-card {
  position: relative;
  width: 220px;
  margin: 0;

  border-radius: 20px;
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;

  & > .price-card__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
    padding: 48px 0;
    border-radius: 10px;
    background: #333;
    mask: url('../../../assets/white-label/card-header-mask.svg');
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;

    &::after {
      display: none;
    }
  }
}

.default-price-card-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 42px;

  font-size: 14px;
  text-transform: uppercase;
  color: #fff;

  background-color: #000;
  border-radius: 16px 16px 0 0;

  & > svg {
    height: 18px;
    width: auto;
  }

  &--popular {
    background: rgb(51, 51, 51);

    & > svg {
      fill: var(--main-color);
    }
  }

  &--best {
    background: rgb(140, 198, 63);
  }
}

.default-price-card-option ~ .price-card__header {
  border-radius: 0;
  mask-position: 100% 100%;

  mask: url('../../../assets/white-label/additional-card-header-mask.svg');
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
}

.price-card__content-container {
  padding: 0 15px;
  padding-bottom: 15px;
}

.price-card__header-heading {
  font-size: 26px;
  color: #fff;
}

.price-card__price-value {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 25px;
  font-size: 68px;
  text-align: center;

  background: black;
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  user-select: none;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 600;

  & > sup {
    font-size: 34px;
    text-transform: uppercase;

    background: black;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    width: 55px;
    height: 2px;

    background: #d5d5d5;
    transform: translate(-50%, 0);
  }
}

.price-card__price-skip {
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;

  text-transform: uppercase;
  text-align: center;
}

.price-card__button-container {
  position: relative;

  margin: 0 auto;
  padding: 2px;

  border-radius: 12px;
  background-image: linear-gradient(white, white), $main-gradient;
  background-origin: border-box;
  background-clip: content-box, border-box;

  overflow: hidden;
}

.price-card__count-container {
  display: flex;
  align-items: stretch;

  & > button {
    flex-grow: 1;
    height: 35px;
    padding: 8px;

    font-size: 16px;
    font-weight: bold;

    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;

    @media screen and (max-width: 1460px) {
      height: 32px;
      padding: 8px;
      font-size: 12px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 10px;
    }
  }

  & > .price-card__count-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65px;
    padding: 0 10px;

    border-radius: 0 10px 10px 0;
    background-color: #f1f1f1;

    & > p {
      padding: 8px 0;

      font-weight: bold;
      font-size: 16px;
    }

    & > button {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 14px;
      height: 14px;

      border: none;
      outline: none;
      background: $main-gradient;
      border-radius: 50%;
      cursor: pointer;
      font-size: 12px;
      color: white;
      font-weight: bold;
      line-height: 1;
      position: relative;

      @media screen and (max-width: 1500px) {
        font-size: 11px;
      }

      @media screen and (max-width: 1400px) {
        font-size: 10px;
      }

      &:first-child {
        &:before {
          content: '-';
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
        }
      }

      &:last-child {
        &:before {
          content: '+';
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
        }
      }
    }
  }
}

// --------------------------------- "Additional" card styles  --------------------------------- \\

.additional-price-card {
  display: flex;
  flex-direction: column;
  width: 260px;

  border-radius: 26px;
  border: 15px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 1px var(--main-color);
}

.additional-price-card__header {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 35px;
  padding-top: 80px;
  border-radius: 10px 10px 0 0;
  background: $main-gradient;

  & > p {
    font-size: 10px;
    color: #fff;
  }
}

.additional-price-card__header-decoration {
  position: absolute;
  top: -10px;
  left: 50%;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 45px;
  height: 80px;
  padding-bottom: 15px;

  border-radius: 0 0 25px 25px;
  background-color: #fff;
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);

  transform: translateX(-50%);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: -10px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent rgb(51, 51, 51) transparent;
  }
}

.additional-price-card__header-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;

  font-size: 24px;
  color: #fff;
}

.additional-price-card__content-container {
  flex-grow: 1;

  background: $main-gradient;
  border-radius: 0 0 10px 10px;
}

.additional-price-card__skips-contanier {
  padding: 50px 0;

  background-color: #fff;
  mask: url('../../../assets/white-label/additional-card-header-mask.svg');
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
}

.additional-price-card__price-value {
  display: inline-block;
  width: 100%;
  margin-top: -10px;

  font-size: 24px;
  text-align: center;
  text-transform: uppercase;

  & > sub {
    font-size: 24px;
  }
}

.additional-price-card__blur-container {
  position: relative;

  padding: 50px 0;
  padding-bottom: 25px;
}

.additional-price-card__blur-content {
  pointer-events: none;
  user-select: none;
  filter: blur(5px);

  & > .price-card__count-container {
    padding: 0 10px;

    & > button {
      color: #fff;

      border-radius: 20px 0 0 20px;
      background-color: #000;
    }
  }
}

.additional-price-card__blur-header {
  position: relative;

  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
  padding-bottom: 25px;

  font-size: 58px;
  text-align: center;
  color: #fff;
  user-select: none;

  & > sup {
    font-size: 34px;
    text-transform: uppercase;
    color: #fff;
  }

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    width: 55px;
    height: 2px;

    background: #ffffff;
    transform: translate(-50%, 0);
  }
}

.price-card__price-skip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  margin: 0 auto 25px;

  font-size: 14px;
  color: black;

  border: 2px solid #fff;
  border-radius: 25px;
}

.additional-price-card__clear-content {
  position: absolute;
  top: 25px;
  left: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 20px 25px;

  border-radius: 10px;
  background: rgba(0, 0, 0, 0.6);

  transform: translateX(-50%);

  & > svg {
    width: 16px;
    height: auto;
    margin-bottom: 10px;
  }

  & > h4 {
    margin: 0 0 10px 0;

    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
  }

  & > p {
    font-size: 10px;
    text-align: center;
    color: #fff;
  }
}

.additional-price-card__call-button {
  width: 120px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;

  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;

  border: 2px solid rgba(218, 216, 216, 0.3);
  border-radius: 20px;
  background: $main-gradient;
  background-size: 200%;
  background-position: 10%;
  cursor: pointer;

  transition: 0.25s ease-in-out all;

  &:hover {
    cursor: pointer;
    background-position: 30%;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);

    & > svg {
      width: 10px;
      height: auto;
    }
  }
}

.price-card__price-default {
  background: $main-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  & > sup {
    background: $main-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.addmore-skips-card-title {
  @media screen and (max-width: 1300px) {
    font-size: 10px !important;
  }
}
