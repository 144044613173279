@import '../../../styles/colors';

.layout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-size: cover !important;
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;

  max-height: 1080px;
  height: 100vh;

  @media screen and (max-width: 1280px) {
    margin-bottom: -6px;
  }

  @media screen and (max-width: 1200px) {
    min-height: 689px;
  }

  &__hidden {
    display: none;
  }

  @media screen and (max-width: 1279px) {
    min-width: 1400px;
  }

  &__figure {
    width: 64.2vw;
    min-width: 862px;
    height: 78px;
    position: absolute;
    left: 15%;
    top: 0;
    max-width: 1232px;
    max-height: 78px;

    @media screen and (min-width: 1920px) {
      max-width: 1000px;
    }

    svg {
      @media screen and (max-width: 1920px) {
        width: 67vw;
        height: 70px;
      }

      @media screen and (max-width: 1750px) {
        width: 60vw;
      }

      @media screen and(max-width: 1600px) {
        width: 56vw;
        height: 60px;
      }

      @media screen and(max-width: 1400px) {
        width: 60vw;
        height: 55px;
      }

      @media screen and(max-width: 1300px) {
        height: 50px;
      }

      @media screen and(max-width: 1279px) {
        width: 800px;
      }
    }
  }

  .main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    @media screen and (max-width: 1300px) {
      width: 1050px !important;
    }
  }

  .content {
    width: 100%;
    height: auto;
  }
}
