@import '../../../../styles/colors';

.search-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  height: calc(100% - 60px);
  padding: 0 20px;
  margin-top: 40px;

  @media screen and (max-width: 1500px) {
    padding: 0 15px;
  }

  h1 {
    text-decoration: underline;
    font-weight: bold;
    margin-top: 30px;
    color: var(--main-color);
  }

  h2 {
    color: var(--text-color);
    @media screen and (max-width: 1450px) {
      font-size: 16px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 2px solid var(--main-color);
    padding: 20px 0;
    height: 100%;

    @media screen and (max-width: 1400px) {
      padding: 10px 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;

    &:first-child {
      border-bottom: 2px solid var(--main-color);
      padding-bottom: 30px;
      height: 60%;

      @media screen and (max-width: 1400px) {
        padding: 0;
      }
    }

    &:last-child {
      padding-top: 20px;
      height: 40%;

      @media screen and (max-width: 1400px) {
        padding: 0;
      }
    }
  }

  &__form-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 20px;

    h2 {
      color: var(--text-color);

      width: 200px;
      margin-right: 30px;
      font-size: 25px;
      text-decoration: underline;

      @media screen and (max-width: 1500px) {
        font-size: 20px;
      }
    }
  }

  &__form-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px;

    @media screen and (max-width: 1400px) {
      gap: 5px;
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    align-items: initial;
    width: 30%;
  }

  button {
    background: $main-gradient;
    padding: 10px;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    width: 80px;

    @media screen and (max-width: 1400px) {
      padding: 8px;
      width: 70px;
    }

    &:hover {
      background: $main-gradient-left;
      transition: 0.4s ease-in all;
    }
  }
}
