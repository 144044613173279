.color-picker {
  position: relative;
  height: 40px;
  border-radius: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
  border: 1px solid var(--text-color);
  flex: 1;

  &__inner {
    width: 30%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    @media screen and(max-width: 1600px) {
      width: 45%;
    }

    @media screen and(max-width: 1300px) {
      width: 100%;
    }
  }

  &-item {
    position: relative;
    bottom: -20px;
    z-index: 1000;
  }

  h2 {
    position: absolute;
    top: -30px;
    color: var(--text-color);
  }

  &-item {
    width: 250px;
  }

  &-btn {
    color: var(--main-color);
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    border-radius: 4px 16px 16px 4px;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
  }
}
