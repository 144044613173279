@import '../../../../styles/colors.scss';

.updatecard_button {
  padding: 10px 15px;
  background: $main-gradient;
  color: $white;
  border: none;
  border-radius: 7px;

  &:hover {
    cursor: pointer;
  }
}

.edit__cards__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  position: relative;
}

.action-cards__section {
  position: absolute;
  top: 0%;
  right: 0%;

  .actions__btn {
    z-index: 100;
    background: transparent;
    font-size: 16px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);

    &:first-child {
      margin-right: 10px;
    }
  }
}

.pricing__cards__container {
  width: 65%;
}

.pricing__codes__container {
  width: 30%;
  margin-left: 45px;
  height: 380px;
  padding: 5px 10px;
  overflow-y: scroll;

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 360px;
  }
}

.edit__input {
  appearance: textfield;
  width: 80px;
  padding: 0 20px;
  font-size: 20px;
  border: 1px solid $main-gradient;
  color: black;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.edit_pricing__container {
  width: 100%;
}

.displayed__cards__container {
  display: flex;
  flex-wrap: wrap;
  z-index: 100;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  margin-top: 120px;

  @media screen and (max-width: 1300px) {
    justify-content: space-around;
  }
}

.prices__cards__table {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.prices__cards__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 1fr 2fr;
  width: 100%;

  & > span {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 10px;
    color: var(--text-color);

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      justify-content: center;

      & > span {
        padding: 5px;
      }
    }
  }

  & > h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #999999;
    font-size: 12px;

    &:last-child {
      text-align: center;
    }
  }

  &:first-child {
    position: sticky;
  }

  &:nth-child(2n) {
    background-color: var(--tr-bg) !important;
  }
}

.preview__cards {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}

.promo-type__container {
  display: flex;
  margin-bottom: 25px;

  .btn {
    font-size: 14px;
    margin-right: 5px;
    background: #e6e6e6;
    color: #fff;
    padding: 9px 25px;

    @media screen and(max-width: 1500px) {
      padding: 9px 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.active-type {
  background: var(--main-color) !important;
}

.ant-modal.create-discount__modal {
  min-width: 850px;
}

.ant-modal.show-cards__modal {
  min-width: 1260px;
  position: relative;

  .close__btn {
    font-size: 45px;
    opacity: 1;
    top: -3%;
    right: 27px;
    position: absolute;
    color: #000;
    transition: color 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}

.modal__cards {
  margin: 50px 0;

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    margin: 40px 0;
  }
}
