$spaceAmounts: (0, 5, 10, 15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 90, 100, 120);
$sides: (top, bottom, left, right);

@each $space in $spaceAmounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }

  .gap-#{$space} {
    gap: #{$space}px !important;
  }
}

.m-t-auto {
  margin-top: auto !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-a-auto {
  margin: auto !important;
}

.g-10 {
  gap: 10px;
}