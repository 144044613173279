@import '../../../styles/colors';

.results-library {
  .ant-tooltip-open {
    font-size: 14px !important;
    @media screen and(max-width: 1520px) {
      font-size: 12px !important;
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:first-child {
      .tooltip__first {
        top: 4%;
        right: 3%;
        transform: translate(-2%, -5%);
      }
    }

    .tooltip {
      top: -65%;
    }

    button {
      cursor: pointer;
      position: relative;
    }

    a:hover {
      color: var(--text-color);
    }
  }

  .tr {
    height: 50px;
    flex: 1;
    padding: 5px 10px;
    background: $main-gradient;
    margin: 0 0 25px 0;
    border-radius: 16px;

    display: grid;
    grid-template-columns: 1fr 120px 120px 120px 100px 100px;
    transition: 0.6s ease-in all;

    @media screen and(max-width: 1520px) {
      grid-template-columns: 1fr 100px 100px 120px 100px 100px;
      padding: 0 5px;
      height: 40px;
      margin-bottom: 15px;
      border-radius: 8px;
    }
  }

  .td {
    font-size: 14px;
    color: white;
    outline: none;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
    @media screen and(max-width: 1520px) {
      font-size: 12px;
    }

    &:nth-child(1) {
      display: flex;
      align-items: center;
    }

    &:nth-child(4) {
      border: 1px solid white;
      border-radius: 50px;
      font-size: 12px;
      text-align: center;
      padding: 5px 0;
      text-transform: uppercase;
      justify-content: center;
      width: 100px;
      height: 25px;
      margin: auto;

      @media screen and(max-width: 1520px) {
        font-size: 10px;
        width: 80px;
      }
    }

    &:nth-child(5) {
      background: var(--action-color);
      border-radius: 8px;
      text-align: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      margin: auto;
      @media screen and(max-width: 1520px) {
        height: 32px;
        width: 64px;
      }
    }

    &:nth-child(6) {
      background: var(--action-color);
      border-radius: 8px;
      text-align: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      margin: auto;
      @media screen and(max-width: 1520px) {
        height: 32px;
        width: 64px;
      }
    }

    .plus {
      background: transparent;
      border: 2px solid white;
      font-size: 28px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      vertical-align: center;
      margin: 0 20px 0 0;

      @media screen and(max-width: 1520px) {
        font-size: 24px;
      }

      span {
        margin-top: -5px;
      }
    }
  }

  .skeleton {
    &-tr {
      background: #f5f5f5;
      height: 50px;

      @media screen and(max-width: 1520px) {
        height: 40px;
      }
    }

    .checkbox label:before {
      border: 1px solid rgba(217, 217, 217, 0.5);
    }

    .checkbox input:checked + label:after {
      border: solid rgba(217, 217, 217, 0.5);
      border-width: 0 2px 2px 0;
    }

    .checkbox input:checked + label:before {
      background: transparent;
      border: 1px solid rgba(217, 217, 217, 0.5);
    }
  }

  .checkbox {
    width: 70px;
    margin-bottom: 25px;

    @media screen and (max-width: 1500px) {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 1300px) {
      width: 50px;
    }
  }

  .checkbox label:before {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #999999;
  }

  .checkbox input:checked + label:after {
    top: 6px;
    left: 14px;
    width: 7px;
    height: 17px;
    border: solid #999999;
    border-width: 0 2px 2px 0;
  }

  .checkbox input:checked + label:before {
    background: transparent;
    border: 1px solid #999999;
  }

  .multiply {
    width: 140px;
    display: flex;
    align-items: center;
    font-size: 18px;
    border-radius: 26px;
    text-transform: uppercase;
    color: white;
    background: rgba(53, 70, 91, 0.3);
    padding: 12px 15px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    position: relative;

    @media screen and(max-width: 1520px) {
      padding: 10px 15px;
    }

    &:before {
      background: var(--action-color);
      position: absolute;
      top: 3px;
      left: 4px;
      right: 4px;
      bottom: 3px;
      content: '';
      border-radius: 25px;
      opacity: 1;
      z-index: 100;
    }

    img {
      width: 12px;
      height: 12px;
      opacity: 1;
      z-index: 200;
      margin-right: 2px;
    }

    span {
      opacity: 1;
      z-index: 200;
      color: white;
      font-size: 12px;
      @media screen and(max-width: 1520px) {
        font-size: 11px;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  .results {
    transition: all 0.2s;

    &-row {
      position: relative !important;
    }

    &-tr {
      @media screen and(max-width: 1520px) {
        padding: 0 5px !important;
        height: 40px !important;
        margin-bottom: 15px !important;
        border-radius: 8px !important;
      }
    }

    .tooltip {
      top: -90%;
      right: 0;
    }

    .checkbox {
      width: 70px;
      margin-bottom: 25px;

      @media screen and (max-width: 1500px) {
        margin-bottom: 15px;
      }
      @media screen and (max-width: 1300px) {
        width: 50px;
      }
    }
  }
}

.action__icon {
  width: 30px;
  height: 22px;

  @media screen and(max-width: 1520px) {
    width: 25px;
    height: 20px;
  }
}
