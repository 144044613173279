.no-result {
  font-size: 40px;
  letter-spacing: 2px;
  color: var(--main-color);
  position: absolute !important;
  font-family: Bebas Neue, sans-serif !important;
  text-transform: uppercase;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  @media screen and (max-width: 1800px) {
    font-size: 35px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 32px;
  }
  @media screen and (max-width: 1450px) {
    font-size: 29px;
  }
  @media screen and (max-width: 1279px) {
    font-size: 35px;
  }
}
