@import '../../styles/colors';

.credits-page {
  padding: 65px 20px 0 20px;
  position: relative;

  @media screen and(max-width: 1580px) {
    padding: 65px 5px 0 0;
  }

  @media screen and(max-width: 1366px) {
    // min-height: 100vh;
  }
  @media screen and (max-height: 750px) and (max-width: 1400px) {
    padding: 66px 5px 0 5px;
  }

  &__body {
    min-height: 80vh;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    @media screen and (max-width: 1280px) {
      min-height: initial;
    }
  }

  &__sub-title {
    margin-left: 60px;

    @media screen and (max-height: 800px) and (max-width: 1400px) {
      margin-left: 40px;
    }
    @media screen and (max-height: 750px) and (max-width: 1400px) {
      margin-left: 40px;
    }
  }

  &__body-item {
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      flex: 1;
      background: $main-gradient;
      border-radius: 32px 0 0 32px;
      padding: 20px;
      position: relative;
      @media screen and(max-width: 1600px) {
        padding: 20px 10px;
      }
      @media screen and (max-height: 800px) and (max-width: 1400px) {
        margin-left: 30px;
      }
      @media screen and (max-height: 750px) and (max-width: 1400px) {
        margin-left: 15px;
      }

      &:before {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../../assets/credits/credits-bg.svg') 0 0 no-repeat;
        background-size: cover;
        height: 80%;
      }

      h1 {
        margin-left: 60px;
        margin-top: 20px;
        color: white;
        font-size: 42px;
        display: flex;
        align-items: baseline;
        text-transform: uppercase;
        font-family: 'Bebas Neue', sans-serif;
        font-weight: 400;
        @media screen and (max-height: 800px) and (max-width: 1400px) {
          font-size: 35px;
          margin-top: -5px;
          margin-left: 40px;
        }
        @media screen and (max-height: 750px) and (max-width: 1400px) {
          font-size: 35px;
          margin-top: -5px;
          margin-left: 40px;
        }

        img {
          width: 50px;
          height: 50px;
          margin-left: 20px;
          @media screen and (max-height: 750px) and (max-width: 1400px) {
            width: 45px;
            height: 45px;
          }
        }
      }

      p {
        color: white;
        font-size: 18px;

        @media screen and(max-width: 1800px) {
          font-size: 16px;
        }

        @media screen and(max-width: 1600px) {
          font-size: 14px;
        }

        @media screen and(max-width: 1300px) {
          font-size: 12px;
        }
      }

      .substrate {
        border-radius: 16px;
        text-align: center;
        width: 39%;
        height: 515px;
        position: absolute;
        background: $action-gradient;
        top: 10%;
        right: 15px;

        @media screen and(max-width: 1800px) {
          height: 500px;
        }

        @media screen and(max-width: 1600px) {
          right: 8px;
        }
        @media screen and(max-width: 1580px) {
          right: 10px;
          height: 432px;
          top: 10%;
        }

        @media screen and (max-height: 800px) and (max-width: 1400px) {
          top: 11%;
          height: 390px;
        }
        @media screen and (max-height: 750px) and (max-width: 1400px) {
          top: 11%;
          height: 355px;
        }

        @media screen and (max-width: 1300px) {
          top: 8%;
        }

        p {
          width: 80%;
          margin: 40px auto 0 auto;
          padding: 5px;
          border: 1px solid white;
          border-radius: 16px;
          font-size: 16px !important;

          @media screen and(max-width: 1600px) {
            font-size: 14px !important;
          }
          @media screen and(max-width: 1500px) {
            font-size: 12px !important;
          }

          @media screen and(max-width: 1400px) {
            font-size: 10px !important;
            margin-top: 20px;
          }
        }
      }
    }

    &:nth-child(2) {
      z-index: 100;
      margin-left: 10px;
      min-width: 260px;
      max-width: 260px;
      border-radius: 0 32px 32px 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
    }
  }

  &__cards {
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    margin: 0 auto 30px;
    margin-top: 55px;

    @media screen and (max-height: 800px) and (max-width: 1400px) {
      margin: 0 auto 5px;
      margin-top: 55px;
    }
    @media screen and (max-width: 1400px) and (max-height: 750px) {
      margin: 0 auto 5px;
      margin-top: 30px;
    }

    @media screen and (max-height: 750px) and (max-width: 1400px) {
      margin: 0 auto 5px;
      margin-top: 20px;
    }
  }

  &__description {
    position: relative;
    margin: auto;
    padding: 20px;
    border-radius: 12px;
    z-index: 100;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 1500px) {
      padding: 12px;
    }

    @media screen and (max-height: 800px) and (max-width: 1400px) {
      margin: 50px auto 0px auto;
    }

    @media screen and (max-height: 750px) and (max-width: 1400px) {
      margin: 26px auto 24px auto;
    }

    &::before {
      content: '';
      background: $action-gradient;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: 12px;
    }
  }

  .enterprise_card {
    position: relative;
    margin: 50px auto auto auto;
    background: $main-gradient;
    width: 80%;
    height: 70%;
    border-radius: 14px;
    padding: 80px 0 0;
    text-align: center;
    @media screen and (max-width: 1700px) {
      margin-top: 25px;
      height: 75%;
    }

    @media screen and (max-height: 750px) and (max-width: 1400px) {
      height: 78%;
      margin-top: 15px;
      padding: 70px 0 0;
    }

    .icon {
      width: 80px;
      height: 160px;
      position: absolute;
      top: -75px;
      left: 50%;
      transform: translate(-50%, 14%);
      @media screen and (max-height: 750px) and (max-width: 1400px) {
        width: 65px;
        height: 110px;
        position: absolute;
        top: -47px;
        left: 50%;
        transform: translate(-50%, 14%);
      }
    }

    &-title {
      font-size: 22px;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      margin-bottom: 20px;

      @media screen and (max-height: 750px) and (max-width: 1400px) {
        font-size: 16px;
      }

      span {
        font-size: 10px;
        text-transform: initial;
        font-weight: initial;
        color: white;
      }
    }

    &-skips {
      background: white;
      width: 100%;
      padding: 25px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 23px;
      text-transform: uppercase;
      position: relative;
      gap: 5px;

      @media screen and(max-width: 1366px) {
        font-size: 18px;
        padding: 20px 10px;
      }
      @media screen and (max-height: 750px) and (max-width: 1400px) {
        font-size: 15px;
        padding: 10px 10px;
      }

      &:after {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: -39px;
        border: 20px solid transparent;
        border-top: 20px solid white;
        transform: translate(-50%, 0);
        @media screen and (max-height: 750px) and (max-width: 1400px) {
          left: 50%;
          bottom: -29px;
          border: 15px solid transparent;
          border-top: 15px solid white;
          transform: translate(-50%, 0);
        }
      }

      h3 {
        font-weight: bold;
        font-size: 24px;

        @media screen and (max-width: 1400px) {
          font-size: 20px;
        }
      }

      p {
        font-weight: initial;
      }
    }
  }

  .enterprise__body {
    width: 100%;
    height: 100%;
    background: url('../../assets/add-more-credits/bg-body.svg') center top no-repeat;
    position: relative;
    @media screen and (max-width: 1800px) {
      background-size: 90%;
    }

    @media screen and (max-width: 1500px) {
      background: url('../../assets/add-more-credits/bg-body.svg') center top no-repeat;
      background-size: 75%;
    }

    @media screen and (max-height: 750px) and (max-width: 1400px) {
      background-size: 73%;
    }

    &-inner {
      position: absolute;
      top: 40px;
      left: 50%;
      width: 80%;
      transform: translate(-50%, 0);
      background: rgba(0, 0, 0, 0.5);
      height: 160px;
      border-radius: 16px;
      padding: 20px 10px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @media screen and (max-height: 750px) and (max-width: 1400px) {
        top: 20px;
        padding: 7px 10px 10px;
      }

      img {
        width: 20px;
        height: 20px;
      }

      p {
        color: white;
        font-size: 14px;
        font-weight: initial;
        line-height: 1.3;
      }

      button {
        background: $main-gradient;
        color: white;
        padding: 5px 0;
        border-radius: 20px;
        width: 80%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        gap: 10px;

        img {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #1a5778;
          padding: 3px;
        }
      }

      span {
        color: white;
        font-size: 10px;
      }
    }
  }

  .enterprise__footer {
    margin-top: auto;
    width: 100%;
    position: relative;
    height: 100px;
    background: #00000063;
    border-radius: 99px 0 32px 0;

    @media screen and (max-width: 1400px) {
      height: 75px;
    }

    @media screen and (max-height: 750px) and (max-width: 1400px) {
      height: 65px;
    }

    img {
      position: absolute;
      top: -35%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 120px;
      height: 120px;

      @media screen and(max-width: 1500px) {
        width: 110px;
        height: 110px;
      }

      @media screen and(max-width: 1300px) {
        width: 100px;
        height: 100px;
        top: -25%;
      }
      @media screen and (max-height: 750px) and (max-width: 1400px) {
        width: 80px;
        height: 80px;
      }
    }
  }

  .price-card {
    @media screen and(max-width: 1800px) {
      min-width: 160px;
      margin-bottom: 0;
    }

    @media screen and(max-width: 1500px) {
      min-width: 130px;
      box-shadow: none;
      height: 208px;
    }

    @media screen and(max-width: 1300px) {
      min-width: 120px;
    }

    &__header-profit {
      border-radius: 0;
    }

    &-option {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translate(-50%, 0);
      background: black;

      p {
        @media screen and(max-width: 1600px) {
          font-size: 12px !important;
        }

        @media screen and(max-width: 1400px) {
          font-size: 10px !important;
        }
      }
    }

    &__header {
      // border-radius: 10px;
      // background: $main-gradient;
      // mask: url('../../assets/white-label/card-header-mask.svg');
      // mask-size: cover;
      // mask-position: center;
      // mask-repeat: no-repeat;
      @media screen and(max-width: 1800px) {
        padding: 20px 0;
      }

      @media screen and(max-width: 1500px) {
        padding: 15px 0;
      }

      p {
        font-size: 26px;

        @media screen and(max-width: 1800px) {
          font-size: 20px;
        }

        @media screen and(max-width: 1500px) {
          font-size: 14px;
        }
      }
    }

    &__body {
      padding-top: 30px;
      @media screen and(max-width: 1500px) {
        padding-top: 30px;
      }

      p {
        font-size: 65px !important;
        background: $main-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        font-family: 'Bebas Neue', sans-serif;

        @media screen and(max-width: 1800px) {
          font-size: 55px !important;
        }

        @media screen and(max-width: 1500px) {
          font-size: 40px !important;
        }

        span {
          @media screen and(max-width: 1500px) {
            font-size: 20px !important;
          }
        }
      }

      .price-option {
        background: black;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        font-family: 'Bebas Neue', sans-serif;
      }
    }

    &__footer {
      @media screen and(max-width: 1800px) {
        padding: 10px 0;
      }

      p {
        color: black;

        @media screen and(max-width: 1800px) {
          font-size: 14px;
        }

        @media screen and(max-width: 1500px) {
          font-size: 10px;
        }
      }
    }
  }

  .m-b-30 {
    @media screen and(max-width: 1800px) {
      margin-bottom: 20px !important;
    }

    @media screen and(max-width: 1500px) {
      margin-bottom: 10px !important;
    }
  }

  .discount-price {
    background: -webkit-linear-gradient(360deg, red 0, purple 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    color: initial !important;
  }

  .price-card__count-inner-btn {
    font-size: 14px;
    @media screen and(max-width: 1800px) {
      font-size: 12px;
      height: 40px;
    }

    @media screen and(max-width: 1500px) {
      font-size: 8px;
      height: 30px;
      padding: 0;
    }

    @media screen and(max-width: 1300px) {
      height: 25px;
    }
  }

  .price-card__count-inner-buttons {
    padding: 7px 0;

    @media screen and(max-width: 1800px) {
      padding: 5px 0;
    }

    @media screen and(max-width: 1500px) {
      padding: 0;
      height: 30px;
    }

    @media screen and(max-width: 1300px) {
      height: 29px;
    }
  }

  .landing__price-section-footer-total {
    padding: 21px 0;
  }

  .landing__price-section-footer-total p {
    color: black !important;
  }

  .landing__price-section-footer {
    color: white !important;
  }

  .landing__price-section-footer-checkout:before {
    left: -15px !important;
  }

  .price-card__header-profit:after {
    @media screen and (max-width: 1500px) {
      bottom: -29px;
      border: 15px solid transparent;
      border-top: 15px solid var(--gradient-2) !important;
    }
  }

  .price-card__header:after {
    @media screen and (max-width: 1500px) {
      bottom: -29px;
      border: 15px solid transparent;
      border-top: 15px solid #333333;
    }
  }

  .price-content {
    width: 18.5%;
  }
}

.credits-page-code {
  position: absolute;
  top: 130px;
  left: 30px;
  width: 40%;
  height: 40px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  transition: all 0.4s cubic-bezier(0.21, 1.04, 1, 0.06);
  max-width: 400px;

  &__field {
    background-clip: content-box, border-box;
    background-image: linear-gradient(var(--content-bg), var(--content-bg)), $main-gradient;
    background-origin: border-box;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 1px;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
  }

  input {
    margin: auto;
    height: 40px;
    outline: none;
    z-index: 0;
    width: 100%;
    padding-left: 15px;
    font-size: 14px;
    border: 1px solid transparent;
    background: transparent;

    &::placeholder {
      font-style: italic;
      color: #a4a4a4;
    }
  }

  button {
    background: white;
    color: var(--text-color);
    outline: none;
    border: none;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.21, 1.04, 1, 0.06);

    &:hover {
      background: $main-gradient-left;
      transition: all 0.2s ease-in;
      color: white;
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 8px;
    background: white;
    color: #ff1e1e !important;
    font-size: 13px;
  }
}

.lower-index {
  z-index: 9 !important;

  @media screen and (max-width: 1300px) {
    bottom: -15px !important;
  }
}

.add-more-checkout-btn {
  @media screen and (max-width: 1366px) and (max-height: 650px) {
    padding: 13px 0 !important;
  }
}
