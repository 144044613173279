@import '../../styles/colors.scss';

.landing__brand-article {
  position: relative;
  width: 100%;
  margin-bottom: -1rem;

  background-color: #fff;
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.6);
  z-index: 1;
}

.landing__brand-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  height: 100px;
  width: 70%;
  margin: 0 auto;

  @media screen and(max-width: 800px) {
    width: 90%;
    height: 50px;
  }

  img {
    width: 90%;

    @media screen and(max-width: 800px) {
      width: 100%;
      object-fit: cover;
      height: 20px;
    }
  }
}

.landing__footer {
  background: black;
  min-height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  .logo__img {
    width: 16%;
    height: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    z-index: 999;

    @media screen and(max-width: 1500px) {
      width: 20%;
    }

    @media screen and(max-width: 800px) {
      width: 30%;
    }

    @media screen and(max-width: 500px) {
      width: 50%;
    }
  }

  @media screen and(max-width: 800px) {
    min-height: 200px;
  }
}

.landing__mobile__footer {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background: black;

  .logo__img {
    width: 12%;
    height: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    z-index: 999;

    @media screen and(max-width: 1500px) {
      width: 20%;
    }

    @media screen and(max-width: 800px) {
      width: 30%;
    }

    @media screen and(max-width: 500px) {
      width: 50%;
    }
  }
}

.test1 {
  width: 100%;
  position: absolute;
  height: 200px;
  background-color: #000;
  border-top-left-radius: 125px;
  z-index: 999;
  margin-top: auto;
}

.test2 {
  width: 200px;
  height: 200px;
  position: absolute;
  background: $main-gradient;
  border-top-left-radius: 150px;
  border-bottom-right-radius: 110px;
  top: -63px;
  left: -110px;
  transform: rotate(37deg);
}
