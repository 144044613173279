@import '../../../styles/colors';

.navigation-section {
  margin-bottom: 60px;

  svg {
    .cls-1 {
      filter: initial !important;
      font-weight: 100;
    }
  }

  &::before {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    display: block;
    width: 100%;
    aspect-ratio: 1920/ 732;
    background: url('../../../assets/white-label/header-decoration-layer.svg') no-repeat;
    background-size: cover;
    z-index: 0;

    @media screen and (max-width: 1280px) {
      min-width: 1300px;
      aspect-ratio: 1920/ 1000;
    }
  }

  & + section {
    position: relative;
    z-index: 2;
  }
}

.navigation-section__welcome-decoraion {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  display: block;
  aspect-ratio: 1920/ 732;

  background-size: 150%;
  background: url('../../../assets/white-label/header-background.png') no-repeat center;
  mask: url('../../../assets/white-label/header-decoration-layer.svg');
  mask-size: cover;
  mask-repeat: no-repeat;

  @media screen and (max-width: 1280px) {
    min-width: 1300px;
    aspect-ratio: 1920/ 1000;
  }
}

.navigation-section__header {
  position: relative;

  display: grid;
  grid-template-columns: clamp(150px, 20%, 285px) 1fr clamp(350px, 38vw, 660px) 1fr 285px;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  @media screen and (max-width: 1700px) {
    grid-template-columns: clamp(150px, 20%, 285px) 1fr clamp(350px, 45vw, 660px) 1fr 235px;
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: clamp(150px, 15%, 285px) 1fr clamp(350px, 45%, 660px) 1fr 235px;
  }
}

.navigation-section__header-logo-container {
  & > svg {
    width: 100%;
  }
}

.navigation-section__header-decoration {
  display: block;
  max-width: 100%;
  height: 2px;
  margin-top: 30px;

  background: rgba(255, 255, 255, 0.5);
}

.navigation-section__nav {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.navigation-section__nav-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  height: fit-content;
  margin: 20px 0 0;
  padding: 0;
  background: inherit;
  list-style: none;

  @media screen and (max-width: 1700px) {
    gap: 1rem;
  }
}

.navigation-section__nav-link {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #fff;
  @media screen and (max-width: 1700px) {
    font-size: 16px;
  }

  &:hover,
  &:focus-visible {
    color: var(--main-color);
    text-shadow: 1px 1px 1px var(--main-color);
  }

  &--active {
    color: var(--main-color);
    text-shadow: 1px 1px 1px var(--main-color);

    pointer-events: none;
  }
}

.navigation-section__login-container {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: fit-content;
  margin-top: 15px;

  & > span {
    color: #fff;
  }
}

.navigation-section__login-link {
  margin: 0 3px;

  color: var(--bold-color);
  border-bottom: 1px solid var(--bold-color);

  &:hover {
    color: var(--main-color-hover);
  }
}

.navigation-section__login-link-button {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 15px;
  width: 100%;

  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;

  border-radius: 35px;
  background: $main-gradient;

  transition: 0.25s ease all;

  @media screen and (max-width: 1700px) {
    font-size: 14px;
  }

  &:hover,
  &:focus-visible {
    opacity: 0.9;
    color: white;
    background: $main-gradient-left;
  }

  span {
    &:first-child {
      flex-grow: 1;
      text-align: center;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin-left: 15px;

      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);

      @media screen and (max-width: 1700px) {
        margin-left: 0;
        width: 25px;
        height: 25px;
      }
    }
  }

  & > svg {
    width: 16px;
    height: auto;
  }
}

.navigation-section__head-container {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -80px;

  font-family: Bebas Neue, sans-serif;
  color: #fff;

  z-index: 1;

  & > svg {
    width: 120px;
    height: auto;
    margin-bottom: 30px;
  }

  & > h1 {
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;

    font-family: 'Bebas Neue', sans-serif;
    font-size: 52px;
    line-height: 48px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
  }

  & > p {
    font-size: 26px;

    @media screen and (max-width: 1500px) {
      font-size: 24px;
    }
  }
}

//@media (max-width: (1280px)) {
//  .navigation-section {
//    &::before {
//      aspect-ratio: 1920/ 882;
//    }
//  }
//}
