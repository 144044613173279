.white-label-label {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--text-color);
  position: relative;


  .white-label-field:disabled{
    background: #F5F5F5;
    cursor: not-allowed;
  }

  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;

    .ant-input-number-input{
      padding: 0 !important;
    }
  }


}
