.flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }

  &-row {
    flex-direction: row;
  }

  &-row-reverse {
    flex-direction: row-reverse;
  }

  &-column {
    flex-direction: column;
  }

  &-column-reverse {
    flex-direction: column-reverse;
  }

  &-justify-content-start {
    justify-content: flex-start;
  }

  &-justify-content-end {
    justify-content: flex-end;
  }

  &-justify-content-center {
    justify-content: center;
  }

  &-justify-content-between {
    justify-content: space-between;
  }

  &-justify-content-around {
    justify-content: space-around;
  }

  &-align-items-start {
    align-items: flex-start;
  }

  &-align-items-end {
    align-items: flex-end;
  }

  &-align-items-center {
    align-items: center;
  }

  &-align-items-stretch {
    align-items: stretch;
  }
}
