.verify-email {
  display: flex;
  flex-direction: column;
  margin: 20px 5px;

  h3 {
    font-size: 21px;
    line-height: 1.3;
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 19px;
    }
  }

  p{
    font-size: 19px;
    line-height: 1.3;
    font-weight: normal;
    text-align: center;

    span{
      color: var(--main-color);
    }
  }

  &__email{
    white-space: nowrap;
  }
}

.verify-title {
  font-size: 24px !important;
  text-transform: uppercase;
  color: black !important;
  line-height: 0;
  margin: 30px 0 20px 0 !important;
  border: none !important;

  @media screen and (max-width: 1400px) {
    font-size: 22px !important;
    margin: 20px 0 10px !important;
  }

  @media screen and (max-width: 800px) {
    font-size: 19px !important;
  }

  span {
    color: var(--main-color);
  }
}
