@import '../../../styles/colors';

.callendly-block {
  button {
    width: 100%;
    display: block;
    background: $main-gradient;
    padding: 15px;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
