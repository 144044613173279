@import '../../../styles/colors';

.callendly__modal-admin {
  width: 70% !important;

  @media screen and (max-width: 1500px) {
    width: 95% !important;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h3 {
      font-size: 30px;
    }

    button {
      color: white;
      background: $main-gradient;
      cursor: pointer;
      outline: none;
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 5px 10px;
    }
  }

  .ant-modal-content {
    border-radius: 22px !important;
    padding: 20px !important;
  }

  &-body {
    overflow-y: auto;
    height: 430px;
  }

  .input {
    width: 100%;
    outline: none;
    border-radius: 8px;
    padding: 10px;
    background: $white;
    border: 1px solid $white;
    height: 60px;
    color: black;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 14px;
    -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }

    &:focus {
      border: 1px solid var(--main-color);
      -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
  }

  .avatar__button {
    padding: 10px;
    margin: 10px 0;
  }
}

.callendly__user {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
  gap: 15px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }

  button {
    color: white;
    background: $main-gradient;
    cursor: pointer;
    outline: none;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 5px 10px;
  }

  &-actions {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0;
    gap: 5px;
  }

  .delete {
    background: $incorrectColor;
  }
}

.callendly__user-edit {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
  gap: 15px;

  p {
    display: flex;
    align-items: baseline !important;
    justify-content: center;
  }

  button {
    color: white;
    background: $main-gradient;
    cursor: pointer;
    outline: none;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 5px 10px;
  }
}

.create-callendly {
  display: flex;
  flex-direction: column;
  height: 515px;
  gap: 8px;
  overflow: auto;

  @media screen and (min-width: 1520px) {
    height: 585px;
  }

  @media screen and (max-width: 1500px) {
    height: 510px;
  }

  h3 {
    font-size: 30px;

    @media screen and (max-width: 1500px) {
      font-size: 24px;
    }
  }

  button {
    color: white;
    background: $main-gradient;
    cursor: pointer;
    outline: none;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 5px 10px;

    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }

    &:hover {
      background: $main-gradient-left;
    }
  }
}
