@import '../../styles/colors';

.forgot {
  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5%;

    h3 {
      margin: 20px 0 0 0;
      border: none;
    }

    p {
      line-height: 25px;
      color: $grey;
    }

    span {
      color: var(--text-color);
    }
  }

  &__error {
    color: rgb(255, 76, 76) !important;
    margin-top: 20px;
  }

  &__login {
    color: #b3b3b3;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__link {
    color: $link-color;
    text-decoration: underline;
    margin-left: 2px;

    &:hover {
      color: var(--main-color) !important;
    }
  }
}

.auth {
  @media screen and (max-width: 1200px) {
    background: none !important;
  }
  @media screen and (max-height: 700px) {
    overflow: scroll;
  }

  &__body {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 30px;
    max-height: 805px;
    z-index: 10;
    position: relative;
    height: 800px;

    @media screen and (max-width: 1600px) {
      height: 650px;
    }

    @media screen and (max-width: 1300px) {
      height: 599px;
      padding: 10px 30px;
    }
  }

  &__body-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    @media screen and (max-width: 1200px) {
      width: 410px;
    }
    .auth__title {
      @media screen and (max-width: 1300px) {
        color: black;
      }
    }
  }

  h3 {
    border-bottom: 1px solid $grey;
    padding-bottom: 30px;
  }
}

.forgot-link {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 5px;
  text-align: center;
}

.block__forgot__password {
  position: absolute;
  bottom: 10%;
  left: -10%;
  z-index: 100;

  @media screen and(min-width: 1850px) {
    bottom: 0;
    left: -12%;
  }

  @media screen and(max-width: 1850px) {
    bottom: -6%;
    left: -6%;
  }

  @media screen and(max-width: 1750px) {
    bottom: 5%;
    left: -6%;
  }

  @media screen and(max-width: 1600px) {
    bottom: 10%;
    left: -11%;
  }

  @media screen and (max-width: 1500px) {
    bottom: 23%;
    left: 0;
  }

  @media screen and (max-width: 1300px) {
    bottom: 20%;
    left: 0;
  }

  @media screen and (max-width: 1270px) {
    bottom: 8%;
    left: 110px;
    padding: 15px 25px;
    background-color: var(--main-color);
    text-align: center;
    border-radius: 16px;
  }
  @media screen and (max-width: 460px) {
    bottom: 5%;
    left: 70px;
  }

  @media screen and (max-width: 370px) {
    bottom: 5%;
    left: 25px;
  }

  h4 {
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__icon {
    width: 60px;
    height: 30px;
    cursor: pointer;
  }
}
