@import '../../../../styles/colors';

.personal-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    border: 1px solid $grey;
    color: $grey;
    font-size: 22px;
    padding: 11px 20px;
    border-radius: 30px;
    width: 20%;
    min-width: 260px;
    text-align: center;

    @media screen and (max-width: 1500px) {
      font-size: 20px;
      padding: 10px 15px;
      min-width: 210px;
    }

    @media screen and (max-width: 1450px) {
      font-size: 18px;
      padding: 8px 15px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
    padding-bottom: 47px;
    border-bottom: 1px solid var(--main-color-hover);

    @media screen and (max-width: 1450px) {
      margin-top: 60px;
      padding-bottom: 20px;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 17px;
    width: 100%;
    border-bottom: 1px solid var(--main-color-hover);
    padding: 20px 20px 57px 20px;
    position: relative;

    &-item {
      width: 24%;
    }

    p {
      font-size: 14px;
      color: var(--text-color);

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }
  }

  &-link {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    text-decoration: underline;
    background: $main-gradient;
    padding: 10px;
    border-radius: 20px;
    width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
      width: 160px;
    }
  }

  .title {
    font-weight: bold;
    margin-bottom: 10px;

    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
    border-bottom: 1px solid var(--main-color-hover);
    padding: 20px 20px 47px 20px;

    &-inner {
      position: relative;
      text-align: center;
      background: var(--content-bg);
      box-shadow: 0 0 10px 0 rgb(64 64 64 / 20%);
      width: 70%;
      min-width: 400px;
      height: 170px;
      padding-top: 20px;
      border-radius: 16px;
      @media screen and (max-width: 1800px) {
        width: 80%;
      }

      @media screen and (max-width: 1600px) {
        width: 90%;
      }

      @media screen and(max-width: 1520px) {
        width: 100%;
      }

      h4 {
        color: $grey;
        width: 100%;
        font-size: 25px;
        margin-bottom: 20px;
        @media screen and (max-width: 1500px) {
          font-size: 23px;
        }
      }

      p {
        font-size: 18px;

        @media screen and (max-width: 1500px) {
          font-size: 16px;
        }
      }
    }
  }

  &__card {
    height: 75px;
    width: 50%;
    position: relative;

    p {
      font-size: 17px;
      color: #777777;

      @media screen and (max-width: 1500px) {
        font-size: 15px;
      }
    }

    .title {
      color: var(--text-color);
    }

    .personal-details-link {
      bottom: -40px;
      width: 170px;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
        width: 150px;
      }

      &:hover {
        color: white !important;
        background: $main-gradient-left;
        opacity: 1 !important;
        transition: 0.4s ease-in all;
      }
    }

    &:first-child {
      border-right: 1px solid #777777;
    }
  }

  .lock {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
    height: 50px;
  }

  .edit {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }

  a:hover {
    color: white !important;
    background: $main-gradient-left;
    opacity: 1 !important;
    transition: 0.4s ease-in all;
  }
}
