@import '../../../styles/colors';

.field-textarea {
  width: 100%;
  height: 200px;
  outline: none;
  border-radius: 16px;
  padding: 10px;
  margin: 10px 0;
  background: $white;
  border: 1px solid $white;
  color: black;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 18px;
  -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  position: relative;

  @media screen and(max-width: 1500px) {
    font-size: 14px;
  }

  &:focus {
    border: 1px solid  var(--main-color);;
    -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  }
}
