@import '../../styles/colors';

.account {
  z-index: 1000;

  &__body {
    min-height: 50vh;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 30px;

    @media screen and (max-width: 1600px) {
      margin: 25px 30px 0;
    }

    @media screen and (max-width: 1450px) {
      margin: 100px 30px 0;
    }
  }

  &__body-item {
    background: var(--content-bg);
    z-index: 999;

    &:first-child {
      width: 20%;
      min-width: 300px;
      max-width: 350px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      border-radius: 20px;

      @media screen and(max-width: 1520px) {
        min-width: 260px;
        max-width: 280px;
      }
    }

    &:last-child {
      flex: 1;
      box-shadow: 0 0 10px 0 rgb(64 64 64 / 20%);
      border-radius: 0 20px 20px 0;

      @media screen and(max-width: 1450px) and (max-height: 700px) {
        max-height: 500px;
      }

      .account__nav {
        position: relative;

        a {
          position: absolute;
          font-size: 16px;
          outline: none;
          cursor: pointer;
          border: none;
          border-radius: 0 0 60px 0;
          padding: 16px 22px;
          color: var(--navigation-text);
          background: #f3f3f3;
          text-align: center;
          transition: 0.6s ease all;

          @media screen and (max-width: 1600px) {
            padding: 15px;
            font-size: 14px;
          }

          &:nth-child(1) {
            min-width: 250px;
            z-index: 4;
            @media screen and (max-width: 1500px) {
              min-width: 200px;
            }

            @media screen and (max-width: 1450px) {
              min-width: 170px;
            }
          }

          &:nth-child(2) {
            min-width: 450px;
            z-index: 3;
            text-align: right;
            padding-right: 55px;

            @media screen and (max-width: 1500px) {
              min-width: 440px;
            }

            @media screen and (max-width: 1450px) {
              min-width: 340px;
              padding-right: 40px;
            }
          }

          &:nth-child(3) {
            min-width: 650px;
            z-index: 2;
            text-align: right;
            padding-right: 40px;
            @media screen and (max-width: 1500px) {
              min-width: 610px;
            }

            @media screen and (max-width: 1450px) {
              min-width: 490px;
            }
          }
          &:nth-child(4) {
            z-index: 1;
            min-width: 850px;
            color: var(--navigation-text);
            text-align: right;
            padding-right: 35px;
            @media screen and (max-width: 1575px) {
              min-width: 800px;
            }

            @media screen and (max-width: 1450px) {
              min-width: 650px;
            }
          }

          &:nth-child(even) {
            background-color: #e3e3e3;
          }

          &:hover {
            transition: 0.6s ease all;
            color: var(--navigation-text-active);
            background: $main-gradient;
          }
        }
      }

      .active {
        background: $main-gradient !important;
        color: var(--navigation-text-active) !important;
        transition: 0.6s ease all;
      }
    }
  }

  .avatar__block {
    background: white;
    box-shadow: 0 0 10px 0 rgb(64 64 64 / 20%);
    min-height: 300px;
    margin-bottom: 8px;
    border-radius: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    @media screen and (max-width: 1450px) {
      min-height: 260px;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      min-height: 240px;
    }

    h3 {
      text-transform: uppercase;
      margin: 20px 0;

      @media screen and (max-width: 1450px) {
        margin: 10px 0;
        font-size: 14px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 23px;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
      @media screen and(max-width: 1520px) {
        font-size: 18px;
      }
    }

    p {
      font-size: 13px;
      background: $main-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .avatar {
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid $link-color;
    margin-bottom: 20px;
  }

  .avatar-edit {
    position: absolute;
    top: 62%;
    left: 73%;
    transform: translate(-73%, -62%);
    border: none;
    background: transparent;
    outline: none;
    font-size: 20px;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    z-index: 10;
  }

  .help__block {
    min-height: 300px;
    background: $main-gradient;
    box-shadow: 0 0 10px 0 rgb(64 64 64 / 20%);
    border-radius: 0 0 0 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    @media screen and (max-width: 1450px) {
      min-height: 260px;
      max-height: 260px;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      min-height: 250px;
      max-height: 250px;
    }

    img {
      width: 80px;
      height: 60px;
      margin: 30px 0 18px 0;

      @media screen and (max-width: 1450px) {
        width: 70px;
        height: 50px;
        margin: 15px 0 18px 0;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 25px;
      margin-bottom: 16px;
      text-transform: uppercase;
      color: white;
      @media screen and(max-width: 1520px) {
        font-size: 18px;
      }
    }

    p {
      color: white;
      margin: 0 45px 30px 45px;
      line-height: 17px;
      @media screen and(max-width: 1520px) {
        font-size: 12px;
        margin: 0 35px 30px 35px;
      }

      @media screen and(max-width: 1450px) and (max-height: 700px) {
        margin-bottom: 20px;
      }
    }

    &-button {
      color: var(--main-color);
      background: var(--content-bg);
      border: 1px solid transparent;
      cursor: pointer;
      font-weight: bold;
      padding: 10px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      max-width: 185px;
      justify-content: center;
      text-align: center;
      margin: 0 auto 25px auto;
      transition: all ease-in 0.4s;

      @media screen and (max-width: 1450px) {
        margin: 0 auto 15px auto;
      }

      &:hover {
        color: var(--text-color);
        transition: all ease-in 0.4s;
      }
    }
  }

  &-content {
    height: calc(100% - 70px);
    margin: 70px 70px 0 70px;

    @media screen and (max-width: 1550px) {
      height: calc(100% - 50px);
      margin: 50px 30px 0 30px;
    }

    @media screen and(max-width: 1520px) {
      margin: 50px 30px 0 20px;
    }

    @media screen and (max-width: 1450px) {
      margin: 0 10px;
      height: 100%;
      overflow: hidden;
    }
  }
}
