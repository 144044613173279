@import '../../../../styles/colors';

.white-label-table {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
  }

  &__dates {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__csv {
    border-radius: 8px;
    height: 30px;
    text-transform: uppercase;
    font-size: 12px;
    border: var(--main-color);
    background: $main-gradient;
    color: white;
    cursor: pointer;
  }

  .ant-select-selector {
    border: 1px solid var(--main-color) !important;
    min-width: 230px !important;
  }
}
