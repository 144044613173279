@import '../../../styles/colors';

.certificate {
  display: flex;
  align-items: center;
  gap: 10px !important;

  &-icon {
    font-size: 20px;
    color: $correctColor !important;

    &-error {
      font-size: 20px;
      color: $incorrectColor !important;
    }
  }
}
