@import '../../../styles/colors';

.layout__header {
  position: absolute;
  top: 0;
  right: 0;
  width: 28.5vw;
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
  z-index: 1;
  max-width: 528px;
  max-height: 160px;
  // opacity: 0;

  @media screen and(max-width: 1450px) {
    width: 400px;
    height: 145px;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1920px) {
      width: 27.5vw;
      top: -25px;
    }
    @media screen and (max-width: 1800px) {
      width: 27.5vw;
      top: 0;
    }

    @media screen and (max-width: 1500px) {
      width: 28.5vw;
      top: 0;
    }

    @media screen and(max-width: 1450px) {
      width: 385px;
      height: 134px;
    }
  }

  &-inner {
    margin-top: 10px;
    min-width: 85%;
    height: 70px;
    background: $header-inner;
    border-radius: 50px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10px;
    position: relative;
    @media screen and(max-width: 1500px) {
      margin: 0;
    }

    @media screen and(max-width: 1366px) {
      padding: 0 5px;
    }
  }

  &-user {
    display: flex;
    align-items: center;
    color: $white;
    font-size: 15px;
    font-weight: 600;

    a {
      outline: none;
      background: transparent;
      border: none;
      text-decoration: underline;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      color: $white;
    }
  }

  &-credits {
    background: $header-skips;
    min-height: 55px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: $white;
    padding: 5px 20px;
    margin-left: 10px;
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;
    flex: 1;
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      font-size: 16px !important;
    }
  }

  .ant-avatar {
    margin-right: 10px;
    border: 2px solid $header-avatar-border;
  }

  .profile-link {
    display: block;
    text-decoration: none;
    font-size: 15px;
  }

  p {
    font-weight: 700;
    letter-spacing: 1px;

    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }

  &-logout {
    font-weight: 400 !important;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
    letter-spacing: initial;
  }
}
