@import '../../styles/colors';

.dashboard {
  padding: 65px 30px 0 30px;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  z-index: 3;

  @media screen and(max-width: 1520px) {
    padding: 0 30px;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    margin: 11vh 0 45px 0;
    z-index: 3;

    @media screen and(max-width: 1520px) {
      margin: 16vh 0 45px 0;
    }

    @media screen and(max-width: 1200px) {
      margin: 16vh 0 0 0;
    }
    @media screen and(max-height: 750px) {
      margin: 16vh 0 10px 0;
    }
  }

  &__body-item {
    width: 40.5%;
    margin: 0 5px;

    &:last-child {
      width: 50%;
      padding: 0 10px;
      min-height: 642px;

      @media screen and(max-width: 1650px) {
        min-height: 542px;
      }

      @media screen and(max-width: 1450px) and (max-height: 700px) {
        height: 450px;
      }

      @media screen and(max-width: 1520px) {
        min-height: 400px;
        width: 57%;
      }
    }
  }

  .custom-scrollbar {
    @media screen and(max-width: 1450px) and (max-height: 700px) {
      height: 320px !important;
    }
  }

  .table {
    min-height: 642px;
    background: var(--content-bg);
    height: 100%;
    font-size: 12px;
    -webkit-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 20px 2px rgba(34, 60, 80, 0.2);
    border-radius: 16px !important;
    padding: 30px 0 0 0;
    position: relative;

    @media screen and(max-width: 1650px) {
      min-height: 542px;
    }

    @media screen and(max-width: 1520px) {
      min-height: 400px;
      padding-top: 15px;
    }

    &__article {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10%;
      padding: 2px 0;
      background-image: linear-gradient(var(--content-bg), var(--content-bg)),
        linear-gradient(90deg, transparent 0%, var(--gradient-3) 30%, var(--gradient-2) 50%, var(--gradient-3) 70%, transparent 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    &-btn {
      border: none;
      background: #e6e6e6;
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.2s background-color ease-in;
      color: var(--navigation-text);
    }

    &-btn:hover {
      color: var(--navigation-text-active);
      border: none;
      transition: 0.2s background-color ease-in;
      background: $main-gradient;
      opacity: 0.7;
    }

    &-btn__active {
      border: none;
      background: $main-gradient;
      color: var(--navigation-text-active);
      transition: 0.2s background-color ease-in;
      cursor: initial;
    }

    h3 {
      text-transform: uppercase;
      font-size: 30px;
      text-align: center;
      font-family: Bebas Neue, sans-serif;
      padding: 10px 0;
      font-weight: bold;
      color: var(--text-color);

      @media screen and(max-width: 1300px) {
        font-size: 25px !important;
      }
    }

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      flex-wrap: wrap;
    }

    .tr-bg {
      background: var(--tr-bg);
    }

    .td {
      font-size: 15px;
      color: var(--text-color);
      @media screen and(max-width: 1550px) {
        font-size: 12px !important;
      }

      &:nth-child(1) {
        text-transform: uppercase;
        flex: 1;
        text-align: left;
        cursor: pointer;
      }

      &:nth-child(2) {
        width: 10%;
        min-width: 100px;
      }

      &:nth-child(3) {
        width: 10%;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(4) {
        width: 15%;
        text-align: center;
        cursor: pointer;
        min-width: 80px;
      }

      &:nth-child(5) {
        width: 15%;
        text-align: center;
        cursor: pointer;
        min-width: 80px;
      }

      & > b {
        display: inline-block;
        max-width: 260px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (min-width: 1550px) {
          font-size: 15px !important;
        }
      }
    }

    &__header {
      .tr {
        padding: 20px 15px 5px 15px;
      }

      .td {
        text-align: center;
        text-transform: uppercase;
        font-size: 13px !important;
        color: $grey !important;
        font-weight: 600;
        cursor: initial;
      }
    }

    .primary-button {
      width: 40%;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 30px;
      margin-top: 30px;
      height: 50px;
      text-transform: uppercase;
      font-size: 20px;
      border: var(--main-color);
      background: $main-gradient;

      @media screen and(min-width: 1270px) {
        height: 45px;
        font-size: 16px;
        width: 30%;
        bottom: -25px;
      }
    }
  }

  //&__footer {
  //  width: 95%;
  //  text-align: center;
  //  margin: auto;
  //  padding: 2px;
  //  border-radius: 30px;
  //  -webkit-border-radius: 30px;
  //  background-image: linear-gradient(var(--content-bg), var(--content-bg)),
  //    linear-gradient(
  //      90deg,
  //      var(--main-color) 0%,
  //      var(--main-color-hover) 15%,
  //      transparent 40%,
  //      transparent 51%,
  //      transparent 62%,
  //      var(--main-color-hover) 78%,
  //      var(--main-color) 100%
  //    );
  //  background-origin: border-box;
  //  background-clip: content-box, border-box;
  //
  //  p {
  //    color: var(--text-color);
  //    padding: 20px 0;
  //
  //    @media screen and(max-width: 1300px) {
  //      font-size: 12px;
  //    }
  //  }
  //}
}
