.discount-helper {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 18px;
    color: var(--main-color) !important;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: -5px;
    min-height: 100px;
    overflow: scroll;
    min-width: 100%;
    z-index: 101;
    color: var(--text-color);
    background: var(--content-bg);
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px 1px #ccc;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  &-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent !important;
    border: none !important;
    outline: none;
    cursor: pointer;
    color: var(--text-color) !important;
  }

  &-cards {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
