@import '../../../../styles/colors';

.admin-faqs-container {
  margin-bottom: 1rem;
  position: relative;
}

.admin-faqs-list {
  height: 520px;

  overflow-y: auto;
  position: relative;

  @media screen and (max-width: 1450px) {
    height: 420px;
  }
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 390px;
  }

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 1rem;
  padding: 0 10px 2rem;

  list-style: none;

  transition: all 0.25s ease-in-out;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 100px;
  }
}

.admin-faqs-list-no-pagination {
}

.admin-faqs-label {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  border-radius: 15px;

  & > b {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;

    color: #fff;
    font-weight: bold;
    font-family: 'Arial', sans-serif;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);

    transition: all 0.3s linear;

    @media screen and(max-width: 1520px) {
      top: 13px;
      width: 25px;
      height: 25px;
    }
  }

  & > input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;

    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  & > input:checked + .admin-faqs-title {
    background-position: 100%;
    width: 100%;
  }

  & > input:checked + .admin-faqs-title + .admin-faqs-content {
    border: 1px solid #eee;
    max-height: 555px;
  }

  & > input:checked + .admin-faqs-title + .admin-faqs-content p {
    opacity: 1;
    transform: scale(1);
    color: var(--text-color);
  }

  & > input:checked + .admin-faqs-title + .admin-faqs-content textarea {
    opacity: 1;
    transform: scale(1);
  }

  & > input:checked + .admin-faqs-title + .admin-faqs-content + b {
    transform: rotate(90deg);
  }
}

.admin-faqs-title {
  position: relative;
  z-index: 2;
  width: 100%;

  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 4rem;
  min-height: 60px;
  justify-content: space-between;
  border-bottom: 1px solid transparent;
  background: $main-gradient;
  background-size: 200%;
  background-position: 0;

  transition: all 0.3s linear;

  @media screen and(max-width: 1520px) {
    min-height: 45px;
  }

  &:hover {
    cursor: pointer;
    background-position: 30%;
  }

  & > h4 {
    color: #fff;
    font-size: 16px;
    width: 70%;

    @media screen and(max-width: 1520px) {
      font-size: 14px;
    }
  }
}

.admin-faqs-actions {
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    border: none;
    padding: 10px;
    border-radius: 8px;
    background: var(--content-bg);
    color: var(--text-color);
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      font-size: 9px;
      padding: 8px 9px;
    }

    @media screen and (max-width: 1400px) {
      padding: 8px 7px;
    }
  }

  button:hover {
    color: white;
    background: var(--main-color);
  }
  .manipulate-btn {
    color: white !important;
  }
}

.admin-faqs-title-input {
  width: 60%;
  min-height: 60px;
  border-top: 1px solid var(--main-color);
  border-bottom: 1px solid var(--main-color);
  border-left: transparent;
  border-right: transparent;
  padding: 0 10px;

  transition: all 0.3s linear;
  outline: none;
}

.admin-faqs-content {
  position: relative;
  width: 100%;
  max-height: 0;
  padding: 0;

  border-top: none;
  border-radius: 0 0 15px 15px;

  transition: max-height 0.3s ease-in-out;

  p {
    position: relative;

    margin: 0;
    padding: 1.2em 3em;
    opacity: 0;

    font-size: 18px;

    transform: scale(0);
    transition: all 0.55s ease;

    @media screen and(max-width: 1520px) {
      font-size: 14px;
    }
  }

  textarea {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1.2em 3em;
    opacity: 0;

    font-size: 18px;

    transform: scale(0);
    transition: all 0.55s ease;
  }
}

.admin-faqs__create {
  position: absolute;
  top: -45px;
  right: 80px;
  z-index: 100;
  background: transparent;
  font-size: 16px;
  padding: 5px 10px;
  cursor: pointer;
  border: transparent;
  color: var(--main-color);

  &:hover {
    border: var(--main-color) 1px solid;
  }

  @media screen and (max-width: 1400px) {
    right: 15px;
  }
}

.label__faqs {
  color: var(--text-color) !important;
  font-size: 14px;

  .field {
    padding: 10px !important;
  }
}

.faqs {
  &__modal {
    .ant-modal-body {
      padding: 24px !important;
      background: var(--content-bg) !important;
    }

    .ant-modal {
      background: var(--content-bg) !important;
    }

    .button-block {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      button {
        padding: 5px 10px;
        outline: none;
        background: transparent;
        border: 1px solid var(--text-color) !important;
        cursor: pointer;
        border-radius: 8px;
        color: var(--text-color);

        &:hover {
          background: $main-gradient;
          color: white;
          border: 1px solid var(--main-color) !important;
        }
      }
    }
  }
}

.faqs__position {
  display: flex;
  align-items: center;
  gap: 10px;
}

.number__field {
  border: none;
  width: 50px;
  height: 34px;
  padding-left: 10px;
  border-radius: 8px;
}

.admin-faqs__container {
  margin-top: 35px;
  @media screen and (max-width: 1400px) {
    margin-top: 15px;
  }
}
