@import '../../../styles/colors';

.results {
  &-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
      cursor: pointer;
      position: relative;
    }
  }

  &-tr {
    width: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background: $main-gradient;
    margin: 0 0 25px 0;
    border-radius: 16px;
    height: 50px;

    @media screen and(max-width: 1520px) {
      padding: 0 5px !important;
      height: 40px !important;
      margin-bottom: 15px !important;
      border-radius: 8px !important;
    }
  }

  &-td {
    padding: 10px;
    font-size: 14px;
    color: white !important;
    outline: none;
    background: transparent;
    border: none;
    margin: 0 10px;
    @media screen and(max-width: 1520px) {
      font-size: 12px;
    }

    &:nth-child(1) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
    }

    &:nth-child(2) {
      width: 7%;
      border: 1px solid white;
      border-radius: 50px;
      font-size: 12px;
      text-align: center;
      padding: 5px 0;
      margin-right: 6%;
      min-width: 90px;
      text-transform: uppercase;
    }

    &:nth-child(3) {
      width: 80px !important;
      background: var(--action-color) !important;
      border-radius: 8px !important;
      text-align: center !important;
      border: none !important;
      padding: 10px !important;
      margin: 0 10px !important;
      max-width: 80px !important;

      @media screen and(max-width: 1520px) {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 64px !important;
      }
    }

    &:nth-child(4) {
      width: 80px;
      background: var(--action-color);
      border-radius: 8px;
      text-align: center;

      @media screen and(max-width: 1520px) {
        height: 32px;
        width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .skeleton {
    &-tr {
      background: var(--tr-bg);
      height: 50px;

      @media screen and(max-width: 1520px) {
        height: 40px;
      }
    }

    .checkbox label:before {
      border: 1px solid rgba(188, 188, 188, 0.5);
    }

    .checkbox input:checked + label:after {
      border: solid rgba(188, 188, 188, 0.5);
      border-width: 0 2px 2px 0;
    }

    .checkbox input:checked + label:before {
      background: transparent;
      border: 1px solid rgba(188, 188, 188, 0.5);
    }
  }

  .checkbox {
    width: 70px;
    margin-bottom: 25px;

    @media screen and (max-width: 1500px) {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 1300px) {
      width: 50px;
    }
  }

  .checkbox label:before {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #999999;
  }

  .checkbox input:checked + label:after {
    top: 6px;
    left: 14px;
    width: 7px;
    height: 17px;
    border: solid #999999;
    border-width: 0 2px 2px 0;
  }

  .checkbox input:checked + label:before {
    background: transparent;
    border: 1px solid #999999;
  }
}
