@import '../../styles/colors';

.results-library {
  margin: 60px 60px 30px 60px;
  position: relative;

  @media screen and (max-width: 1750px) {
    margin: 40px 60px 0 60px;
  }

  @media screen and(max-width: 1550px) {
    margin: 80px 30px 30px 30px;
    z-index: 1000;
  }

  @media screen and(max-width: 1450px) {
    margin: 40px 30px 00px 30px;
  }

  &__navigation {
    background: var(--content-bg);
    border-radius: 32px 32px 0 0;
    -webkit-box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.2);
    box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.2);

    &-button {
      padding: 1rem 4rem 1rem 2rem;
      font-size: 16px;
      color: var(--navigation-text);
      text-align: center;

      border: none;
      border-radius: 0 0 60px 0;
      background: $main-gradient-hover;
      background-size: 500% 500%;
      background-position: 100% 50%;
      outline: none;

      transition: 0.45s ease all;
      cursor: pointer;

      @media screen and(max-width: 1520px) {
        padding: 12px 60px 12px 30px;
      }

      &:nth-child(2n) {
        background: $main-gradient-hover;
        background-size: 500% 500%;
        background-position: 100% 50%;
      }

      &:nth-child(2n) -active {
        background: $main-gradient-hover;
        background-size: 500% 500%;
        background-position: 100% 50%;
      }

      &:hover,
      &:focus-visible {
        color: var(--navigation-text-active);
        background-position: 60% 15%;
      }

      &:not(:first-child) {
        margin-left: -2.5rem;
        padding-left: 3.5rem;
      }

      &:first-child {
        border-top-left-radius: 20px;
        z-index: 1;
      }

      &-active {
        color: var(--navigation-text-active);
        background-position: 0 0 !important;
        pointer-events: none;
      }
    }
  }

  &__header {
    width: 50%;
    min-width: 640px;
    margin-bottom: 1rem;

    @media screen and(max-width: 1300px) {
      margin-bottom: 20px;
    }

    h1 {
      font-family: Bebas Neue, sans-serif;
      font-size: 40px;
      line-height: 0.9;
      font-weight: bold;
      color: var(--text-color);

      @media screen and(max-width: 1520px) {
        font-size: 35px;
      }

      span {
        color: var(--main-color);
      }
    }

    p {
      font-size: 18px;
      width: 62%;
      min-width: 540px;
      line-height: 1.15;
      color: var(--text-color);

      @media screen and(max-width: 1520px) {
        font-size: 14px;
      }
    }
  }

  &__body {
    -webkit-box-shadow: 0 10px 10px 0 rgba(64, 64, 64, 0.2);
    box-shadow: 0 10px 10px 0 rgba(64, 64, 64, 0.2);
    min-height: 600px;
    background: var(--content-bg);
    border-radius: 0 0 32px 32px;
    display: flex;
    flex-direction: column;

    @media screen and(max-width: 1520px) {
      min-height: 500px;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      min-height: 400px;
    }

    &-header {
      position: relative;
      padding: 20px 0;
      border-bottom: 1px solid #e7e7e7;

      @media screen and (max-width: 1400px) {
        padding: 15px 0;
      }
    }

    &-header-inner {
      width: 700px;
      position: absolute;
      bottom: -25%;
      left: 50%;
      transform: translate(-50%, 25%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1000;

      @media screen and(min-width: 1600px) {
        width: 55%;
      }

      @media screen and(min-width: 1800px) {
        width: 45%;
      }

      .month {
        width: 200px;
        border-radius: 32px;
        position: relative;

        &:before {
          z-index: 1000;
          content: '';
          position: absolute;
          top: 8px;
          left: 19px;
          width: 23px;
          height: 24px;
          background: url('../../assets/UI/calendar.png') 0 0 no-repeat;

          @media screen and(max-width: 1520px) {
            width: 19px;
            height: 20px;
            background-size: cover;
          }
        }
      }

      .ant-select-selector {
        border-radius: 32px !important;
        height: 44px !important;
        background: #f2f2f2 !important;
        border: none !important;

        @media screen and(max-width: 1520px) {
          height: 36px !important;
        }
      }

      .ant-select-arrow {
        color: var(--main-color);

        @media screen and(max-width: 1520px) {
          top: 55% !important;
          right: 15px !important;
        }
      }

      .ant-select-selection-item {
        padding: 7px 0 0 40px !important;

        @media screen and(max-width: 1520px) {
          padding: 5px 0 0 40px !important;
        }
      }

      .search__icon {
        position: absolute;
        bottom: 50%;
        right: 40px;
        z-index: 1;
        transform: translate(0, 50%);
        cursor: pointer;
        padding: 10px 0;
      }

      .search__field {
        border-radius: 32px;
        outline: none;
        border: none;
        background: #f2f2f2;
        padding: 12px 20px;
        width: 420px;
        font-size: 16px;
        position: relative;

        @media screen and (max-width: 1400px) {
          height: 40px;
        }

        @media screen and (max-width: 1400px) {
          font-size: 12px;
        }
      }

      .search__field::placeholder {
        font-style: italic;
        font-size: 18px;
        opacity: 0.7;

        @media screen and (max-width: 1400px) {
          font-size: 14px;
        }
      }

      .search__field:focus {
        border: 2px solid var(--main-color-hover);
        padding: 10px 20px;
      }
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .ant-spin-container:after {
      border-radius: 32px !important;
    }

    a {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      color: var(--text-color) !important;
      width: 30% !important;
      font-size: 18px !important;

      @media screen and(max-width: 1550px) {
        font-size: 16px;
      }

      @media screen and (max-width: 1500px) {
        width: 20%;
        font-size: 14px !important;
      }
    }

    .arrow {
      width: 25px;
      height: 15px;
      margin-right: 10px;
    }

    &-buttons {
      display: flex;
      align-items: stretch;
      justify-content: center;
      width: 70%;
      @media screen and(max-width: 1520px) {
        height: 60px;
      }
    }

    .selected {
      width: 25%;
      display: flex;
      align-items: center;
      border-radius: 80px 0 0 0;
      background: $main-gradient;
      padding: 20px 0;
      color: white;
      justify-content: center;
      font-size: 22px;
      min-width: 258px;

      @media screen and (max-width: 1500px) {
        min-width: 228px;
        font-size: 20px;
      }

      @media screen and (max-width: 1500px) {
        min-width: 200px;
        font-size: 18px;
      }

      @media screen and(max-width: 1520px) {
        font-size: 14px;
      }

      b {
        margin-right: 5px;
      }
    }

    .button-block {
      padding: 19px 0;
      width: 56%;
      border-radius: 0 80px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: $action-gradient;
      min-width: 450px;
      @media screen and (max-width: 1800px) {
        width: 52%;
      }

      @media screen and (max-width: 1500px) {
        min-width: 420px;
      }

      button {
        outline: none;
        font-size: 18px;
        border-radius: 26px;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        background: var(--main-color-hover);
        width: 300px;
        padding: 15px 20px;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        border: none;
        position: relative;

        @media screen and (max-width: 1700px) {
          font-size: 16px;
        }

        @media screen and(max-width: 1520px) {
          font-size: 14px;
        }

        &:before {
          background: $main-gradient;
          position: absolute;
          top: 3px;
          left: 4px;
          right: 4px;
          bottom: 3px;
          content: '';
          border-radius: 25px;
          opacity: 1;
          z-index: 100;
        }

        @media screen and (max-width: 1800px) {
          width: 230px;
        }

        @media screen and(max-width: 1520px) {
          width: 200px;
        }

        p {
          font-weight: bold;
          opacity: 1;
          z-index: 100;
          @media screen and(max-width: 1520px) {
            font-size: 12px;
          }
        }
      }
    }

    .form-group label:before {
      border: 1px solid white;
      border-radius: 6px;
      padding: 7px;
    }

    .form-group input:checked + label:before {
      background: transparent;
      border: 1px solid white;
    }

    .form-group input:checked + label:after {
      border-width: 0 1px 1px 0;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;

      @media screen and(max-width: 1520px) {
        top: 11px;
      }
    }

    .checkbox-label {
      color: white;
      font-size: 16px;
    }
  }

  .table {
    width: 100%;
    padding: 37px 48px;
    position: relative;

    @media screen and(max-width: 1520px) {
      padding: 35px 30px 0 30px;
    }
  }

  .thead {
    width: calc(100% - 70px);
    margin: 0 0 0 65px;
    display: grid;
    grid-template-columns: 1fr 120px 120px 120px 100px 100px;

    @media screen and(max-width: 1520px) {
      grid-template-columns: 1fr 100px 100px 120px 100px 100px;
    }
    transition: 0.6s ease-in all;

    .sort {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .tbody {
    margin-top: 15px;
    margin-bottom: 30px;
    height: 450px;
    overflow: auto;

    @media screen and (max-width: 1700px) {
      height: 400px;
      margin-bottom: 0;
    }

    @media screen and(max-width: 1450px) {
      height: 300px;
      margin-bottom: 0;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      height: 270px;
    }
  }

  .th {
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    color: #999999;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .download-checkbox {
    width: 130px;
  }

  .download-checkbox .checkbox-label {
    font-size: 16px;
    color: var(--text-color);
  }

  .download-checkbox input:checked + label:before {
    background: transparent;
    border: 1px solid var(--text-color);
  }

  .download-checkbox input:checked + label:after {
    border: solid var(--text-color);
    border-width: 0 2px 2px 0;
    left: 5px;
    width: 6px;
    height: 11px;
  }

  .download-checkbox label:before {
    padding: 7px;
    border-radius: 5px;
  }
}

.sort-icon {
  width: 10px;
  height: 5px;
  margin-left: 10px;
  transform: rotateX(0);
  cursor: pointer;

  &-top {
    transform: rotateX(180deg);
  }
}
