@import '../../../styles/colors';

.phone__field {
  width: 100%;
  color: var(--text-color) !important;
  height: 35px;

  @media screen and (max-width: 1450px) {
    height: 35px !important;
  }

  .field {
    margin-bottom: 30px;
    width: 100% !important;
    font-family: 'Glacial Indifference', sans-serif !important;
    background: $white !important;
    border: 1px solid $white !important;
    font-size: 18px !important;
    border-radius: 16px !important;
    -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2) !important;
    position: relative;
    height: 35px !important;

    @media screen and (max-width: 1600px) {
      font-size: 16px !important;
    }

    @media screen and (max-width: 1450px) {
      height: 35px !important;
      font-size: 15px !important;
    }
    @media screen and (max-height: 700px) {
      height: 35px !important;
    }
  }

  .field:focus {
    -webkit-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.2);
  }

  .phone__field__btn {
    border-right: none;
    border-color: transparent;
    background: $white !important;
    outline: none;
    border-radius: 16px 0 0 16px !important;

    @media screen and (max-width: 1600px) {
      font-size: 16px !important;
    }

    @media screen and (max-width: 1450px) {
      height: 35px !important;
      font-size: 15px !important;
    }
    @media screen and (max-height: 700px) {
      height: 35px !important;
    }
  }

  .field:hover + .phone__field__btn,
  .field:focus + .phone__field__btn {
    border: 1px solid var(--main-color) !important;
    border-right: none !important;
  }

  .field:hover,
  .field:focus {
    border: 1px solid var(--main-color) !important;
  }

  .country-name {
    color: var(--text-color) !important;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 12px;
  }
}

.phone__field__btn:hover {
  background: $white !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: $white !important;
}

.react-tel-input .flag-dropdown.open .selected-flag:hover {
  background: $white !important;
}

.react-tel-input .selected-flag {
  background: $white !important;
  border-radius: 16px 0 0 16px !important;
}

.react-tel-input .selected-flag:hover {
  background: $white !important;
  border-radius: 16px 0 0 16px !important;
}

.react-tel-input .flag-dropdown:hover {
  background: $white !important;
}

.react-tel-input .flag-dropdown.open {
  background: $white !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: $white !important;
}
