.change_email-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 30px;
}

.change-email {
  .password__eye {
    @media screen and (max-width: 1400px) {
      top: 23px;
    }
  }
}
