@import '../../../../../styles/colors.scss';

.users-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.users-list__container-statistic {
  height: 430px;
  margin-bottom: 1rem;
  overflow-y: auto;

  @media screen and (max-width: 1300px) {
    height: 370px;
  }

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 400px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 100px;
  }
}

.container-statistic-pagination {
  height: 500px !important;

  @media screen and (max-width: 1450px) {
    height: 420px !important;
  }
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 355px !important;
  }
}

.user-info__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding: 10px;

  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }

  & > span {
    display: flex;
    align-items: center;
    gap: 1rem;

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      justify-content: center;

      & > span {
        padding: 5px;
      }
    }
  }

  & > h3 {
    margin: 0;
    padding: 0;

    font-weight: bold;
    color: #999999;

    &:last-child {
      text-align: center;
    }
  }

  &:first-child {
    position: sticky;
  }

  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
}

.detail-info__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding: 10px;

  &:nth-child(2n) {
    background-color: var(--tr-bg) !important;
  }

  & > span {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-color);

    &:first-child {
      text-transform: capitalize;
    }

    &:last-child {
      justify-content: center;

      & > span {
        padding: 5px;
      }
    }
  }

  & > h3 {
    margin: 0;
    padding: 0;

    font-weight: bold;
    color: #999999;

    &:last-child {
      text-align: center;
    }
  }

  &:first-child {
    position: sticky;
  }

  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
}

.users-list__item-status {
  display: flex;
  flex-wrap: wrap;

  & > span {
    width: 100%;
  }
}

// .users-list__popover {
//   display: flex;
//   gap: 10px;

//   & > button {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 5px 10px;
//     color: #fff;
//     border: none;
//     border-radius: 5px;
//     background: $main-gradient;

//     cursor: pointer;
//     transition: 0.25s ease all;

//     &:hover,
//     &:focus-visible {
//       background: $main-gradient-left;
//     }
//   }
// }

.price__panel {
  background: $main-gradient;
}

.price__info__item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 45px;

  &:not(:last-child) {
    border-bottom: 1px solid #cacaca;
    margin-bottom: 5px;
  }

  .discount__block {
    position: absolute;
    text-transform: uppercase;
    top: -50px;
    left: 75%;
  }

  p {
    font-size: 16px;
  }
}

.show__info__button {
  background: linear-gradient(to right, var(--gradient-1) 0%, var(--gradient-2) 52%, var(--gradient-3) 100%);
  padding: 15px 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  color: $white;

  @media screen and (max-width: 1450px) {
    padding: 10px 15px;
  }

  &:hover {
    cursor: pointer;
    background: $main-gradient;
    opacity: 0.8;
    color: $white;
  }
}

.payment-info-list {
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 1rem;
  padding: 0 10px 2rem;
  list-style: none;
  transition: all 0.25s ease-in-out;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 100px;
  }
}

.payment-info-label {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  border-radius: 15px;

  & > b {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;

    color: #fff;
    font-weight: bold;
    font-family: 'Arial', sans-serif;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);

    transition: all 0.3s linear;
  }

  & > input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;

    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  & > input:checked + .payment-info-title {
    background-position: 100%;
    width: 100%;
  }

  & > input:checked + .payment-info-title + .payment-info-content {
    max-height: 555px;
  }

  & > input:checked + .payment-info-title + .payment-info-content p {
    opacity: 1;
    transform: scale(1);
  }

  & > input:checked + .payment-info-title + .payment-info-content textarea {
    opacity: 1;
    transform: scale(1);
  }

  & > input:checked + .payment-info-title + .payment-info-content + b {
    transform: rotate(90deg);
  }
}

.payment-info-title {
  position: relative;
  z-index: 2;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 4rem;
  min-height: 60px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  background: linear-gradient(to right, var(--gradient-1) 0%, var(--gradient-2) 52%, var(--gradient-3) 100%);
  background-size: 200%;
  background-position: 0;

  transition: all 0.3s linear;

  &:hover {
    cursor: pointer;
    background-position: 30%;
  }

  & > h4 {
    color: #fff;
    font-size: 16px;
    width: 70%;
  }
}

.payment-info-actions {
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    border: none;
    padding: 10px;
    border-radius: 8px;
    background: white;
    color: black;
    cursor: pointer;
  }

  button:hover {
    color: white;
    background: var(--main-color);
  }
}

.payment-info-title-input {
  width: 60%;
  min-height: 60px;
  border-top: 1px solid var(--main-color);
  border-bottom: 1px solid var(--main-color);
  border-left: transparent;
  border-right: transparent;
  padding: 0 10px;

  transition: all 0.3s linear;
  outline: none;
}

.payment-info-content {
  position: relative;
  width: 100%;
  max-height: 0;
  padding: 0;

  border: 1px solid #eee;
  border-top: none;
  border-radius: 0 0 15px 15px;

  transition: max-height 0.3s ease-in-out;

  p {
    position: relative;
    color: var(--text-color);
    margin: 0;
    padding: 1.2em 3em;
    opacity: 0;

    font-size: 18px;

    transform: scale(0);
    transition: all 0.55s ease;
  }

  textarea {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1.2em 3em;
    opacity: 0;

    font-size: 18px;

    transform: scale(0);
    transition: all 0.55s ease;
  }
}

.payment-info__create {
  position: absolute;
  top: -45px;
  right: 80px;
  z-index: 100;
  background: transparent;
  font-size: 16px;
  padding: 5px 10px;
  cursor: pointer;
  border: transparent;
  color: var(--main-color);

  &:hover {
    border: var(--main-color) 1px solid;
  }
}

.label__faqs {
  color: var(--text-color) !important;
  font-size: 14px;

  .field {
    padding: 10px !important;
  }
}

.faqs {
  &__modal {
    .ant-modal-body {
      padding: 24px !important;
      background: white;
    }

    .ant-modal {
      background: white;
    }

    .button-block {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      button {
        padding: 5px 10px;
        outline: none;
        background: transparent;
        border: 1px solid black;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          background: var(--main-gradient);
          color: white;
          border: 1px solid var(--main-color);
        }
      }
    }
  }
}

.faqs__position {
  display: flex;
  align-items: center;
  gap: 10px;
}

.number__field {
  border: none;
  width: 50px;
  height: 34px;
  padding-left: 10px;
  border-radius: 8px;
}

.back__btn {
  font-size: 20px;
  color: var(--main-color);
  background: none;
  outline: none;
  border: none;

  &:hover {
    color: var(--main-color-hover);
    cursor: pointer;
  }
}
