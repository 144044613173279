@import '../../styles/colors';

.faq {
  background-image: url('../../assets/white-label/main-background.png');
  background-repeat: no-repeat;
  background-size: 100%;

  @media screen and (max-width: 1280px) {
    min-width: 1300px;
  }
}

.faq-section {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 2rem 0;

  font-family: 'Glacial Indifference', sans-serif;

  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);

  & + section {
    margin-top: -50px;
  }
}

.faq-section__header-container {
  display: block;
  width: 240px;
  padding: 10px;
  margin: 0 auto 2rem;

  border: 2px solid var(--main-color);
  border-radius: 30px;

  & > h2 {
    margin: 0;

    font-size: 36px;
    text-align: center;
    color: var(--main-color);
    text-transform: uppercase;
  }
}

.faq__questions-list {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 3rem;
  padding: 0 0 2rem;

  list-style: none;
  overflow-y: auto;
  height: 80vh;

  transition: all 0.25s ease-in-out;

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    display: block;
    width: 40%;
    height: 2px;
    background-color: #eee;

    transform: translateX(-50%);
  }
}

.faq__questions-label {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  border-radius: 15px;

  & > b {
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;

    color: #fff;
    font-weight: bold;
    font-family: 'Arial', sans-serif;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);

    transition: all 0.3s linear;

    @media screen and(max-width: 1520px) {
      top: 10px;
      width: 25px;
      height: 25px;
    }
  }

  & > input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;

    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  & > input:checked + .faq__questions-title {
    background-position: 100%;
  }

  & > input:checked + .faq__questions-title + .faq__questions-content {
    max-height: 555px;
  }

  & > input:checked + .faq__questions-title + .faq__questions-content p {
    opacity: 1;
    transform: scale(1);
  }

  & > input:checked + .faq__questions-title + .faq__questions-content + b {
    transform: rotate(90deg);
  }
}

.faq__questions-title {
  position: relative;
  z-index: 2;
  width: 100%;

  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 4rem;
  min-height: 80px;

  border-bottom: 1px solid #eee;
  background: $main-gradient;
  background-size: 200%;
  background-position: 0;

  transition: all 0.3s linear;

  @media screen and(max-width: 1520px) {
    min-height: 45px;
  }

  &:hover {
    cursor: pointer;
    background-position: 30%;
  }

  & > h4 {
    color: #fff;
    font-size: 24px;

    @media screen and(max-width: 1520px) {
      font-size: 14px;
    }
  }
}

.faq__questions-content {
  position: relative;
  width: 100%;

  max-height: 0;
  padding: 0;

  border: 1px solid #eee;
  border-top: none;
  border-radius: 0 0 15px 15px;

  transition: max-height 0.3s ease-in-out;

  p {
    position: relative;

    margin: 0;
    padding: 1.2em 3em;
    opacity: 0;

    font-size: 18px;

    transform: scale(0);
    transition: all 0.55s ease;

    @media screen and(max-width: 1520px) {
      font-size: 14px;
    }
  }
}

.faq__form {
  margin-bottom: 70px;

  & > h3 {
    font-family: Bebas Neue, sans-serif;
    font-size: 50px;
    text-align: center;

    & > b {
      color: var(--main-color);
    }
  }

  & > p {
    max-width: 50%;
    margin: 0 auto 1rem;

    font-size: 20px;
    text-align: center;
  }
}

.faq__form-fieldset {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
  border: none;

  & > textarea,
  & > input {
    height: 60px;
    padding: 1rem;

    font-family: 'Glacial Indifference', sans-serif;

    border: 2px solid #f1f1f1;
    background-color: #f1f1f1;

    transition: 0.15s all ease-in-out;

    &:hover,
    &:focus-visible {
      border-color: var(--main-color);
      outline: none;
    }

    &::placeholder {
      color: #ccc;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
      transition: 0.15s all ease-in-out;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & > input[type='text'] {
    flex-grow: 1;
    border-radius: 10px 0 0 0;
  }

  & > input[type='email'] {
    flex-grow: 2;
    border-radius: 0 10px 0 0;
  }

  & > textarea {
    width: 100%;
    height: 180px;

    border-radius: 10px;

    resize: none;
  }
}

.faq__form-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 280px;
  height: 50px;
  margin: 0 auto;
  padding: 10px;

  font-size: 20px;
  font-family: 'Glacial Indifference', sans-serif;
  color: #fff;

  border: 1px solid transparent;
  border-radius: 25px;
  background: $main-gradient;
  background-size: 150%;
  background-position: 0;
  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus-visible {
    background-position: 100%;
    border-color: var(--main-color);
  }
}

.faq__account-container {
  position: relative;

  width: 60%;
  margin: 0 auto;
  padding: 50px 50px 15px;

  border-radius: 15px 15px 0 0;
  background-color: #444444;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
  }
}

.faq__account-svg-container {
  position: absolute;
  top: -40px;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  border-radius: 50%;
  background-color: #fff;

  transform: translateX(-50%);
  box-shadow: 0 3px 4px 2px rgba(34, 60, 80, 0.5);

  & > svg {
    width: 50%;
  }
}

.faq__account-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin: 0 0 1rem;
  padding: 0;

  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.faq__login-button {
  margin-bottom: 1rem;
}

.faq__login-link {
  border-bottom: 1px solid #fff;
}

.input-error {
  border: 1px solid #ff4c4c !important;
}

.faqs-search {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 16px;
  padding-left: 20px;
  background: $main-gradient;
  border: none;
  color: white;
  outline: none;

  &::placeholder {
    color: white;
    border: 2px solid transparent;
  }

  &:focus {
    border: 2px solid var(--main-color);
  }
}
