@import '../../../styles/colors.scss';

.pagination-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination__list {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0;
  margin: 0;

  list-style: none;
}

.pagination-switcher {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  font-weight: bold;
  color: #000;

  border: 2px solid gainsboro;
  border-radius: 5px;
  background: $main-gradient-hover;
  background-size: 300% 300%;
  background-position: 100% 0%;

  outline: none;
  cursor: pointer;
  transition: 0.25s ease all;

  @media screen and (max-width: 1600px) {
    width: 35px;
    height: 35px;
  }

  @media screen and (max-width: 1400px) {
    width: 30px;
    height: 30px;
    font-size: 11px;
  }

  & > svg {
    width: 100%;
    height: auto;
  }

  &:hover,
  &:focus {
    color: var(--main-color);
    border-color: var(--main-color);
  }

  &:disabled {
    color: #fff;
    background-position: 10% 0%;

    cursor: auto;
    pointer-events: none;
  }

  &--dots {
    align-items: flex-end;

    color: grey;

    border-color: transparent;
    background: none;

    cursor: auto;
    pointer-events: none;

    &:disabled {
      color: grey;
      background-color: transparent;
    }

    & > svg {
      width: 100%;
      height: auto;
    }
  }

  &--edge {
    &:disabled {
      border-color: grey;
      color: #fff;

      opacity: 0.6;
    }
  }
}
