@import '../../styles/colors';

.admin-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 20px 0;

  @media screen and (max-width: 1450px) {
    padding-top: 0;
  }

  @media screen and (max-width: 1280px) {
    padding-top: 20px;
  }
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    padding-top: 75px;
  }
}

.admin-padding {
  padding-top: 89px;

  @media screen and (max-width: 1450px) {
    padding-top: 80px;
  }
}

.admin-panel__main-container {
  background: var(--content-bg);
  box-shadow: 0 0 10px 0 rgb(64 64 64 / 20%);
  border-radius: 0 0 20px 20px;
}

.admin-panel__navigation {
  position: relative;
  z-index: 1;
  display: flex;
}

.admin-panel__navigation-link {
  position: relative;
  padding: 1rem 4rem 1rem 2rem;
  font-size: 16px;
  color: var(--navigation-text);
  text-align: center;
  border: none;
  border-radius: 0 0 60px 0;
  background: $main-gradient-hover;
  background-size: 500% 500%;
  background-position: 100% 50%;
  outline: none;
  transition: 0.45s ease all;
  cursor: pointer;

  @media screen and(max-width: 1500px) {
    padding: 1rem 3rem 1rem 1rem;
    font-size: 14px;
  }

  &:nth-child(2n) {
    background: $main-gradient-hover;
    background-size: 500% 500%;
    background-position: 100% 50%;
  }

  &:nth-child(2n) --active {
    background: $main-gradient-hover;
    background-size: 500% 500%;
    background-position: 100% 50%;
  }

  &:hover,
  &:focus-visible {
    color: var(--navigation-text-active);
    background-position: 60% 15%;
  }

  &:not(:first-child) {
    margin-left: -2.5rem;
    padding-left: 3.5rem;
  }

  &:first-child {
    z-index: 1;
  }

  &--active {
    color: var(--navigation-text-active);
    background-position: 0 0 !important;

    pointer-events: none;
  }
}

.admin-panel__content-container {
  height: 600px;
  padding: 1rem;

  @media screen and (max-width: 1450px) {
    height: 500px;
  }

  @media screen and (max-width: 1280px) {
    height: 510px;
  }

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 435px;
  }
}

.white-label {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  height: 100%;

  &__content {
    flex: 1;
    border: 2px solid var(--main-color);
    border-radius: 8px;
    overflow: scroll;

    &-reports {
      overflow: initial !important;
      border: none;
    }
  }

  &-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    list-style: none;
    padding: 0;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--main-color);
      border-radius: 100px;
    }
  }

  &-list__item {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 1fr;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;

    @media screen and(max-width: 1500px) {
      padding: 5px 15px;
    }

    &-admin {
      grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr !important;
    }

    & > span {
      display: flex;
      align-items: center;
      gap: 1rem;

      &:first-child {
        text-transform: capitalize;
      }

      &:last-child {
        justify-content: center;

        & > span {
          padding: 5px;
        }
      }
    }

    & > h3 {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      color: var(--text-color);
      font-weight: 400;
      gap: 5px;
      width: 100%;
      height: 100%;

      a {
        color: var(--text-color);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        @media screen and(max-width: 1500px) {
          font-size: 14px;
        }
      }

      &:hover a {
        color: var(--main-color);
      }

      &:first-child {
        align-items: center;
        justify-content: flex-start;
      }

      &:last-child {
        text-align: center;
      }

      button {
        background: $main-gradient;
        padding: 10px;
        color: white;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          background: $main-gradient-left;
          transition: 0.4s ease-in all;
        }
      }
    }

    &:first-child {
      position: sticky;
    }

    &:nth-child(2n) {
      background-color: var(--tr-bg) !important;
    }
  }

  &__fields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  &-field {
    height: 40px !important;
    flex: 1 !important;
    border-radius: 16px !important;
    padding-left: 20px !important;
    border: 1px solid var(--main-color) !important;
    outline: none;
    box-shadow: none !important;
    color: black;

    &::placeholder {
      color: black;
    }

    @media screen and (max-width: 1500px) {
      width: 100%;
    }

    input {
      height: 40px !important;

      &::placeholder {
        color: var(--text-color);
      }
    }

    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }
}

.create-white-label {
  padding: 10px 20px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  &-btn {
    margin-top: auto;
    margin-left: auto;
    display: block;
    width: 220px;
    background: $main-gradient;
    padding: 10px;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    @media screen and (max-width: 1400px) {
      width: 209px;
    }
    @media screen and (max-width: 1280px) {
      width: 185px;
    }
  }
}

.white-label-files {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.white-label-title {
  font-family: Bebas Neue, sans-serif;
  font-size: 35px;
  line-height: 0.9;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 20px;
  position: absolute;
  top: -40px;
  left: 3px;
}

.white-label-table {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 530px;

  @media screen and (max-width: 1280px) {
    height: 470px;
  }
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 426px;
  }

  .ant-table-thead > tr > th {
    background: var(--content-bg);
    color: var(--text-color);
    font-size: 12px;
    padding: 5px;
    text-align: center;
  }

  .ant-table-tbody > tr > td {
    background: var(--content-bg);
    color: var(--text-color);
    font-size: 12px;
    padding: 5px;
    text-align: center;
  }

  .ant-table-cell-row-hover {
    background: var(--main-color-hover) !important;
    color: black;
  }

  .ant-picker {
    background: var(--content-bg);
    border: 1px solid var(--main-color);

    input,
    input::placeholder,
    .ant-picker-suffix {
      color: var(--text-color) !important;
    }
  }

  .ant-picker-focused,
  .ant-picker:hover {
    border-color: var(--main-color);
    box-shadow: 0 0 0 2px var(--main-color-hover);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid var(--main-color) !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--main-color) !important;
    border-right: 1px solid var(--main-color) !important;
  }
}

.with-pagination-reports {
  height: 570px !important;

  @media screen and (max-width: 1450px) {
    height: 475px !important;
  }

  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 415px !important;
  }

  @media screen and (max-width: 1280px) {
    height: 455px !important;
  }

  @media screen and (max-width: 1280px) and (max-height: 750px) {
    height: 415px !important;
  }
}

.with-pagination-skiptrace {
  height: 570px !important;

  @media screen and (max-width: 1450px) {
    height: 475px !important;
  }

  @media screen and (max-width: 1280px) {
    height: 455px !important;
  }
  @media screen and (max-width: 1400px) and (max-height: 750px) {
    height: 415px !important;
  }
}

.admin-panel__role {
  display: flex;
  align-items: center;
  position: relative;

  &__item {
    box-shadow: 0 0 10px 0 rgb(64 64 64 / 20%);
    background: var(--content-bg);
    height: 50px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;

    &:first-child {
      border-radius: 20px 0 0 0;
    }

    &:last-child {
      border-radius: 0 20px 0 0;
    }
  }

  &__item--active {
    background: $main-gradient;
    color: white;
  }
}

.white-label-table .ant-table-thead > tr > th,
.white-label-table .ant-table-tbody > tr > td {
  font-size: 14px !important;

  @media screen and (max-width: 1450px) {
    font-size: 12px !important;
  }
}

.white-label__update-date {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.white-label__delete {
  background: $incorrectColor !important;
}

.edit-wl-btn {
  width: 160px;
  background: $main-gradient;
  padding: 10px;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.edit-wl-buttons__container {
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: flex-end;

  .ssl-block {
    flex: 1;
  }

  .confirm-block {
    width: 20%;
  }
}

.history-table-btn {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
