@import '../../../styles/colors';
.success-modal {
  display: flex;
  flex-direction: column;
  height: 50vh;
  position: relative;

  &__body {
    text-align: center;
    margin: auto auto;

    h1 {
      font-size: 50px;
      font-family: Open Sans, serif, sans-serif;
      font-weight: bold;
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;
      color: var(--text-color);
      border-bottom: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(90deg, transparent 0%, transparent 20%, var(--main-color) 51%, transparent 80%, transparent 100%);
    }

    p {
      color: var(--text-color);
      font-size: 22px;
    }
  }

  &__footer {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, -25px);

    button {
      width: 25vw;
      outline: none;
      border: 1px solid #333333;
      background: #333333;
      color: white;
      border-radius: 32px;
      padding: 10px 15px;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.ant-modal-footer {
  background: $main-gradient !important;
  border-radius: 0 0 50px 50px !important;
  height: 100px !important;
}
