@import '../../styles/colors';

.register {
  @media screen and (max-width: 1200px) {
    background: none !important;
    overflow: scroll !important;
  }

  .phone__field {
    @media screen and (max-width: 1000px) {
      margin-bottom: 10px;
    }
  }

  &__body {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 30px;
    max-height: 805px;
    z-index: 10;
    position: relative;
    height: 800px;

    @media screen and (max-width: 1600px) {
      height: 650px;
    }

    @media screen and (max-width: 1400px) and (max-height: 700px) {
      height: 540px;
      padding: 5px 30px 0 30px;
    }

    @media screen and (max-width: 1300px) {
      height: 599px;
      padding: 10px 30px;
    }
  }

  &__body-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    height: 650px;

    .register__inputs {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .signup__container {
        width: 100%;
        padding: 0 10px;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--main-color);
        border-radius: 10px;
      }
    }

    .auth__title {
      @media screen and (max-width: 1300px) {
        color: black;
      }
    }
  }

  &__body-item {
    display: flex;
    flex-direction: column;

    &:last-child {
      display: flex;
      flex-direction: column;
      width: 450px;
      padding: 10px;
      align-items: center;
      @media screen and (max-width: 1300px) {
        padding: 10px 10px 60px 10px;
      }
    }
  }

  &__input {
    .field {
      width: 430px;
      border: 1px solid transparent;

      transition: 0.25s ease all;

      &:hover,
      &:focus {
        border-color: var(--main-color);
      }
    }

    .form-control {
      transition: 0.25s ease all;
    }

    .flag-dropdown {
      transition: 0.25s ease all;
    }

    &--error {
      .field {
        border-color: #ff4c4c;
      }

      .form-control {
        border-color: #ff4c4c !important;
      }

      .flag-dropdown {
        border-color: #ff4c4c !important;
      }
    }

    &--correct {
      .field {
        border-color: $correctColor;
      }

      .form-control {
        border-color: $correctColor !important;
      }

      .flag-dropdown {
        border-color: $correctColor !important;
      }
    }
  }

  &__policy {
    color: $grey;
    line-height: 1.4;
    font-size: 12px;
    text-align: center;
  }

  &__login {
    color: #b3b3b3;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__link {
    color: #3ab1e4;
    text-decoration: underline;
    margin-left: 1px;
  }

  &__footer {
    width: 100%;
    margin-top: auto;
    text-align: right;

    p {
      position: absolute;
      top: 114px;
      left: 646px;
      font-size: 12px;
      color: $white;
    }

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
}

.register__mobile {
  @media screen and(max-width: 800px) {
    display: flex;
    flex-direction: column;

    .register__input {
      margin: 0 auto !important;
      width: 100% !important;
    }
  }
}

.auth__name-block {
  width: 100%;
  @media screen and (max-width: 1650px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .register__input {
      width: 50%;
    }

    .label {
      margin: 5px 0 0 0;
    }
  }
  @media screen and(max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 0;

    .register__input {
      width: 100% !important;
    }

    input {
      width: 100% !important;
    }
  }
}

.marketing-messages__text {
  font-size: 18px;
  margin-left: 30px;
}
.agreed-terms__block {
  width: 440px;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  line-height: 1.1;

  &.agree_2 {
    display: flex;
    align-items: flex-start;
    line-height: 1.1;
  }

  @media screen and (max-width: 1400px) {
    width: 440px;
  }
  @media screen and (max-height: 800px) {
    font-size: 13px;
  }
}

.policy-wrapper {
  display: flex;
  gap: 5.5px;
  padding: 5px 0;
  &.marketing {
    flex-direction: column;
  }
}

.is-agree_error {
  color: red;
}
