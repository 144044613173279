p,
h1,
h2,
h3,
h4,
h5,
body {
  margin: 0;
  font-weight: 400;
  line-height: 1;
  font-family: 'Glacial Indifference', sans-serif;
}

img,
button,
a,
svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

code {
  font-family: 'Glacial Indifference', sans-serif;
}

* {
  box-sizing: border-box;
}

.forbidden {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main__spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#SKIPCLOUD {
  --color-1: var(--gradient-1);
  --color-2: var(--gradient-2);
  --color-3: var(--gradient-3);
}

#SKIPCLOUD-ICON {
  --color-1: var(--gradient-1);
  --color-2: var(--gradient-2);
  --color-3: var(--gradient-3);
  --sub-color: #e6e6e6;
}

.svg {
  fill: url(#SKIPCLOUD);
}

.svg-icon {
  fill: url(#SKIPCLOUD-ICON);
}

:root {
  --main-color: #0c70e9;
  --main-color-hover: #187aec7f;

  --gradient-1: #0c6fe7;
  --gradient-2: #1694ee;
  --gradient-3: #1eaff3;

  --content-bg: #fff;

  --action-color: #4379ad;
  --tr-bg: #f2f2f2;
  --text-color: #000;
  --header-inner-gradient-1: #1a4268;
  --header-inner-gradient-2: #1a4268;
  --header-inner-gradient-3: #1a4268;

  --header-skips-gradient-1: #0c6fe7;
  --header-skips-gradient-2: #1694ee;
  --header-skips-gradient-3: #1eaff3;

  --action-gradient-1: #235089;
  --action-gradient-2: #275a8a;
  --action-gradient-3: #2c6b8e;

  --navigation-text: #000;
  --navigation-text-active: #fff;

  --background-totals-1: #37485b;
  --background-totals-2: #37485b;
  --background-totals-3: #37485b;
}
