@import '../../styles/colors';

.faqs-section {
  padding: 0 20px;
  font-family: 'Glacial Indifference', sans-serif;
  margin-top: 40px;

  h1 {
    font-family: Bebas Neue, sans-serif;
    font-size: 40px;
    line-height: 0.9;
    font-weight: bold;
    margin-bottom: 30px;
    margin-left: 3rem;
    color: var(--text-color);

    @media screen and(max-width: 1300px) {
      font-size: 45px;
    }

    span {
      color: var(--main-color);
    }
  }

  &__body {
    width: 95%;
    margin: 0 auto;
    border-radius: 15px;
    background-color: var(--content-bg);
    -webkit-box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.2);
    box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.2);
    padding: 1rem 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 2rem;
    list-style: none;
    overflow-y: scroll;
    transition: all 0.25s ease-in-out;
    height: 65vh;

    @media screen and (max-width: 1500px) {
      height: 75vh;
    }

    @media screen and(max-width: 1520px) {
      height: 60vh;
    }

    @media screen and(max-width: 1450px) and (max-height: 700px) {
      height: 56vh;
    }

    &::after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 50%;

      display: block;
      width: 40%;
      height: 2px;

      transform: translateX(-50%);
    }
  }

  .faqs__questions-label {
    position: relative;
    cursor: pointer;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    border-radius: 18px;

    & > b {
      position: absolute;
      top: 15px;
      left: 20px;
      z-index: 3;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0;

      color: #fff;
      font-weight: bold;
      font-family: 'Arial', sans-serif;

      border-radius: 50%;
      background: rgba(0, 0, 0, 0.4);

      transition: all 0.3s linear;

      @media screen and(max-width: 1520px) {
        top: 10px;
        width: 25px;
        height: 25px;
      }
    }

    & > input {
      position: absolute;

      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;

      border: 0;

      clip: rect(0 0 0 0);
      overflow: hidden;
    }

    & > input:checked + .faqs__questions-title {
      background-position: 100%;
      border-radius: 22px 22px 0 0 !important;
    }

    & > input:checked + .faqs__questions-title + .faqs__questions-content {
      border: 1px solid #eee;
      max-height: 555px;
    }

    & > input:checked + .faqs__questions-title + .faqs__questions-content p {
      opacity: 1;
      transform: scale(1);
      color: var(--text-color);
    }

    & > input:checked + .faqs__questions-title + .faqs__questions-content + b {
      transform: rotate(90deg);
    }
  }

  .faqs__questions-title {
    position: relative;
    z-index: 2;
    width: 100%;
    border-radius: 22px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 4rem;
    min-height: 60px;
    background: $main-gradient;
    background-size: 200%;
    background-position: 0;

    transition: all 0.3s linear;

    @media screen and(max-width: 1520px) {
      min-height: 45px;
    }

    &:hover {
      cursor: pointer;
      background-position: 30%;
    }

    & > h4 {
      color: #fff;
      font-size: 16px;

      @media screen and(max-width: 1520px) {
        font-size: 14px;
      }
    }
  }

  .faqs__questions-content {
    position: relative;
    width: 100%;

    max-height: 0;
    padding: 0;

    border-top: none;
    border-radius: 0 0 22px 22px;

    transition: max-height 0.3s ease-in-out;

    p {
      position: relative;

      margin: 0;
      padding: 1.2em 3em;
      opacity: 0;

      font-size: 18px;

      transform: scale(0);
      transition: all 0.55s ease;

      @media screen and(max-width: 1520px) {
        font-size: 14px;
      }
    }
  }

  .faqs-search {
    width: calc(100% - 4rem);
    margin: 0 auto 30px auto;
  }
}
