@import '../../../../styles/colors';

.stripe__card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid white;
  border-radius: 20px;
  padding: 8px;
  min-width: 270px;
  margin-bottom: 15px;

  @media screen and (max-width: 1600px) {
    padding: 5px;
    margin-left: -10px;
  }

  @media screen and (max-width: 1500px) {
    padding: 2px;
  }

  @media screen and (max-width: 1300px) {
    min-width: 250px;
  }

  p {
    font-size: 18px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 5px;

    @media screen and (max-width: 1600px) {
      font-size: 16px;
    }
  }

  span {
    margin-top: 2px;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
  }

  &-info {
    display: flex;
    align-items: center;
    border-right: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(180deg, transparent 0%, rgba(255, 255, 255, 0.2) 15%, white 51%, rgba(255, 255, 255, 0.2) 85%, transparent 100%);
    padding: 10px 0 10px 0;
    width: 180px;
    @media screen and (max-width: 1500px) {
      width: 160px;
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      background: white;
      border-radius: 50%;
      color: var(--main-color);
      padding: 5px;
      width: 16px;
      height: 16px;
      position: relative;
      cursor: pointer;
      font-weight: bold;

      &:first-child {
        margin-right: 5px;

        &:after {
          content: '-';
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:last-child {
        &:after {
          content: '+';
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
