@import '../../../../styles/colors';

.landing__price-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: auto;
  background: $main-gradient;
  padding: 15px 0 110px 0;
  position: relative;
  text-align: center;
  //margin-bottom: 100px;

  @media screen and (max-width: 600px) {
    padding-bottom: 50px;
  }

  .icon {
    width: 120px;
    height: 120px;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      width: 90px;
      height: 90px;
    }
  }

  .title {
    color: white;
    text-transform: uppercase;
    font-size: 50px;
    margin-bottom: 10px;
    font-family: 'Bebas Neue', sans-serif;

    @media screen and (max-width: 600px) {
      font-size: 36px;
      max-width: 90%;
      margin: 0 auto 10px;
    }

    @media screen and (max-width: 500px) {
      font-size: 28px;
    }

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  .sub-title {
    color: white;
    width: 30%;
    min-width: 576px;
    margin: 0 auto 90px;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      text-align: center;
      margin-bottom: 50px;
      width: 90%;
      min-width: 90%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 30%;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.85;
    border-radius: 32px;
    background: url('../../../../assets/landing/price-bg.svg') 0 0 no-repeat;
    aspect-ratio: 300/81;
  }
}

.landing__price-list {
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  width: 95%;
  margin: 0 auto 30px;

  @media screen and (max-width: 1400px) {
    width: 100%;
  }

  @media screen and (max-width: 1600px) {
    width: 90%;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .card__count-inner-btn {
    padding: 15px 0;
  }

  .card__count-inner-buttons {
    button {
      font-size: 12px;
    }
  }
}

.landing__price-section-footer {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, 15px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 26px;
  width: 35%;
  min-width: 521px;
  border: 5px solid rgba(241, 241, 241, 0.62);
  z-index: 9999;
  margin: 0 auto;

  @media screen and (max-width: 1400px) {
    bottom: -3px;
  }
  @media screen and (max-width: 1366px) {
    bottom: 0px;
  }
  @media screen and (max-width: 1280px) {
    bottom: -14px;
  }
  @media screen and (max-height: 990px) and (max-width: 1280px) {
    bottom: -35px;
  }

  @media screen and (max-width: 1200px) {
    min-width: 450px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 450px) {
    width: 95%;
    min-width: 300px;
    position: initial;
    bottom: initial;
    left: initial;
    transform: initial;
  }

  @media screen and(max-width: 300px) {
    min-width: 280px;
  }

  &-total {
    width: 65%;
    display: flex;
    align-items: center;
    background: #f1f1f1;
    color: black;
    padding: 20px 0;
    border-radius: 19px 0 0 19px;

    @media screen and (max-width: 800px) {
      min-height: 58px;
    }

    @media screen and (max-width: 450px) {
      width: 70%;
    }

    p {
      font-size: 18px;
      padding: 0 30px;

      @media screen and (max-width: 800px) {
        font-size: 16px;
      }

      @media screen and (max-width: 450px) {
        font-size: 12px;
        padding: 0 10px;
      }

      &:first-child {
        padding-right: 15px;
        font-weight: bold;
        border-right: 1px solid var(--text-color);
      }

      &:last-child {
        padding-left: 15px;
      }
    }
  }

  &-checkout {
    padding: 21px 0;
    width: 35%;
    background: $main-gradient;
    border-radius: 0 19px 19px 0;
    color: white;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 450px) {
      font-size: 14px;
      width: 30%;
    }

    @media screen and (max-width: 350px) {
      font-size: 12px;
      width: 30%;
    }

    @media screen and(max-width: 300px) {
      font-size: 10px;
    }

    &:before {
      position: absolute;
      top: 15px;
      left: -45px;
      content: ' ';
      background: white url('../../../../assets/pop-up/cartIcon.svg') 7px 8px no-repeat;
      background-size: 15px 15px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      z-index: 1000;

      @media screen and (max-width: 450px) {
        top: 50%;
        transform: translate(0, -50%);
        left: -35px;
      }
    }
  }
}

.priceSwipper {
  @media screen and (max-width: 500px) {
    .swiper-pagination-horizontal {
      bottom: 10px !important;
      left: 24% !important;
      width: 50% !important;
      background-color: RGBA(0, 0, 0, 0.3);
      border-radius: 10px;
      padding: 5px 0;
    }
    .swiper-pagination-bullet {
      background-color: #f2f2f2;
      width: 10px !important;
      height: 10px !important;
    }
    .swiper-pagination-bullet-active {
      background-color: $white;
    }
  }

  .swiper-slide {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}
