@import '../../styles/colors.scss';

.about-us {
  background-image: url('../../assets/white-label/main-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;

  @media screen and (max-width: 1280px) {
    min-width: 1300px;
  }

  &__section {
    width: 100%;
    max-width: 1100px;
    margin: 80px auto 0;
    padding: 2rem 2rem 0;
    font-family: 'Glacial Indifference', sans-serif;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
    z-index: 99;
  }
}

.why__skipcloud__container {
  width: 70%;
  max-width: 800px;
  padding: 2rem 2rem 0;
  border-radius: 25px;
  background: linear-gradient(to right, var(--gradient-1) 0%, var(--gradient-2) 52%, var(--gradient-3) 100%);
  box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  color: $white;
  text-align: center;
  margin: -35px auto 45px;
  z-index: 99;

  h1 {
    color: $white;
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;
    font-size: 52px;

    @media screen and (max-width: 1500px) {
      font-size: 40px;
      padding: 15px 0;
    }
  }

  p {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 22px;

    @media screen and (max-width: 1500px) {
      font-size: 18px;
    }
  }
}

.options {
  width: 70%;
  max-width: 800px;
  padding: 2rem 2rem 0;
  border: 1px solid $blue;
  border-radius: 25px;
  background-color: transparent;
  box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  color: $white;
  text-align: center;
  margin: -35px auto 55px;

  &__item {
    margin-bottom: 40px;

    &__img {
      width: 48px;
      height: 48px;
      background: var(--main-color);
      border-radius: 50%;
      position: relative;
      text-align: center;
      margin: 0 auto 10px;

      img {
        position: absolute;
      }

      .phone {
        top: 11px;
        left: 11px;
      }

      .search {
        top: 12px;
        left: 12px;
      }

      .time {
        top: 9px;
        left: 10px;
      }

      .money {
        top: 7px;
        left: 12px;
      }
    }

    span {
      font-family: 'Glacial Indifference', sans-serif;
      color: $black;
      font-weight: 600;
      font-size: 28px;

      @media screen and (max-width: 1500px) {
        font-size: 22px;
      }
    }
  }
}

.people-cards {
  display: flex;
  flex-direction: column;
  padding: 10px 0 40px;
  gap: 20px;
  width: 100%;

  p {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.2;

    @media screen and (max-width: 1500px) {
      font-size: 22px;
    }

    span {
      color: var(--main-color);
      text-decoration: underline;
    }
  }

}

.block {
  background: rgba($color: #000000, $alpha: 0.8);
  width: 900px;
  height: 40px;
  border-radius: 15px;
  top: calc(100% - 20px);
  margin: 0 auto;
  margin-top: -25px;
  z-index: -1;
}

.user__avatar__container {
  width: 200px;
  height: 200px;
  position: absolute;
  top: -90px;
  left: 55px;

  .user__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid $blue;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      transform: translate(0%, 12%);
    }
  }
}

.about__account-container {
  position: relative;
  width: 35%;
  margin: 0 auto;
  padding: 50px 50px 15px;
  @media screen and (max-width: 1400px) {
    width: 40%;
  }

  border-radius: 15px 15px 0 0;
  background-color: #444444;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
  }
}

.about__account-svg-container {
  position: absolute;
  top: -40px;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  border-radius: 50%;
  background-color: #fff;

  transform: translateX(-50%);
  box-shadow: 0 3px 4px 2px rgba(34, 60, 80, 0.5);

  & > svg {
    width: 50%;
  }
}

.about__account-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin: 0 0 1rem;
  padding: 0;

  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.about__login-button {
  margin-bottom: 1rem;
}

.about__login-link {
  border-bottom: 1px solid #fff;
}

.underline__text {
  text-decoration: underline;
}
